export class TalentModel{
/**
 * {
        id="",
        agency= "",
        code= "",
        talentSort= "",
        last= "",
        status= "",
        talentPart= "",
        first= "",
        gender= "",
        age= "",
        fullName="",
        latestURL="",
    }
 */
    constructor(obj) {
        let {id, agency, code, talentSort, last, status, talentPart, first, gender, age, fullName, latestURL, files, assetFiles, images, assets, category} = obj?obj:'';
       
        this.id = id??"";
        this.agency = agency??"";
        this.code = code??"";
        this.talentSort = talentSort??"";
        this.last = last??"";
        this.status = status??"";
        this.talentPart = talentPart??"";
        this.first = first??"";
        this.gender = gender??"";
        this.age = age??"";
        this.fullName = fullName??"";
        this.latestURL = latestURL??"";
        this.files = files??[];
        this.assetFiles = assetFiles??[];
        this.images = images??[];
        this.category = category??'';
        // this.assets = assets??[];
    }
} 

export const TalentEventType = {
    TALENT_BUILD_OUTFIT: 'talentBuildOutfit',
    TALENT_EDIT: 'talentEdit',
    TALENT_ADD: 'talentAdd',
    TALENT_VIEW: 'talentView',
    TALENT_DELETE: 'talentDelete',
    TALENT_DOWNLOAD: 'talentDownload',
    TALENT_ASSIGN_CAPTURES: 'talentAssignCaptures',
    TALENT_SHOW_SESSION: 'talentShowSession',
    TALENT_DISTRIBUTE: 'talentDistribute',
};

export const TalentStatusTypes = {
    TALENT_PHOTOGRAPHY_COUNT: 'photographyCount',
    INACTIVE:'inactive',
    ACTIVE:'active'
};

export const TalentStatuses = [
    { label: 'Active', value: 'active' },
    { label: 'Inactive', value: 'inactive' },
  ]

  export const TalentCategories = [
    'Womens','Mens','Big Kids','Little Kids','Babies']