import { Button, Checkbox, Group, Popover, ScrollArea, Text } from '@mantine/core';
import { IconCheck, IconChevronDown } from '@tabler/icons';
import React from 'react';

const ColumnFilterMenu = (props) => {
    return (
        <Popover shadow='md' withArrow width={200} position='bottom-end'>
            <Popover.Target>
                <Button variant='default' rightIcon={<IconChevronDown size={14} />}>
                    Display Columns
                </Button>
            </Popover.Target>

            <Popover.Dropdown px={0}>
                <Text px={'md'} pb={'md'} size={'xs'} color='dimmed'>
                    Columns
                </Text>
                <ScrollArea h={props.height??302} px={'md'} >
                {props.columns?.map((val, idx) => {
                    // const col = columns.find(column => column.accessor === val.accessor)
                    const checked = props.hiddenColumns.indexOf(val.accessor) < 0;
                    return (
                        <React.Fragment key={idx}>
                            {Object.hasOwn(val, 'show') ? (
                                <div key={idx} onClick={(e) => props.onChange('change', val, checked)}>
                                    <Group spacing={'xs'} mb={'md'}>
                                        <Checkbox checked={checked} onChange={() => {}}  />
                                        {/* <div style={{ width: 20 }}>{props.hiddenColumns.indexOf(val.accessor) < 0 ? <IconCheck size={14} /> : null}</div> */}
                                        <Text style={{cursor:'pointer'}} size={'sm'}>{val.Header}</Text>
                                    </Group>
                                </div>
                            ) : null}
                        </React.Fragment>
                    );
                })}
                </ScrollArea>
            </Popover.Dropdown>
        </Popover>
    );
};

export default ColumnFilterMenu;
