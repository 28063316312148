/**
 * @file sessionSlice.js
 * @description Sets initial state for the warehouse module.  Creates reducers and actions for API Calls
 * @author Ben Yee
 */
import { createSlice } from '@reduxjs/toolkit';
import { getCaptures, updateCapture } from 'services/approvalService';
import { purgeFilters, setObjectFromProps, updateCaptureItems } from 'utils/helper';
import {
    addGuideToSession,
    addSampleToCapture,
    addStyleToSession,
    deleteGuideFromSession,
    deleteStyleFromSession,
    getFileUploadPath,
    getSession,
    getSessions,
    getSessionStyles,
    getSessionTasks,
    getTask,
    getTasks,
    udpateSession,
    udpateTask,
    updateSessionStyle,
    uploadTaskFile,
    uploadTaskFileWithProgress,
} from '../../services/sessionService';
import { logger } from 'utils/logger';
import { getGuide } from 'services/guideService';
import { getSamples, getSessionLiveData, updateSample } from 'services/samplesService';
import { ListViewOption } from 'core/components/listToggle/listToggleControl';
import _ from 'lodash';
import { SessionStatusTypes } from 'core/models/SampleModel';
import { statuss } from 'utils/constants';
import { SampleFilterModel } from 'core/models/SampleFilterModel';

/*
 const filterSessions = (samples,filter) => {
   return samples.filter(sample => {
     var match = false;
     // filter by brand
     if(filter.brands){
       match = filter.brands.indexOf(sample.facet_brand) > -1?true:false;
     }else{
       match = true;
     }
     logger.log(match, ' MATCH')
     return match;
   })
 }
 */

const sessionSlice = createSlice({
    name: 'sessions',
    initialState: {
        sessions: [],
        tasks: [],
        selectedSession: null,
        selectedSessions: [],

        sessionsFilter: {
            brands: [],
            categories: [],
            department: [],
            location: [],
            status: [],
            colour: '',
            keyword: '',
            teams: [],
            seasons: [],
            tags: [],
            status: [SessionStatusTypes.ACTIVE],
            dueDateStart: '',
            dueDateEnd: '',
            shootDateStart: '',
            shootDateEnd: '',
            sortBy: null,
            pageIndex: 0,
        },
        // single sessionFilter
        // sessionSampleFilter: { ...SampleFilterModel },

        // create a lookup of all single sessionFilters.
        // samples, tasks, media
        sessionFilters: {},

        // tasks
        selectedTasks: [],
        selectedTask: null,

        // styles
        selectedStyles: [],

        taskFilter: {
            keyword: '',
            description: '',
            deadline: [null, null],
            users: [],
            assignees: [],
            sortBy: null,
            pageIndex: 0,
        },

        displayPrefs: {
            mediaDisplayType: ListViewOption.GRID,
        },

        // Meta
        error: null,
        success: null,
        loading: false,
        action: null,
    },
    reducers: {
        selectSession(state, action) {
            logger.log('Setting Session: ', action.payload);
            state.selectedSession = action.payload ? { ...action.payload } : null;
        },
        selectSessions(state, action) {
            logger.log('setting selected samples', action.payload);
            state.selectedSessions = action.payload ? [...action.payload] : [];
        },
        setDisplayPrefs(state, action) {
            state.displayPrefs = { ...action.payload };
        },
        setSessionsFilter(state, action) {
            logger.log('Setting Session: ', action.payload);

            state.sessionsFilter = action.payload ? { ...action.payload } : null;

            // filter the samples.
            // const samples = filterSessions(state.samples, state.sampleFilter);
        },

        setSessionFilters(state, action) {
            logger.log('Setting Session: ', action.payload);
            const sessionId = action.payload.sessionId ?? null;

            if (sessionId) {
                const key = `${sessionId}_${action.payload.type}`;
                // state.sessionSampleFilters[sessionId] = action.payload ? { ...action.payload } : null;
                var filter = state.sessionFilters[key] && state.sessionFilters[key].filter ? state.sessionFilters[key].filter : {};
                state.sessionFilters = {
                    ...state.sessionFilters,
                    [key]: {
                        timestamp: Date.now(),
                        filter: {
                            ...filter,
                            ...action.payload.filter,
                        },
                    },
                };
            }
        },

        cleanupUnusedFilters(state, action) {
            const newFilters = purgeFilters(state.sessionFilters);
            return {
                ...state,
                sessionFilters: newFilters,
            };
        },

        setSuccess(state, action) {
            state.success = { type: action.payload.type, message: action.payload.message };
        },
        //////////////////////
        //
        // SINGLE Session
        //
        //////////////////////
        getSessionStart(state, action) {
            state.success = null;
            state.error = null;
        },
        getSessionSuccess(state, action) {
            logger.log('getSessionSuccess : SUCCESS');

            state.loading = false;
            state.success = { type: action.type, message: 'Get Session Success' };
            // if(action.payload.urls){
            //   state.selectedProject = {...state.selectedProject, urls:action.payload.urls}
            // }
            state.selectedSession = { ...action.payload };
            //state.openIssuesCount = action.payload.open_issues_count
            state.error = null;
        },
        getSessionFailure(state, action) {
            logger.log(state, action, 'fail');
            state.error = action.payload;
        },

        //////////////////////
        //
        // GET JOBS
        //
        //////////////////////
        getSessionsStart(state, action) {
            state.success = null;
            state.error = null;
        },
        getSessionsSuccess(state, action) {
            logger.log('getSessionSuccess : SUCCESS', action);

            state.loading = false;
            state.success = { type: action.type, message: 'Get Sessions Success' };

            state.sessions = [...action.payload];
            //state.openIssuesCount = action.payload.open_issues_count
            state.error = null;
        },
        getSessionsFailure(state, action) {
            logger.log(state, action, 'fail');
            state.error = action.payload;
        },

        //////////////////////
        //
        // TASKS
        //
        //////////////////////
        selectTask(state, action) {
            logger.log('Setting Task: ', action.payload);
            state.selectedTask = action.payload ? { ...action.payload } : null;
        },
        selectTasks(state, action) {
            logger.log('setting selected samples', action.payload);
            state.selectedTasks = action.payload ? [...action.payload] : [];
        },

        setTaskFilter(state, action) {
            logger.log('Setting Session: ', action.payload);

            state.taskFilter = action.payload ? { ...action.payload } : null;
        },
        getSessionTasksSuccess(state, action) {
            logger.log(state, action);
            const session = { ...state.selectedSession, tasks: action.payload };
            state.selectedSession = session;
            state.success = { type: action.type, message: 'Fetched Tasks' };
        },
        getTasksSuccess(state, action) {
            logger.log(state, action);
            state.tasks = [...action.payload.data];
            state.success = { type: action.type, message: 'Fetched Tasks' };
        },
        getTaskSuccess(state, action) {
            logger.log(state, action);
            state.selectedTask = { ...action.payload };
            state.success = { type: action.type, message: 'Fetched Task' };
        },
        updateTaskStart(state, action) {
            state.success = null;
            state.error = null;
        },
        updateTaskSuccess(state, action) {
            const tasks = [...state.tasks];
            logger.log(action.payload);

            for (let i = 0; i < action.payload.length; i++) {
                // find the capture.
                let currentItem = action.payload[i];
                var taskIdx = tasks.findIndex((val) => val.taskSort === currentItem.taskSort);
                // var task = tasks[taskIdx];

                // update the selected item
                if (state.tasks && taskIdx > -1) {
                    tasks[taskIdx] = setObjectFromProps(tasks[taskIdx], currentItem);
                } else {
                    tasks.push(currentItem);
                }

                state.tasks = tasks;
            }

            //state.sessions = [...action.payload];

            state.error = null;
            state.loading = false;
            state.success = { type: action.type, message: 'Task has been saved', data: action.payload };
        },
        updateTaskFailure(state, action) {
            logger.log(state, action, 'fail');
            state.error = action.payload;
        },

        batchUpdateTaskSuccess(state, action) {
            var tasks = [...state.tasks];
            logger.log(action.payload);
            const idMap = new Map(action.payload.map((item) => [item.taskSort, item]));

            // update the tasks
            const tmpTasks = [...state.tasks].map((task) => {
                if (idMap.get(task.taskSort)) {
                    return { ...idMap.get(task.taskSort) };
                } else {
                    return task;
                }
            });

            state.tasks = tmpTasks;

            state.error = null;
            state.loading = false;
            state.success = { type: action.type, message: 'Your tasks have been created', data: action.payload };
        },

        updateSessionStart(state, action) {
            state.success = null;
            state.error = null;
        },
        updateSessionSuccess(state, action) {
            state.loading = false;
            state.success = { type: action.type, message: 'Update Session Success' };

            // update all keys except for ones that get populated with other fetches.
            const tmpSession = { ...state.selectedSession };
            const ignoreKeys = ['guides', 'samples', 'tasks', 'team', 'id', 'sessionId', 'actionPart', 'actionSort'];
            Object.keys(action.payload).forEach((key) => {
                if (ignoreKeys.indexOf(key) === -1) {
                    tmpSession[key] = action.payload[key];
                }
            });
            state.selectedSession = tmpSession;

            //state.sessions = [...action.payload];
            //state.openIssuesCount = action.payload.open_issues_count
            state.error = null;
        },
        updateSessionFailure(state, action) {
            logger.log(state, action, 'fail');
            state.error = action.payload;
        },

        bulkUpdateSessionsSuccess(state, action) {
            state.loading = false;
            state.success = { type: action.type, message: 'Update Sessions Success' };

            state.error = null;
        },

        // Create session
        createSessionStart(state, action) {
            state.success = null;
            state.error = null;
        },
        createSessionSuccess(state, action) {
            logger.log(action.type.toString());

            const sessions = [...state.sessions];
            sessions.push(action.payload);

            const { data, doSelect } = action.payload;

            state.sessions = [sessions];
            // only select the session if we need, defaults to true
            if (doSelect) {
                state.selectedSession = { ...data };
            }

            state.loading = false;
            state.success = { type: action.type, message: 'create Session Success', data };

            //state.sessions = [...action.payload];
            //state.openIssuesCount = action.payload.open_issues_count
            state.error = null;
        },
        createSessionFailure(state, action) {
            logger.log(state, action, 'fail');
            state.error = action.payload;
        },

        clearNotifications(state, action) {
            state.error = null;
            state.success = null;
        },

        getSessionMediaSuccess(state, action) {
            const session = { ...state.selectedSession, media: action.payload.items };
            state.selectedSession = { ...session };
            state.loading = false;

            if (action.payload.complete) {
                state.success = { type: action.type, message: 'Fetch Media Success' };
            }
        },

        getSessionStylesSuccess(state, action) {
            const session = { ...state.selectedSession, styles: action.payload };
            state.selectedSession = session;
        },

        removeStylesFromSessionSuccess(state, action) {
            const stylesToRemove = action.payload;
            const styles = [...state.selectedSession.samples].filter((style, idx) => {
                const doRemove = stylesToRemove.find((val) => val.id === style.id);

                if (!doRemove) {
                    return style;
                }
            });

            state.selectedSession = { ...state.selectedSession, samples: styles };
            // const session = {...state.selectedSession,media:action.payload};
            // state.selectedSession = session;
        },

        assignStylesToSessionSuccess(state, action) {
            // const session = {...state.selectedSession,media:action.payload};
            // state.selectedSession = session;\
            state.success = {
                type: action.type,
                message: 'Styles have been assigned',
            };
        },

        assignTalentToStylesSuccess(state, action) {
            // const session = {...state.selectedSession,media:action.payload};
            // state.selectedSession = session;\
            state.success = {
                type: action.type,
                message: 'Talent have been assigned',
            };
        },

        getSessionSamplesSuccess(state, action) {
            logger.log(action.type.toString(), action.payload);
            const session = { ...state.selectedSession, samples: action.payload };
            state.selectedSession = session;
            state.success = {
                type: action.type,
                message: 'Session styles has been fetched',
            };
        },

        getSessionLiveDataSuccess(state, action) {
            logger.log(action.type.toString(), action.payload);
            const session = { ...state.selectedSession, liveData: action.payload };
            state.selectedSession = session;
            state.success = {
                type: action.type,
                message: 'Session live data has been fetched',
            };
        },

        removeSamplesFromSessionSuccess(state, action) {
            // const session = {...state.selectedSession,media:action.payload};
            // state.selectedSession = session;
        },

        assignSamplesToSessionSuccess(state, action) {
            // const session = {...state.selectedSession,media:action.payload};
            // state.selectedSession = session;
        },

        removeGuideFromSessionSuccess(state, action) {
            const guides = [...state.selectedSession.guides];

            _.remove(guides, (obj) => obj.id === action.payload.id);

            state.selectedSession.guides = guides;

            // const session = {...state.selectedSession,media:action.payload};
            // state.selectedSession = session;
        },

        assignGuideToSessionSuccess(state, action) {
            // we add it to the sessions guides if it exists.
            const guides = [...state.selectedSession.guides];
            logger.log(action.payload);

            // find the capture.
            var currentItem = action.payload;
            var guideIdx = guides.findIndex((val) => val.id === currentItem.id);
            // var guide = guides[guideIdx];

            // update the selected item
            if (guideIdx > -1) {
                guides[guideIdx] = setObjectFromProps(guides[guideIdx], currentItem);
            } else {
                guides.push(currentItem);
            }

            state.selectedSession.guides = guides;

            //state.sessions = [...action.payload];

            state.error = null;
            state.loading = false;
            state.success = {
                type: action.type,
                message: 'Style Guide has been assigned',
            };
        },

        getSampleGuideCategoriesSuccess(state, action) {
            logger.log('getGuideSuccess : SUCCESS');

            state.loading = false;

            const session = { ...state.selectedSession };
            session.guides = [...action.payload];

            state.selectedSession = { ...session };

            // also need to update the sessions.
            let tmpSessions = [...state.sessions].map((val) => {
                if (val.id === session.id) {
                    return { ...session };
                } else {
                    return { ...val };
                }
            });
            state.sessions = tmpSessions;
            state.success = { type: action.type, message: 'Get Guides Success' };

            // state.guides = [...action.payload];
            //state.openIssuesCount = action.payload.open_issues_count
            state.error = null;
        },

        setSessionCapturesSuccess(state, action) {
            logger.log(action.type, ' : SUCCESS');

            state.loading = false;
            state.success = {
                type: action.type,
                message: 'Captures successfully updated',
            };

            // update the captures
            const captures = [...updateCaptureItems(state.selectedSession.media, action.payload)];
            state.selectedSession = { ...state.selectedSession, media: captures };

            state.error = null;
        },

        applySamplesToCapturesSuccess(state, action) {
            state.loading = false;
            state.success = {
                type: action.type,
                message: 'Captures successfully assigned',
            };

            // update the captures
            const captures = [...updateCaptureItems(state.selectedSession.media, action.payload)];
            state.selectedSession = { ...state.selectedSession, media: captures };

            // const captures = [...updateCaptureItems(state.selectedSession.media,action.payload)];
            // state.selectedSession = {...state.selectedSession,media:captures};

            state.error = null;
        },

        assignCaptureToGuideCategorySuccess(state, action) {
            state.loading = false;
            state.success = {
                type: action.type,
                message: 'Captures successfully assigned',
            };

            if (state.selectedSession && state.selectedSession.media) {
                // update the captures
                const captures = [...updateCaptureItems(state.selectedSession.media, action.payload)];
                state.selectedSession = { ...state.selectedSession, media: captures };
            }

            // const captures = [...updateCaptureItems(state.selectedSession.media,action.payload)];
            // state.selectedSession = {...state.selectedSession,media:captures};

            state.error = null;
        },

        updateSeasonStyleSuccess(state, action) {
            state.loading = false;
            state.success = { type: action.type, message: 'Style successfully updated' };

            state.error = null;
        },

        selectStyles(state, action) {
            logger.log('setting selected samples', action.payload);
            state.selectedStyles = action.payload ? [...action.payload] : [];
        },

        onServiceStart(state, action) {
            state.error = null;
            state.success = null;
        },

        onServiceFailure(state, action) {
            state.error = action.payload;
            state.success = null;
        },
    },
});

// JOB
export const fetchSession = (id, user) => async (dispatch) => {
    try {
        logger.log(id, ' IN SLICE');
        dispatch(getSessionStart());
        const data = await getSession(id, user);
        dispatch(getSessionSuccess(data));
    } catch (err) {
        logger.log('err', err);
        dispatch(getSessionFailure(err));
    }
};

// JOBS
export const fetchSessions = (criteria, user) => async (dispatch) => {
    try {
        dispatch(getSessionsStart());
        const data = await getSessions(criteria, user ? user.rawIdToken : '');
        dispatch(getSessionsSuccess(data));
    } catch (err) {
        logger.log('err', err);
        dispatch(getSessionsFailure(err));
    }
};

export const createSession =
    (session, user, doSelect = true) =>
    async (dispatch) => {
        try {
            dispatch(createSessionStart());
            const data = await udpateSession(session, user ? user.rawIdToken : '');

            if (session.styles) {
                const styles = session.styles;
                var items = [];
                for (let i = 0; i < styles.length; i++) {
                    const style = { ...styles[i] };
                    const result = await addStyleToSession(style, data, user ? user.rawIdToken : '');
                    items.push(result);
                }
            }

            if (session.guides) {
                const guides = session.guides;
                var guideItems = [];
                for (let i = 0; i < guides.length; i++) {
                    const guide = { ...guides[i] };
                    const result = await addGuideToSession(guide, data, user ? user.rawIdToken : '');
                    guideItems.push(result);
                }
            }

            dispatch(createSessionSuccess({ data, doSelect }));
        } catch (err) {
            logger.log('err', err);
            dispatch(createSessionFailure(err));
        }
    };

export const updateSession = (session, user) => async (dispatch) => {
    try {
        dispatch(updateSessionStart());
        const data = await udpateSession(session, user ? user.rawIdToken : '');
        dispatch(updateSessionSuccess(data));
    } catch (err) {
        logger.log('err', err);
        dispatch(updateSessionFailure(err));
    }
};

export const bulkUpdateSessions = (sessions, user) => async (dispatch) => {
    try {
        dispatch(updateSessionStart());

        for (let i = 0; i < sessions.length; i++) {
            await udpateSession(sessions[i], user ? user.rawIdToken : '');
        }

        dispatch(bulkUpdateSessionsSuccess(sessions));
    } catch (err) {
        logger.log('err', err);
        dispatch(updateSessionFailure(err));
    }
};

// TASKS
export const fetchSessionTasks = (criteria, user, signal) => async (dispatch) => {
    try {
        dispatch(onServiceStart());
        const data = await getSessionTasks(criteria, signal);
        dispatch(getSessionTasksSuccess(data, criteria.sessionCode));
    } catch (err) {
        logger.log('err', err);
        dispatch(onServiceFailure(err));
    }
};

export const fetchTasks = (criteria) => async (dispatch) => {
    try {
        dispatch(onServiceStart());
        var tasks = [];
        const data = await getTasks(criteria);
        dispatch(getTasksSuccess({ data: data, sessionCode: criteria.sessionCode }));
    } catch (err) {
        logger.log('err', err);
        dispatch(onServiceFailure(err));
    }
};

export const fetchTask = (criteria) => async (dispatch) => {
    try {
        dispatch(onServiceStart());
        var tasks = [];
        const data = await getTask(criteria);
        dispatch(getTaskSuccess(data));
    } catch (err) {
        logger.log('err', err);
        dispatch(onServiceFailure(err));
    }
};

export const fetchAllTasksByStatus = (criteria, signal) => async (dispatch) => {
    try {
        dispatch(onServiceStart());
        var tasks = [];
        for (var i = 0; i < statuss.length; i++) {
            const status = statuss[i].value;
            const data = await getTasks({ ...criteria, status: status }, signal);
            tasks = [...tasks, ...data];
        }

        dispatch(getTasksSuccess({ data: tasks, sessionCode: criteria.sessionCode }));
    } catch (err) {
        logger.log('err', err);
        dispatch(onServiceFailure(err));
    }
};

// SAMPLES
export const fetchSessionSamples = (criteria, user) => async (dispatch) => {
    try {
        dispatch(onServiceStart());
        const data = await getSamples(criteria);
        dispatch(getSessionSamplesSuccess(data, criteria.sessionId));
    } catch (err) {
        logger.log('err', err);
        dispatch(onServiceFailure(err));
    }
};

export const fetchSessionLiveData = (id) => async (dispatch) => {
    try {
        dispatch(onServiceStart());
        const data = await getSessionLiveData(id);
        dispatch(getSessionLiveDataSuccess(data, id));
    } catch (err) {
        logger.log('err', err);
        dispatch(onServiceFailure(err));
    }
};

/*

export const updateCaptureItems = (items,updatedItems) => {

  var tmpCaptures = [...items]; 

  const updateCapture = (val) => {
    logger.log('val: ', val)
    const idx = findCaptureIndex(tmpCaptures,val);
    
    if(idx > -1){
      
      var newCapture = setObjectFromProps(tmpCaptures[idx],val);
      tmpCaptures = update(tmpCaptures,{[idx]: {$set: newCapture}});  
      // arrCaptures = [...newState];
    }
  }

  logger.log(updatedItems)
  updatedItems.forEach((val,idx) => {  updateCapture(val); });

  return tmpCaptures;

} */

/*export const findCaptureIndex = (arr,val) => {
  let idx = -1;
  if(val){
    idx = findIndex(arr, function(obj) { 
      return (obj.actionPart === val.actionPart && obj.actionSort === val.actionSort) 
    });
  }
  return idx;  
}*/

export const batchUpdateTask =
    (session, tasks, user, updateState = true) =>
    async (dispatch) => {
        try {
            dispatch(updateTaskStart());
            var dataItems = [];
            // loop over all the task items
            // create a separate task for each item
            for (let i = 0; i < tasks.length; i++) {
                const sessionCode = tasks[i].taskPart.split('#')[1];
                const session = { code: sessionCode };
                const data = await udpateTask(session, tasks[i]);
                dataItems.push(data);
            }

            if (updateState) dispatch(batchUpdateTaskSuccess(dataItems));
        } catch (err) {
            logger.log('err', err);
            dispatch(updateTaskFailure(err));
        }
    };

export const updateTask =
    (
        session,
        task,
        user,
        updateState = true,
        onProgress = (e) => {
            logger.log('on progress', e);
        },
    ) =>
    async (dispatch) => {
        try {
            dispatch(updateTaskStart());

            // loop over all the task items
            // create a separate task for each item
            const data = await udpateTask(session, task);
            // for (let i = 0; i < task.items.length; i++) {
            //   const item = task.items[i];
            //   let itemdata = { ...task, item };
            //   delete itemdata.items;
            //   delete itemdata.original;
            //   delete itemdata.user;

            //   const data = await udpateTask(session, itemdata);

            //   results.push(data);
            // }

            if (task.files && task.files.length) {
                for (let j = 0; j < task.files.length; j++) {
                    const file = task.files[j];

                    const fileReq = await getFileUploadPath(file, task, session);

                    logger.log(fileReq);
                    await uploadTaskFileWithProgress(file, task, fileReq.url, user, onProgress);
                }
            }

            if (updateState) dispatch(updateTaskSuccess(data));
        } catch (err) {
            logger.log('err', err);
            dispatch(updateTaskFailure(err));
        }
    };

export const deleteTask = (task, user) => async (dispatch) => {
    try {
        dispatch(updateTaskStart());
        const data = await deleteTask(task, user ? user.rawIdToken : '');
        dispatch(updateTaskSuccess(data));
    } catch (err) {
        logger.log('err', err);
        dispatch(updateTaskFailure(err));
    }
};

export const fetchSessionMedia = (criteria, signal) => async (dispatch) => {
    try {
        dispatch(onServiceStart());
        var cursor = '';
        var items = [];
        do {
            const data = await getCaptures(criteria, cursor, signal);
            cursor = data.nextCursor;
            items.push(...data.items);

            dispatch(getSessionMediaSuccess({ items: [...items], complete: false }));
        } while (cursor);

        dispatch(getSessionMediaSuccess({ items: [...items], complete: true }));
    } catch (err) {
        logger.log('err', err);
        dispatch(onServiceFailure(err));
    }
};

// CAPTURES
export const setSessionCaptures = (captures, user) => async (dispatch) => {
    try {
        logger.log(captures, ' SETTING CAPTURES');
        dispatch(onServiceStart());
        dispatch(setSessionCapturesSuccess(captures));

        for (let i = 0; i < captures.length; i++) {
            const capture = captures[i];
            await updateCapture(capture);
        }
        //dispatch(setSessionCapturesSuccess(captures))
    } catch (err) {
        logger.log('err', err);
        dispatch(onServiceFailure(err));
    }
};

// STYLES
export const fetchSessionStyles = (criteria, user) => async (dispatch) => {
    try {
        dispatch(onServiceStart());
        const data = await getSessionStyles(criteria, user ? user.rawIdToken : '');
        dispatch(getSessionStylesSuccess(data));
    } catch (err) {
        logger.log('err', err);
        dispatch(onServiceFailure(err));
    }
};

export const assignStylesToSession = (styles, session, user) => async (dispatch) => {
    try {
        dispatch(onServiceStart());
        var items = [];
        for (let i = 0; i < styles.length; i++) {
            const style = { ...styles[i] };
            const data = await addStyleToSession(style, session, user ? user.rawIdToken : '');
            items.push(data);
        }
        dispatch(assignStylesToSessionSuccess(items));
    } catch (err) {
        logger.log('err', err);
        dispatch(onServiceFailure(err));
    }
};
export const removeStylesFromSession = (styles, session, user) => async (dispatch) => {
    try {
        var items = [];
        dispatch(onServiceStart());
        for (let i = 0; i < styles.length; i++) {
            const style = styles[i];
            const data = await deleteStyleFromSession(style, session, user ? user.rawIdToken : '');
            items.push(data);
        }

        dispatch(removeStylesFromSessionSuccess(styles));
    } catch (err) {
        logger.log('err', err);
        dispatch(onServiceFailure(err));
    }
};

export const assignTalentToStyles = (styles, session, user) => async (dispatch) => {
    try {
        dispatch(onServiceStart());
        var items = [];
        for (let i = 0; i < styles.length; i++) {
            const style = { ...styles[i] };
            const data = await updateSessionStyle(style, session);
            items.push(data);
        }
        dispatch(assignTalentToStylesSuccess(items));
    } catch (err) {
        logger.log('err', err);
        dispatch(onServiceFailure(err));
    }
};

// SAMPLES
// export const assignSamplesToSession = ( styles,session, user ) => async dispatch => {
//   try {
//     var items = [];
//     dispatch(onServiceStart())
//     for(let i=0; i<styles.length; i++){
//       const style = styles[i];
//       const data = await addStyleToSession(style,session,user?user.rawIdToken:'');
//       items.push(data);
//     }
//     dispatch(assignStylesToSessionSuccess(items))
//   } catch (err) {
//     logger.log('err',err)
//     dispatch(onServiceFailure(err))
//   }
// }
// export const removeSampleFromSession = ( styles,session, user ) => async dispatch => {
//   try {

//     dispatch(onServiceStart())
//     for(let i=0; i<styles.length; i++){
//       const style = styles[i];
//       const data = await deleteStyleFromSession(style,session,user?user.rawIdToken:'')
//     }
//     dispatch(removeStylesFromSessionSuccess(styles))
//   } catch (err) {
//     logger.log('err',err)
//     dispatch(onServiceFailure(err))
//   }
// }

// Session Guides
export const assignGuideToSession = (guide, session, user) => async (dispatch) => {
    try {
        dispatch(onServiceStart());
        await addGuideToSession(guide, session, user ? user.rawIdToken : '');
        dispatch(assignGuideToSessionSuccess(guide));
    } catch (err) {
        logger.log('err', err);
        dispatch(onServiceFailure(err));
    }
};
export const removeGuideFromSession = (guide, session, user) => async (dispatch) => {
    try {
        dispatch(onServiceStart());
        await deleteGuideFromSession(guide, session, user ? user.rawIdToken : '');
        dispatch(removeGuideFromSessionSuccess(guide));
    } catch (err) {
        logger.log('err', err);
        dispatch(onServiceFailure(err));
    }
};

export const fetchSessionGuides = (guideList, user) => async (dispatch) => {
    try {
        dispatch(onServiceStart());
        var guides = [];

        for (let i = 0; i < guideList.length; i++) {
            const guide = await getGuide(guideList[i], user ? user.rawIdToken : '');
            guides.push(guide);
        }
        dispatch(getSessionGuidesSuccess(guides));
    } catch (err) {
        logger.log('err', err);
        dispatch(onServiceFailure(err));
    }
};

// GUIDE CATEGORIES
export const fetchSampleGuideCategories = (session, user) => async (dispatch) => {
    try {
        dispatch(onServiceStart());
        var guides = [];
        for (let i = 0; i < session.guides.length; i++) {
            var guide = { ...session.guides[i] };
            const data = await getGuide(guide.id, user ? user.rawIdToken : '');
            // const data = await getGuideCategories(guide,user?user.rawIdToken:'');
            // guide.guideCategories = [...data];
            guides.push(data);
        }

        dispatch(getSampleGuideCategoriesSuccess(guides));
    } catch (err) {
        logger.log('err', err);
        dispatch(onServiceFailure(err));
    }
};

export const applySamplesToCaptures = (samples, captures, description) => async (dispatch) => {
    try {
        dispatch(onServiceStart());
        // we need to loop over all selected captures and loop over all selected samples.
        // for(let i = 0; i<samples.length; i++){
        //   const sample = samples[i];
        const result = [];
        for (let j = 0; j < captures.length; j++) {
            var capture = { ...captures[j] };
            const data = await addSampleToCapture(samples, capture, description);
            capture.styles = data.styles;
            result.push(capture);
        }
        // }

        dispatch(applySamplesToCapturesSuccess(result));
    } catch (err) {
        logger.log('err', err);
        dispatch(onServiceFailure(err));
    }
};

export const assignCaptureToGuideCategory = (capture) => async (dispatch) => {
    try {
        dispatch(onServiceStart());
        dispatch(assignCaptureToGuideCategorySuccess([capture]));
        await updateCapture(capture);
    } catch (err) {
        logger.log('err', err);
        dispatch(onServiceFailure(err));
    }
};

export const updateSeasonStyle = (season, sample) => async (dispatch) => {
    try {
        dispatch(onServiceStart());
        const data = await updateSample(season, sample);
        dispatch(updateSeasonStyleSuccess(data));
    } catch (err) {
        logger.log('err', err);
        dispatch(onServiceFailure(err));
    }
};

export const {
    selectSession,
    selectSessions,
    setDisplayPrefs,
    setSessionsFilter,
    setSessionFilters,
    cleanupUnusedFilters,

    getSessionStart,
    getSessionFailure,
    getSessionSuccess,

    getSessionsStart,
    getSessionsFailure,
    getSessionsSuccess,

    updateSessionStart,
    updateSessionFailure,
    updateSessionSuccess,

    bulkUpdateSessionsSuccess,

    createSessionStart,
    createSessionFailure,
    createSessionSuccess,

    selectTask,
    selectTasks,
    setTaskFilter,

    selectStyles,

    setSuccess,

    getTasksSuccess,
    getSessionTasksSuccess,

    getTaskSuccess,

    updateTaskStart,
    updateTaskSuccess,
    updateTaskFailure,

    batchUpdateTaskSuccess,

    getSessionMediaSuccess,
    setSessionCapturesSuccess,

    getSessionStylesSuccess,
    assignStylesToSessionSuccess,
    removeStylesFromSessionSuccess,

    assignTalentToStylesSuccess,

    getSessionSamplesSuccess,
    assignSamplesToSessionSuccess,
    removeSamplesFromSessionSuccess,

    assignGuideToSessionSuccess,
    removeGuideFromSessionSuccess,

    applySamplesToCapturesSuccess,

    getSessionGuidesSuccess,
    getSampleGuideCategoriesSuccess,

    assignCaptureToGuideCategorySuccess,

    updateSeasonStyleSuccess,

    getSessionLiveDataSuccess,

    clearNotifications,
    onServiceStart,
    onServiceFailure,
} = sessionSlice.actions;

export default sessionSlice.reducer;
