import { combineReducers, configureStore } from '@reduxjs/toolkit';

// Import the `thunk` middleware
import thunk from 'redux-thunk';
import { logger } from 'utils/logger';

import { persistStore, persistReducer } from 'redux-persist';
import storage from 'redux-persist/lib/storage'; // defaults to localStorage for web

import appReducer from '../app/appSlice';
import warehouseReducer from '../modules/Warehouse/warehouseSlice';
import outfitterReducer from '../modules/Warehouse/outfitterSlice';
import authReducer from '../modules/Auth/authSlice';
import sessionReducer from '../modules/Sessions/sessionSlice';
import settingsReducer from '../modules/Settings/settingsSlice';
import approvalReducer from '../modules/Approval/approvalSlice';
import guideReducer from '../modules/Guides/guideSlice';
import seasonReducer from '../modules/Seasons/seasonSlice';
import styleReducer from '../modules/Styles/styleSlice';
import talentReducer from '../modules/Talent/talentSlice';

const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['sessionReducer', 'seasonReducer', 'warehouse','outfitter', 'styleReducer'],
};

const sessionPersistConfig = {
    key: 'sessionReducer',
    storage: storage,
    whitelist: ['taskFilter', 'sessionFilters', 'sessionsFilter', 'displayPrefs'],
};

const seasonPersistConfig = {
    key: 'seasonReducer',
    storage: storage,
    whitelist: ['seasonSampleFilters', 'seasonFilter'],
};

const warehousePersistConfig = {
    key: 'warehouse',
    storage: storage,
    whitelist: ['scanFilter', 'sampleFilter'],
};

const stylePersistConfig = {
    key: 'styleReducer',
    storage: storage,
    whitelist: ['distStyleFilter', 'wipStyleFilter', 'displayPrefs'],
};

const stateName = `${process.env.NODE_ENV}_persistantState`;

export function clearLocalStorage() {
    logger.log('clearing local storage');
    localStorage.removeItem(stateName);
}

// this is your global config
const rootReducer = combineReducers({
    sessionReducer: persistReducer(sessionPersistConfig, sessionReducer),
    warehouse: persistReducer(warehousePersistConfig, warehouseReducer),
    outfitter: outfitterReducer,
    guideReducer: guideReducer,
    seasonReducer: persistReducer(seasonPersistConfig, seasonReducer),
    settingsReducer: settingsReducer,
    appReducer: appReducer,
    auth: authReducer,
    approvalReducer: approvalReducer,
    styleReducer: persistReducer(stylePersistConfig, styleReducer),
    talentReducer: talentReducer,
});

const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    devTools: false,
    middleware: [thunk],
});

export const persistor = persistStore(store);
