import { useMantineTheme } from '@mantine/core';
import { DatePicker } from '@mantine/dates';
import moment from 'moment';
import { logger } from 'utils/logger';

const CustomDatePicker = (props) => {
    const theme = useMantineTheme();
    const {returnType,onChange, ...rest} = props;

    const changeHandler = (val) => {
        if (props.returnType && props.returnType === ReturnTypes.UTC) {

            // Use moment to convert the date to UTC
            const utcMoment = moment(val);

            // Store the UTC date as an ISO string
            const utcDateString = utcMoment.toISOString();

            props.onChange(val);
        } else {
            props.onChange(val);
        }
    };
    return (
        <DatePicker
            dayStyle={(date) => {
                const today = moment(new Date());
                const dateVal = moment(props.value);
                const dateVar = moment(date);

                if (today.isSame(dateVar, 'day') && !dateVal.isSame(dateVar)) {
                    return {
                        backgroundColor: theme.colors.gray[theme.fn.primaryShade()],
                        color: theme.white,
                        // ...theme.fn.hover({ backgroundColor: theme.colors.gray[7] }),
                    };
                } else {
                    return null;
                }
            }}
            {...rest}
            onChange={(val) => changeHandler(val)}
        />
    );
};
export default CustomDatePicker;


export const ReturnTypes = {
    UTC:'utc'
}