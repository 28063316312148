import { DateSelections, getDateRanges } from "utils/helper";

export class SampleFilterModel {
    constructor(obj) {
        this.keyword = '';
        this.brands = [];
        this.departments = [];
        this.location = [];
        this.status = [];
        this.colour = '';
        this.sortBy = null;
        this.pageIndex = 0;
        this.distribution = { dateStart: '', dateEnd: '' };
        this.completed = { dateStart: '', dateEnd: '' };
        this.otbDate = { dateStart: '', dateEnd: '' };
        this.sessions = [];
        this.stylesCodes = [];
        this.issuers = [];
        this.seasons = [];
        this.statuses = [];
        this.talents = [];
        this.depts = [];
        this.photoCode = [];
        this.multiShotFlag = '';

        this.dataFilter = {
            dateRange: {
                item: DateSelections[1],
                value: [getDateRanges(DateSelections[1]).startDate, getDateRanges(DateSelections[1]).endDate],
            },
            timeRange: ['', ''],
        }
        

        if (obj) {
            Object.assign(this, obj);
        }
    }
   
}
