const { Group, Button, Box, MultiSelect, RangeSlider } = require("@mantine/core");
const { default: FormInputWrapper } = require("../formInputWrapper/FormInputWrapper");
const { useForm } = require("@mantine/form");

const TalentFilterPanel = ({ onCancel, onApply, filter, filterItems }) => {

    const form = useForm({
        initialValues: {
            ...filter,
        },
    });

    const getFilterVars = (key) => {
        var res = [];
        if (filterItems[key]) Object.keys(filterItems[key]).forEach((val) => res.push(val));
        return res;
    };

    return (
        <Box shadow='xs' style={{ width: '100%' }}>
            <form onSubmit={form.onSubmit((values) => onApply(values))}>
                <FormInputWrapper label='Agency' id='input-agency' labelWidth={'120px'}>
                    <MultiSelect data={getFilterVars('agencies')} label='' placeholder='Pick all that you like' searchable nothingFound='Nothing found' {...form.getInputProps('agency')} />
                </FormInputWrapper>

                <FormInputWrapper label='Status' id='input-status' labelWidth={'120px'}>
                    <MultiSelect data={getFilterVars('statuses')} label='' placeholder='Pick all that you like' searchable nothingFound='Nothing found' {...form.getInputProps('status')} />
                </FormInputWrapper>

                {/* <FormInputWrapper label='Status' id='input-status' labelWidth={'120px'}>
                    <MultiSelect data={getFilterVars('statuses')} label='' placeholder='Pick all that you like' searchable nothingFound='Nothing found' {...form.getInputProps('status')} />
                </FormInputWrapper> */}
                <FormInputWrapper label='Gender' id='input-gender' labelWidth={'120px'}>
                    <MultiSelect data={getFilterVars('gender')} label='' placeholder='Pick all that you like' searchable nothingFound='Nothing found' {...form.getInputProps('gender')} />
                </FormInputWrapper>

                <FormInputWrapper label='Category' id='input-gender' labelWidth={'120px'}>
                    <MultiSelect data={getFilterVars('categories')} label='' placeholder='Pick all that you like' searchable nothingFound='Nothing found' {...form.getInputProps('category')} />
                </FormInputWrapper>

                <FormInputWrapper label='Age' id='input-age' labelWidth={'120px'}>
                    <RangeSlider defaultValue={''} marks={marks} {...form.getInputProps('age')} />
                </FormInputWrapper>

                {/* <FormInputWrapper label='Brands' id='input-brands' labelWidth={'120px'}>
          <MultiSelect
            data={getFilterVars('brands')}
            label=''
            placeholder='Pick all that you like'
            searchable
            nothingFound='Nothing found'
            {...form.getInputProps('brands')}
          />
        </FormInputWrapper> */}

                {/* <FormInputWrapper
          label='Category'
          id='input-category'
          labelWidth={'120px'}
        >
          <MultiSelect
            data={getFilterVars('categories')}
            label=''
            placeholder='Pick all that you like'
            searchable
            nothingFound='Nothing found'
            {...form.getInputProps('categories')}
          />
        </FormInputWrapper> */}

                {/* <FormInputWrapper
          label='Department'
          id='input-department'
          labelWidth={'120px'}
        >
          <MultiSelect
            data={[
              'React',
              'Angular',
              'Svelte',
              'Vue',
              'Riot',
              'Next.js',
              'Blitz.js',
            ]}
            label=''
            placeholder='Pick all that you like'
            searchable
            nothingFound='Nothing found'
            {...form.getInputProps('department')}
          />
        </FormInputWrapper>

        <FormInputWrapper
          label='Location'
          id='input-location'
          labelWidth={'120px'}
        >
          <MultiSelect
            data={[
              'React',
              'Angular',
              'Svelte',
              'Vue',
              'Riot',
              'Next.js',
              'Blitz.js',
            ]}
            label=''
            placeholder='Pick all that you like'
            searchable
            nothingFound='Nothing found'
            {...form.getInputProps('location')}
          />
        </FormInputWrapper> */}

                {/* <FormInputWrapper label='Colour' id='input-colour' labelWidth={'120px'}>
          <ColorInput
            placeholder='Pick color'
            {...form.getInputProps('colour')}
          />
        </FormInputWrapper>

        <FormInputWrapper
          label='Assigned Product'
          id='input-colour'
          labelWidth={'120px'}
        >
          <Switch />
        </FormInputWrapper> */}

                <Group position='apart' mt='lg'>
                    <Button component='button' variant='subtle' size='sm' onClick={() => onCancel()}>
                        Cancel
                    </Button>
                    <Button type='submit' size='sm'>
                        Apply
                    </Button>
                </Group>
            </form>
        </Box>
    );
};

export default TalentFilterPanel;



const marks = [
    { value: 20, label: '20' },
    { value: 50, label: '50' },
    { value: 80, label: '80' },
];

