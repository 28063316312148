import React from 'react';
import ReactDOM, { createRoot } from 'react-dom/client';

import { Provider } from 'react-redux';
import App from './app/App';
import './index.scss';

import reportWebVitals from './reportWebVitals';

import { PersistGate } from 'redux-persist/integration/react';
import { persistor, store } from 'app/store';


// const root = ReactDOM.createRoot(document.getElementById("root"));
// root.render(
//   <React.StrictMode>
//     <Provider store={store}>
//     <App />
//     </Provider>
//   </React.StrictMode>
// );

const container = document.getElementById('root');
const root = createRoot(container)

root.render(
<React.StrictMode>
    <Provider store={store}>
        <PersistGate loading={null} persistor={persistor}>
        <App />
        </PersistGate>
    </Provider>
</React.StrictMode>,
)




// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(logger.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
