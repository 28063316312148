import { ActionIcon, Box, Button, Group, Input, MultiSelect, Popover, Text, Title } from '@mantine/core';

import { ListViewOption } from 'core/components/listToggle/listToggleControl';
import { fetchSeasons, getSeasonsSuccess, selectSeason, selectSeasons, setSeasonFilter } from 'modules/Seasons/seasonSlice';
import { useCallback, useEffect, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useLocation, useNavigate } from 'react-router-dom';
import { CheckboxDropdown, FilterButton, FormInputWrapper } from 'core/components';

import { useForm } from '@mantine/form';
import SeasonList from './SeasonList';
import SeasonEdit from '../season/SeasonEdit';
import { IconSearch, IconX } from '@tabler/icons';
import { SeasonModel } from 'core/models/SeasonModel';
import { selectItems, useEffectOnce } from 'utils/helper';
import { logger } from 'utils/logger';
import { closeAllModals, openModal } from '@mantine/modals';
import { SortByModel } from 'core/models';
import moment, { isDate } from 'moment';
import CustomDatePicker, { ReturnTypes } from 'core/components/datePicker/CustomDatePicker';

// import Dashboard from './features/components/Dashboard';

const Seasons = ({ user, season, seasons, selectedSeasons, success, error, setSeasonFilter, seasonFilter, selectSeasons }) => {
    const dispatch = useDispatch();
    const [filterOpen, setFilterOpen] = useState(false);
    const [listView, setListView] = useState(ListViewOption.LIST);
    const navigate = useNavigate();
    const location = useLocation();
    // const [selectedItems, setSelectedItems] = useState(selectedSeasons);
    const [filterCount, setFilterCount] = useState(0);
    const [seasonData, setSeasonData] = useState(seasons);
    const [filterItems, setFilterItems] = useState(seasonFilter);
    const [isLoading, setIsLoading] = useState(false);
    const [sortBy, setSortBy] = useState(new SortByModel());

    const selectAllMenu = (
        <div>
            {/* <Button variant='default' onClick={(e) => onMenuItemClick(e)}>
                Create new Season
            </Button>
            <Button variant='default' onClick={(e) => onMenuItemClick(e)}>
                Add to Season
            </Button>
            <Button
                variant='default'
                onClick={(e) => onMenuItemClick(SeasonActions.BUILD_OUTFIT)}>
                Build Outfit
            </Button> */}
            <Button variant='default' onClick={(e) => onSelectAll(e)}>
                Deselect All
            </Button>
        </div>
    );

    /**
     * Handles the list toggle display
     * @param {*} val
     */
    // const listToggleHandler = (val) => {
    //     navigate({ search: `?displayType=${val}` });
    // };

    /**
     * Effect to fetch the seasons
     */
    useEffectOnce(() => {
        setTimeout(() => {
            // setIsLoading(true);
            if(!seasons || seasons && seasons.length === 0){
                setIsLoading(true);
            }
            dispatch(fetchSeasons());
            dispatch(selectSeason(null));
        }, 100);
    }, [dispatch]);

    const filterSeasons = useCallback(() => {
        // if the filter hasn't changed
        const data = seasons.filter((season) => {
            var match = true;
            // filter by brand
            if (seasonFilter.brands && seasonFilter.brands.length) {
                match = seasonFilter.brands.indexOf(season.facet_brand) > -1 ? true : false;
            }

            if (seasonFilter.categories && seasonFilter.categories.length && match) {
                match = seasonFilter.categories.indexOf(season.metadata_toplevelcategory) > -1 ? true : false;
            }
            if (seasonFilter.tags && seasonFilter.tags.length && match) {
                var tag;
                for (let i = 0; i < seasonFilter.tags.length; i++) {
                    tag = season.tags?.indexOf(seasonFilter.tags[i]) > -1 ? true : false;
                    if (tag) {
                        break;
                    }
                }
                match = tag ? true : false;
            }

            const seasonDeadline = new Date(season.deadline);
            // Deadline filter dates
            if (seasonFilter.deadline.dateStart && seasonFilter.deadline.dateEnd && match) {
                match = seasonDeadline >= moment(seasonFilter.deadline.dateStart).toDate() && seasonDeadline <= moment(seasonFilter.deadline.dateEnd).toDate() ? true : false;
            } else if (seasonFilter.deadline.dateStart && seasonFilter.deadline.dateEnd === '' && match) {
                match = seasonDeadline >= moment(seasonFilter.deadline.dateStart).toDate() ? true : false;
            } else if (seasonFilter.deadline.dateEnd && seasonFilter.deadline.dateStart === '' && match) {
                match = seasonDeadline <= moment(seasonFilter.deadline.dateEnd).toDate() ? true : false;
            }

            if (seasonFilter.keyword && seasonFilter.keyword.length && match) {
                match = season.name.toLowerCase().indexOf(seasonFilter.keyword.toLowerCase()) > -1 ? true : false;

                if (!match) {
                    match = season.description.toLowerCase().indexOf(seasonFilter.keyword.toLowerCase()) > -1 ? true : false;
                }
            }
            return match;
        });

        setSeasonData(data);
    }, [seasons, seasonFilter.brands, seasonFilter.categories, seasonFilter.keyword, seasonFilter.deadline, seasonFilter.tags]);

    useEffect(() => {
        //setSeasonData(seasons);

        // set the brands/season/category
        var brands = {};
        var categories = {};
        var seasonsMap = {};
        var tags = {};

        seasons.forEach((item, idx) => {
            brands[item.facet_brand] = item;
            categories[item.metadata_toplevelcategory] = item;
            seasonsMap[item.facet_season] = item;
            tags[item.tags] = item;
        });

        setFilterItems({ brands, categories, seasonsMap, tags });

        filterSeasons();
    }, [seasons, filterSeasons]);

    useEffect(() => {
        logger.log('SUCCESS', success);
        if (success && success.type === getSeasonsSuccess.toString()) {
            setIsLoading(false);
        }
    }, [success]);
    /**
     * Effect to handle location change
     */
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        let displayType = params.get('displayType');
        logger.log('location change ', location, ' DISPLAY: ', displayType, params);
        // if display type changes
        if (displayType && displayType !== listView) {
            displayType = displayType === ListViewOption.BOARD?ListViewOption.LIST:displayType;
            setListView(displayType);
        }
    }, [location, listView]);

    const onItemClick = (item) => {
        // we need to select the current season
        // dispatch(selectSeason(item));

        // update the route
        navigate(`/seasons/${item.id}`);
    };

    const onItemSelected = (e, item, selected, rows) => {
        logger.log(item, selectedSeasons);
        var items = [...selectedSeasons];

        // call general helper to select items
        selectItems(e, item, selected, rows.map(val => val.original), items);

        //setSelectedItems(items);
        //dispatch(selectSeasons(items));
        selectSeasons(items);
    };

    const onSelectAll = () => {
        // if there are items selected remove them
        if (selectedSeasons.length) {
            //setSelectedItems([])
            dispatch(selectSeasons([]));
        } else {
            //setSelectedItems([...elements])
            dispatch(selectSeasons([...seasonData]));
        }
    };

    // const onMenuItemClick = (e) => {
    //     switch (e) {
    //         case SeasonActions.BUILD_OUTFIT: {
    //             navigate(`/seasons/create`);
    //             break;
    //         }
    //         default: {
    //             break;
    //         }
    //     }
    // };

    const setFilterVars = (vals) => {
        logger.log(vals, ' FILTER VALS');
        var filter = {...vals};
        
        setSeasonFilter(vals);
    };

    useEffect(() => {
        // filter the data.
        filterSeasons();
    }, [seasonFilter, filterSeasons]);

    const getFilterCount = (val) => {
        var count = 0;
        Object.entries(val).forEach(([key, value]) => {
            logger.log(`${key}: ${value}`);
            if (value.length) {
                ++count;
            }

            if (key === 'deadline') {
                if (value.dateStart?.toString().length || value.dateEnd?.toString().length) {
                    ++count;
                }
            }
        });

        return count;
    };

    useEffect(() => {
        const count = getFilterCount(seasonFilter);
        setFilterCount(count);
    }, [seasonFilter]);

    const onClearClick = (e) => {
        e.stopPropagation();
        e.nativeEvent.stopPropagation();

        setSeasonFilter({
            brands: [],
            department: [],
            location: [],
            status: [],
            colour: '',
            keyword: '',
            deadline: { dateStart: '', dateEnd: '' },
        });

        logger.log('CLEAR CLICK', e);
    };

    const filterButtonHandler = (e) => {
        logger.log('BUTTON CLICK', e, e.nativeEvent);
        setFilterOpen((o) => !o);
    };

    const onCreateClick = () => {
        // create a new season
        var tmpSeason = new SeasonModel();

        logger.log(tmpSeason);
        selectSeason(tmpSeason);

        openSeasonModal();
        // setSeasonModalOpen(true);
    };

    const openSeasonModal = () => {
        openModal({
            title: 'Create a new Event',
            size: 'xl',
            children: (
                <>
                    <SeasonEdit onCancel={closeAllModals} />
                </>
            ),
        });
    };

    const onSortChange = (val) => {
        logger.log(val);
        if (val && val.length && val[0].id !== sortBy.id) {
            setSortBy({ ...val[0] });
        }
    };


    logger.log(seasonFilter, "==========")


    return (
        <Box px='lg' sx={(theme) => ({background:theme.colorScheme === 'dark'?theme.colors.dark[8]:theme.white,  display:'flex', flexDirection:'column', height:'100%', overflow:'hidden'})}>
            <Group
                className='main-container-header'
                position='apart'
                sx={(theme) => ({
                    borderBottom: `1px solid ${theme.colors.gray[3]}`,
                    borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[2],
                    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
                })}>
                {/* <Crumbline></Crumbline> */}
                <Title order={3}>Events</Title>
                <Button onClick={() => onCreateClick()}>Create Event</Button>
            </Group>

            <Group className='container-action-bar' sx={{ width: '100%' }} position='apart' py='xs' mt='sm'>
                <Group>
                    <CheckboxDropdown selectedItems={selectedSeasons} onCheckboxClick={onSelectAll} selectMenu={selectAllMenu} />

                    <Input
                        icon={<IconSearch />}
                        sx={{ width: 250 }}
                        value={seasonFilter.keyword}
                        onChange={(e) =>
                            setSeasonFilter({
                                ...seasonFilter,
                                keyword: e.currentTarget.value,
                            })
                        }
                        placeholder={`Search across ${seasonData?.length} Events`}
                        rightSection={
                            <ActionIcon
                                onClick={() =>
                                    setSeasonFilter({
                                        ...seasonFilter,
                                        keyword: '',
                                    })
                                }>
                                {seasonFilter.keyword.length ? <IconX size={14} /> : null}
                            </ActionIcon>
                        }
                    />

                    <Text size='sm'>
                        Total{' '}
                        <strong>
                            {seasonData.length} of {seasons?.length}
                        </strong>{' '}
                    </Text>
                </Group>

                <Group>
                    <Popover shadow='sm' opened={filterOpen} onClose={() => setFilterOpen(false)} width={600} position='bottom' withArrow closeOnClickOutside={true}>
                        <Popover.Target>
                            <div>
                                <FilterButton onClick={filterButtonHandler} onClear={onClearClick} count={filterCount} />
                            </div>
                        </Popover.Target>
                        <Popover.Dropdown>
                            <div style={{ display: 'flex' }}>
                                <FilterPanel
                                    filter={seasonFilter}
                                    onCancel={() => setFilterOpen(false)}
                                    onApply={(vals) => {
                                        setFilterVars(vals);
                                        setFilterOpen(false);
                                    }}
                                    filterItems={filterItems}
                                />
                            </div>
                        </Popover.Dropdown>
                    </Popover>

                    {/* <Select
                placeholder="Filter By All"
                data={[
                  { value: '', label: 'Show All' },
                  { value: 'sku', label: 'Filter by SKU' },
                  { value: 'location', label: 'Filter by Location' },
                  { value: 'status', label: 'Filter by Status' },
                ]}
              /> */}

                    {/* <Select
                        placeholder='Sort By'
                        value={sortBy.id}
                        onChange={(val) => setSortBy({ ...new SortByModel({ id: val }) })}
                        data={[
                            { value: 'name', label: 'Sort by Name' },
                            { value: 'description', label: 'Sort by Description' },
                            { value: 'tags', label: 'Sort by Tags' },
                            { value: 'deadline', label: 'Sort by Deadline' },
                        ]}
                    /> */}

                    {/* <ListToggleControl
                        value={listView}
                        onChange={(val) => listToggleHandler(val)}
                    /> */}
                </Group>
            </Group>

            {/* <ScrollArea style={{height:500}} > */}
            <Box className='main-container-content' mt='lg' sx={{flexGrow:1, overflow:'hidden', marginRight:-20}}>
                {listView === ListViewOption.LIST ? (
                    <SeasonList
                        isLoading={isLoading}
                        data={seasonData}
                        onItemClick={onItemClick}
                        onItemSelected={onItemSelected}
                        selectedItems={selectedSeasons}
                        manualSortBy={sortBy}
                        sortChange={(val) => onSortChange(val)}
                    />
                ) : (
                    <div data={seasonData} onItemClick={onItemClick} onItemSelected={onItemSelected} selectedItems={selectedSeasons} />
                )}
            </Box>
            {/* </ScrollArea> */}

            {/* {filterOpen ? (
                <Box
                    style={{
                        backgroundColor: '#000',
                        opacity: '0.05',
                        position: 'absolute',
                        top: '155px',
                        right: 0,
                        bottom: 0,
                        left: '0px',
                    }}></Box>
            ) : null} */}
        </Box>
    );
};

const FilterPanel = ({ onCancel, onApply, filter, filterItems }) => {
    logger.log(filter);

    const form = useForm({
        initialValues: {
            ...filter,
            deadline:{
                dateStart:filter.deadline?.dateStart?.toString().length?moment(filter.deadline?.dateStart).toDate():'',
                dateEnd:filter.deadline?.dateEnd?.toString().length?moment(filter.deadline?.dateEnd).toDate():'',
            }
        },
    });

    // useEffect(() => {
    //     if (filter && form) {
    //         form.setValues({ ...filter });
    //     }

    //     logger.log(form.getInputProps('status'));
    // }, [filter, form]);

    const getFilterVars = (key) => {
        var res = [];
        if (filterItems[key]) Object.keys(filterItems[key]).forEach((val) => res.push(val));
        return res;
    };

    return (
        <Box shadow='xs' style={{ width: '100%' }}>
            <form onSubmit={form.onSubmit((values) => onApply(values))}>
                <FormInputWrapper label='Due Date' id='input-shootdate' labelWidth={'120px'}>
                    <Group noWrap>
                        <CustomDatePicker returnType={ReturnTypes.UTC} sx={{ width: 190 }} placeholder='From Due Date' {...form.getInputProps('deadline.dateStart')} />
                        <Text>to</Text>
                        <CustomDatePicker returnType={ReturnTypes.UTC} sx={{ width: 190 }} placeholder='To Due Date' {...form.getInputProps('deadline.dateEnd')} />
                    </Group>
                </FormInputWrapper>
                <FormInputWrapper label='Tags' id='input-tags' labelWidth={'120px'}>
                    <MultiSelect data={getFilterVars('tags')} label='' placeholder='Pick all that you like' searchable nothingFound='Nothing found' {...form.getInputProps('tags')} />
                </FormInputWrapper>
                {/*                 
                <FormInputWrapper label='Brands' id='input-brands' labelWidth={'120px'}>
                    <MultiSelect data={getFilterVars('brands')} label='' placeholder='Pick all that you like' searchable nothingFound='Nothing found' {...form.getInputProps('brands')} />
                </FormInputWrapper>

                <FormInputWrapper label='Category' id='input-category' labelWidth={'120px'}>
                    <MultiSelect data={getFilterVars('categories')} label='' placeholder='Pick all that you like' searchable nothingFound='Nothing found' {...form.getInputProps('categories')} />
                </FormInputWrapper>

                <FormInputWrapper label='Department' id='input-department' labelWidth={'120px'}>
                    <MultiSelect
                        data={['React', 'Angular', 'Svelte', 'Vue', 'Riot', 'Next.js', 'Blitz.js']}
                        label=''
                        placeholder='Pick all that you like'
                        searchable
                        nothingFound='Nothing found'
                        {...form.getInputProps('department')}
                    />
                </FormInputWrapper>

                <FormInputWrapper label='Location' id='input-location' labelWidth={'120px'}>
                    <MultiSelect
                        data={['React', 'Angular', 'Svelte', 'Vue', 'Riot', 'Next.js', 'Blitz.js']}
                        label=''
                        placeholder='Pick all that you like'
                        searchable
                        nothingFound='Nothing found'
                        {...form.getInputProps('location')}
                    />
                </FormInputWrapper>

                <FormInputWrapper label='Status' id='input-status' labelWidth={'120px'}>
                    <MultiSelect
                        data={[
                            { label: 'United States', value: 'US' },
                            { label: 'Great Britain', value: 'GB' },
                            { label: 'Finland', value: 'FI' },
                            { label: 'France', value: 'FR' },
                            { label: 'Russia', value: 'RU' },
                        ]}
                        label=''
                        placeholder='Pick all that you like'
                        searchable
                        nothingFound='Nothing found'
                        {...form.getInputProps('status')}
                    />
                </FormInputWrapper>

                <FormInputWrapper label='Colour' id='input-colour' labelWidth={'120px'}>
                    <ColorInput placeholder='Pick color' {...form.getInputProps('colour')} />
                </FormInputWrapper>

                <FormInputWrapper label='Assigned Product' id='input-colour' labelWidth={'120px'}>
                    <Switch />
                </FormInputWrapper> */}

                <Group position='apart' mt='lg'>
                    <Button variant='subtle' size='sm' onClick={() => onCancel()}>
                        Cancel
                    </Button>
                    <Button type='submit' size='sm'>
                        Apply
                    </Button>
                </Group>
            </form>
        </Box>
    );
};

const mapStateToProps = (state, props) => {
    //logger.log(state,props, ' mapstate')
    return {
        user: state.auth.user,
        season: state.seasonReducer.selectedSeason,
        seasons: state.seasonReducer.seasons,
        seasonFilter: state.seasonReducer.seasonFilter,
        selectedSeasons: state.seasonReducer.selectedSeasons,
        success: state.seasonReducer.success,
        error: state.auth.error,
    };
};

const mapDispatchToProps = { setSeasonFilter, selectSeasons, selectSeason };

export default connect(mapStateToProps, mapDispatchToProps)(Seasons);
