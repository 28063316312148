/**
 * @file seasonSlice.js
 * @description Sets initial state for the warehouse module.  Creates reducers and actions for API Calls
 * @author Ben Yee
 */
import { createSlice } from '@reduxjs/toolkit';
import { addStyleToSeason, deleteStyleFromSeason, getSeason, getSeasonStyles, getSeasons, udpateSeason, uploadCSVtoSeason } from '../../services/seasonService';
import { logger } from 'utils/logger';
import { addGuideToSession, addStyleToSession, udpateSession } from 'services/sessionService';
import { updateSample } from 'services/samplesService';
import { SampleFilterModel } from 'core/models/SampleFilterModel';
import { purgeFilters } from 'utils/helper';

//  const filterSeasons = (samples,filter) => {
//    return samples.filter(sample => {
//      var match = false;
//      // filter by brand
//      if(filter.brands){
//        match = filter.brands.indexOf(sample.facet_brand) > -1?true:false;
//      }else{
//        match = true;
//      }
//      logger.log(match, ' MATCH')
//      return match;
//    })
//  }

const seasonSlice = createSlice({
    name: 'season',
    initialState: {
        seasons: [],
        selectedSeason: null,
        selectedSeasons: [],
        selectedSamples: [],
        seasonFilter: { brands: [], categories: [], department: [], location: [], status: [], colour: '', keyword: '', sortBy: {}, tags: [], deadline: { dateStart: '', dateEnd: '' } },
        // seasonSampleFilter: { ... new SampleFilterModel() },
        seasonSampleFilters: {},
        // Meta
        error: null,
        success: null,
        loading: false,
        action: null,
    },
    reducers: {
        onServiceStart(state, action) {
            state.error = null;
            state.success = null;
        },

        onServiceFailure(state, action) {
            state.error = action.payload;
            state.success = null;
        },

        selectSeason(state, action) {
            logger.log('Setting Season: ', action.payload);
            state.selectedSeason = action.payload ? { ...action.payload } : null;
        },

        selectSeasons(state, action) {
            logger.log('setting selected samples', action.payload);
            state.selectedSeasons = action.payload ? [...action.payload] : [];
        },

        selectSamples(state, action) {
            logger.log('setting selected samples', action.payload);
            state.selectedSamples = action.payload ? [...action.payload] : [];
        },

        setSeasonFilter(state, action) {
            logger.log('Setting Season: ', action.payload);
            state.seasonFilter = action.payload ? { ...action.payload } : null;

            // filter the samples.
            // const samples = filterSeasons(state.samples, state.sampleFilter);
        },

        setSeasonSampleFilters(state, action) {
            logger.log(action);
            // state.seasonSampleFilters = action.payload ? { ...action.payload } : null;
            const seasonId = action.payload.seasonId ?? null;
            if (seasonId) {
                // state.sessionSampleFilters[sessionId] = action.payload ? { ...action.payload } : null;
                var filter =
                    state.seasonSampleFilters[action.payload.seasonId] && state.seasonSampleFilters[action.payload.seasonId].filter
                        ? state.seasonSampleFilters[action.payload.seasonId].filter
                        : { ...new SampleFilterModel() };
                state.seasonSampleFilters = {
                    ...state.seasonSampleFilters,
                    [action.payload.seasonId]: {
                        timestamp: Date.now(),
                        filter: {
                            ...filter,
                            ...action.payload.filter,
                        },
                    },
                };
            }
        },

        cleanupUnusedFilters(state, action) {
            const newFilters = purgeFilters(state.seasonSampleFilters);
            return {
                ...state,
                seasonSampleFilters: newFilters,
            };
        },

        //////////////////////
        //
        // SINGLE Season
        //
        //////////////////////
        getSeasonStart(state, action) {
            state.success = null;
            state.error = null;
        },
        getSeasonSuccess(state, action) {
            logger.log('getSeasonSuccess : SUCCESS');

            state.loading = false;
            state.success = { type: action.type, message: 'Get Event Success' };
            // if(action.payload.urls){
            //   state.selectedProject = {...state.selectedProject, urls:action.payload.urls}
            // }
            state.selectedSeason = { ...action.payload };
            //state.openIssuesCount = action.payload.open_issues_count
            state.error = null;
        },
        getSeasonFailure(state, action) {
            logger.log(state, action, 'fail');
            state.error = action.payload;
        },

        //////////////////////
        //
        // GET JOBS
        //
        //////////////////////
        getSeasonsStart(state, action) {
            state.success = null;
            state.error = null;
        },
        getSeasonsSuccess(state, action) {
            logger.log('getSeasonSuccess : SUCCESS');

            state.loading = false;
            state.success = { type: action.type, message: 'Get Events Success' };

            state.seasons = [...action.payload];
            //state.openIssuesCount = action.payload.open_issues_count
            state.error = null;
        },
        getSeasonsFailure(state, action) {
            logger.log(state, action, 'fail');
            state.error = action.payload;
        },

        //////////////////////
        //
        // SEASON STYLE
        //
        //////////////////////
        fetchSeasonStylesSuccess(state, action) {
            logger.log('getSeasonSuccess : SUCCESS');

            state.loading = false;
            state.success = { type: action.type, message: 'Get Event Styles Success' };

            state.selectedSeason.styles = [...action.payload];
            //state.openIssuesCount = action.payload.open_issues_count
            state.error = null;
        },

        removeStyleFromSeasonSuccess(state, action) {
            logger.log('getSeasonSuccess : SUCCESS');

            state.loading = false;
            state.success = { type: action.type, message: 'Removed Styles Success' };

            //state.openIssuesCount = action.payload.open_issues_count
            state.error = null;
        },

        //////////////////////
        //
        // SEASON
        //
        //////////////////////

        createSeasonSuccess(state, action) {
            logger.log('getSeasonSuccess : SUCCESS');

            state.loading = false;
            state.success = { type: action.type, message: 'Event successfully created' };

            //state.seasons = [...action.payload];
            //state.openIssuesCount = action.payload.open_issues_count
            state.error = null;
        },

        updateSeasonStart(state, action) {
            state.success = null;
            state.error = null;
        },
        updateSeasonSuccess(state, action) {
            logger.log('getSeasonSuccess : SUCCESS');

            state.loading = false;
            state.success = { type: action.type, message: 'Events successfully updated' };

            //state.seasons = [...action.payload];
            //state.openIssuesCount = action.payload.open_issues_count
            state.error = null;
        },
        updateSeasonFailure(state, action) {
            logger.log(state, action, 'fail');
            state.error = action.payload;
        },

        uploadCSVToSeasonSuccess(state, action) {
            logger.log(state, action, 'success');
            state.success = { type: action.type, message: 'Events CSV Successfully uploaded' };

            //state.seasons = [...action.payload];
            //state.openIssuesCount = action.payload.open_issues_count
            state.error = null;
        },

        updateSeasonStyleSuccess(state, action) {
            state.loading = false;
            state.success = { type: action.type, message: 'Style successfully updated' };

            state.error = null;
        },

        //////////////////////
        //
        // SESSIONS
        //
        //////////////////////
        createSessionSuccess(state, action) {
            logger.log(action.type.toString());

            // const sessions = [...state.sessions];
            // sessions.push(action.payload);

            // state.sessions = [sessions];
            // state.selectedSession = { ...action.payload };

            state.loading = false;
            state.success = { type: action.type, message: 'Create Session Success', data: action.payload };

            //state.sessions = [...action.payload];
            //state.openIssuesCount = action.payload.open_issues_count
            state.error = null;
        },

        assignStylesToSessionSuccess(state, action) {
            state.loading = false;
            state.success = { type: action.type, message: 'Styles successfully assigned', data: action.payload };

            state.error = null;
        },

        batchUpdateStyleSuccess(state, action) {

            var season = {...state.selectedSeason};
            var styles = [...state.selectedSeason.styles];
            logger.log(action.payload);
            const idMap = new Map(action.payload.map((item) => [item.stylenumber, item]));

            // update the talent
            const tmpStyles = styles.map((style) => {
                if (idMap.get(style.stylenumber)) {
                    return { ...style,...idMap.get(style.stylenumber) };
                } else {
                    return style;
                }
            });

            state.selectedSeason = {...season,styles:tmpStyles};


            state.loading = false;
            state.success = { type: action.type, message: 'Styles successfully updated', data: action.payload };

            state.error = null;
        },

        clearNotifications(state, action) {
            state.error = null;
            state.success = null;
        },
    },
});

// SAMPLE
export const fetchSeason = (id, user) => async (dispatch) => {
    try {
        logger.log(id, ' IN SLICE');
        dispatch(getSeasonStart());
        const data = await getSeason(id, user);
        dispatch(getSeasonSuccess(data));
    } catch (err) {
        logger.log('err', err);
        dispatch(getSeasonFailure(err));
    }
};

// GUIDES
export const fetchSeasons = (criteria, user) => async (dispatch) => {
    try {
        dispatch(getSeasonsStart());
        const data = await getSeasons(criteria, user ? user.rawIdToken : '');
        dispatch(getSeasonsSuccess(data));
    } catch (err) {
        logger.log('err', err);
        dispatch(getSeasonsFailure(err));
    }
};

export const createSeason = (season, user) => async (dispatch) => {
    try {
        dispatch(onServiceStart());
        const data = await udpateSeason(season, user ? user.rawIdToken : '');
        dispatch(createSeasonSuccess(data));
    } catch (err) {
        logger.log('err', err);
        dispatch(onServiceFailure(err));
    }
};

// GUIDE UPDATE
export const updateSeason = (season, user) => async (dispatch) => {
    try {
        dispatch(updateSeasonStart());

        const data = await udpateSeason(season, user ? user.rawIdToken : '');
        // const uploadurl = await getUploadUrl(data);
        // const uploadData = await uploadSeasonFile(season);

        dispatch(updateSeasonSuccess(data));
    } catch (err) {
        logger.log('err', err);
        dispatch(updateSeasonFailure(err));
    }
};

export const deleteSeason = (task, user) => async (dispatch) => {
    try {
        dispatch(updateSeasonStart());
        const data = await deleteSeason(task, user ? user.rawIdToken : '');
        dispatch(updateSeasonSuccess(data));
    } catch (err) {
        logger.log('err', err);
        dispatch(updateSeasonFailure(err));
    }
};

export const assignStylesToSeason = (season, style) => async (dispatch) => {
    try {
        dispatch(onServiceStart());
        const data = await addStyleToSeason(season, style);
        dispatch(assignStyleToSeasonSuccess(data));
    } catch (err) {
        logger.log('err', err);
        dispatch(updateSeasonFailure(err));
    }
};

export const removeStylesFromSeason = (season, styles) => async (dispatch) => {
    try {
        dispatch(onServiceStart());
        for (let i = 0; i < styles.length; i++) {
            const style = styles[i];
            const data = await deleteStyleFromSeason(season, style);
        }

        dispatch(removeStyleFromSeasonSuccess(styles));
    } catch (err) {
        logger.log('err', err);
        dispatch(updateSeasonFailure(err));
    }
};

export const fetchSeasonStyles = (season, style, signal) => async (dispatch) => {
    try {
        dispatch(onServiceStart());
        const data = await getSeasonStyles(season, style, signal);
        dispatch(fetchSeasonStylesSuccess(data));
    } catch (err) {
        logger.log('err', err);
        dispatch(updateSeasonFailure(err));
    }
};

export const createSession = (session, user) => async (dispatch) => {
    logger.log('create session from season');
    try {
        dispatch(onServiceStart());
        const data = await udpateSession(session, user ? user.rawIdToken : '');

        if (session.styles) {
            const styles = session.styles;
            var items = [];
            for (let i = 0; i < styles.length; i++) {
                const style = { ...styles[i] };
                const result = await addStyleToSession(style, data, user ? user.rawIdToken : '');
                items.push(result);
            }
        }

        if (session.guides) {
            const guides = session.guides;
            var guideItems = [];
            for (let i = 0; i < guides.length; i++) {
                const guide = { ...guides[i] };
                const result = await addGuideToSession(guide, data, user ? user.rawIdToken : '');
                guideItems.push(result);
            }
        }

        dispatch(createSessionSuccess(data));
    } catch (err) {
        logger.log('err', err);
        dispatch(updateSeasonFailure(err));
    }
};

export const assignStylesToSession = (session, styles) => async (dispatch) => {
    logger.log('create session from season');
    try {
        dispatch(onServiceStart());

        if (styles) {
            var items = [];
            for (let i = 0; i < styles.length; i++) {
                const style = { ...styles[i] };
                const result = await addStyleToSession(style, session);
                items.push(result);
            }
        }

        dispatch(assignStylesToSessionSuccess(session));
    } catch (err) {
        logger.log('err', err);
        dispatch(updateSeasonFailure(err));
    }
};

export const batchUpdateStyles =
    (styles, season, updateState = true) =>
    async (dispatch) => {
        try {
            dispatch(onServiceStart());
            var dataItems = [];
            // loop over all the task items
            // create a separate task for each item
            for (let i = 0; i < styles.length; i++) {
                const data = await updateSample(season, styles[i]);
                dataItems.push(styles[i]);
            }

            if (updateState) dispatch(batchUpdateStyleSuccess(dataItems));
        } catch (err) {
            logger.log('err', err);
            dispatch(updateSeasonFailure(err));
        }
    };

export const uploadCSVToSeason = (season, type, files) => async (dispatch) => {
    try {
        dispatch(onServiceStart());
        const data = await uploadCSVtoSeason(season, type, files[0]);
        dispatch(uploadCSVToSeasonSuccess(data));
    } catch (err) {
        logger.log('err', err);
        dispatch(updateSeasonFailure(err));
    }
};

export const updateSeasonStyle = (season, sample) => async (dispatch) => {
    try {
        dispatch(onServiceStart());
        const data = await updateSample(season, sample);
        dispatch(updateSeasonStyleSuccess(data));
    } catch (err) {
        logger.log('err', err);
        dispatch(updateSeasonFailure(err));
    }
};

export const {
    onServiceStart,
    onServiceFailure,
    selectSeason,
    selectSeasons,
    setSeasonFilter,
    setSeasonSampleFilters,
    cleanupUnusedFilters,

    getSeasonStart,
    getSeasonFailure,
    getSeasonSuccess,

    getSeasonsStart,
    getSeasonsFailure,
    getSeasonsSuccess,

    createSeasonSuccess,

    assignStyleToSeasonSuccess,

    removeStyleFromSeasonSuccess,

    fetchSeasonStylesSuccess,

    createSessionSuccess,

    assignStylesToSessionSuccess,
    batchUpdateStyleSuccess,
    uploadCSVToSeasonSuccess,

    updateSeasonStart,
    updateSeasonSuccess,
    updateSeasonFailure,

    // updateSeasonStyle,
    updateSeasonStyleSuccess,

    clearNotifications,
} = seasonSlice.actions;

export default seasonSlice.reducer;
