import { Anchor, Avatar, Badge, Box, Button, Center, Divider, Group, Image, SegmentedControl, Select, Stack, Table, Text, Title, useMantineTheme } from '@mantine/core';
import { useForm } from '@mantine/form';
import { FormInputWrapper } from 'core/components/';
import { selectTask } from 'modules/Sessions/sessionSlice';
import { forwardRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { AppConstants, DataFormats, Genders, status_backlog, statuss, ViewModes } from 'utils/constants';
import { pluralize } from 'utils/helper';
import { IconUser } from '@tabler/icons';
import { batchUpdateTalent } from 'modules/Talent/talentSlice';
import { TalentCategories, TalentStatuses } from 'core/models/TalentModel';
import moment from 'moment';
import { Link } from 'react-router-dom';
import { logger } from 'utils/logger';
import { batchUpdateStyles } from 'modules/Seasons/seasonSlice';

const PAGE_SIZE = 10;

const BatchSeasonStyleEdit = ({ season, styles, onCancel, mode = ViewModes.EDIT, user }) => {
    const dispatch = useDispatch();
    const [isSaving, setIsSaving] = useState(false);

    // const MAX_THUMB_COUNT = 11;
    const LABEL_WIDTH = 150;

    const form = useForm({
        initialValues: {
            multiShotFlag: '-1',
        },

        // validate:
        //     mode === ViewModes.ADD
        //         ? {
        //               deadline: (value) => (value && mode ? null : 'Invalid deadline'),
        //           }
        //         : {},
    });

    const onFormSubmit = (values) => {
        setIsSaving(true);
        const payload = styles.map((tmpStyle) => {
            const id = `${tmpStyle.seasonPart}__${tmpStyle.seasonSort}`;
            let item = {id:id,stylenumber:tmpStyle.stylenumber, styleCode:tmpStyle.stylenumber};
            if (values.multiShotFlag !== '-1') {
                item.multiShotFlag = values.multiShotFlag;
            }

            if (mode === ViewModes.EDIT) {
                // delete item.id;
                //delete item.instructions;               
            }

            return item;
        });

        dispatch(batchUpdateStyles(payload,season,true));
    };

    const rows = styles.map((element, idx) => {
        
        return (
            <tr key={idx}>
                <td>
                    <Group sx={{ flexShrink: 0 }}>
                       
                        <Box sx={{ flexShrink: 1 }}>
                            <Anchor size='sm'>
                                <Text size='sm' lineClamp={1} sx={{ maxWidth: '500px' }}>
                                    {element.stylenumber}
                                </Text>
                            </Anchor>
                            {/* <Text size='xs' color='dimmed'>
                                {element.id}
                            </Text> */}
                        </Box>
                    </Group>
                </td>
                <td>{element['instructions']['Name']}</td>
                <td>{element['instructions']['departmentStr']}</td>
                {/* <td>{element.photographyCount}</td> */}
                {/* <td>
                    {status ? (
                        <Group noWrap>
                            <Indicator color={status.colors.primary} sx={{ zIndex: 0 }}></Indicator>
                            <Text sx={{ textTransform: 'capitalize' }}>{status.label}</Text>
                        </Group>
                    ) : null}
                </td> */}
             
                {/* <td><Badge variant='gradient' gradient={{ from: '#ed6ea0', to: '#ec8c69', deg: 35 }}>{distSelections[0]}</Badge></td> */}
            </tr>
        );
    });
   
    return (
        <>
            <form onSubmit={form.onSubmit((values) => onFormSubmit(values))}>
                <FormInputWrapper label='Session' labelWidth={LABEL_WIDTH}>
                    <Box>
                        <Group pb={'xs'}>
                            <Anchor component={Link} className='dark-anchor' to={`/season/${season.code}`} size={'md'}>
                                {season.name}
                            </Anchor>
                            <Badge>{season.seasonSort}</Badge>
                        </Group>
                        <Group>
                            <Text size={'sm'} color={'dimmed'}>
                                Due : {moment(season.deadline).format(DataFormats.DATE_FORMAT)}
                            </Text>
                        </Group>
                    </Box>
                </FormInputWrapper>
                <Divider />
                {/* <FormInputWrapper label='Agency' id='input-agency'>
                    <Input disabled={!enabled} id='input-agency' placeholder='Enter Agency' variant={AppConstants.inputVariant} {...form.getInputProps('agency')} />
                </FormInputWrapper> */}

                {/* <FormInputWrapper label='Age' id='input-age'>
                    <Input sx={{ width: 200 }} disabled={!enabled} id='input-age' placeholder='Enter Age' variant={AppConstants.inputVariant} {...form.getInputProps('age')} />
                </FormInputWrapper> */}
                <FormInputWrapper label='Muti Shot' labelWidth={LABEL_WIDTH} description='Multi Shot includes both Talent and Flatlay shot'>
                    {/* <Checkbox {...form.getInputProps('multiShotFlag')}/> */}
                    <SegmentedControl
                        sx={{ width: '100%' }}
                        data={[
                            { label: 'Single Shot', value: '' },
                            { label: 'Multi Shot', value: 'Y' },
                        ]}
                        // placeholder='PSD Loaded'
                        {...form.getInputProps('multiShotFlag')}
                    />
                </FormInputWrapper>

                <Divider my={'lg'} />

                <Stack>
                    <Group>
                        <Title mb={0} align='left' order={5}>
                            You will be {mode === ViewModes.EDIT ? 'updating' : 'creating'} the following styles:
                        </Title>
                        <Group align={'center'} position='center'>
                            <Badge variant='gradient' gradient={{ from: '#ed6ea0', to: '#ec8c69', deg: 35 }} size='md'>
                                {styles.length} {pluralize('Style', styles.length)}
                            </Badge>
                        </Group>
                    </Group>
                    <Center>
                        <Table withBorder sx={{ maxWidth: '100%' }}>
                            <thead>
                                <tr>
                                    <th>Style</th>
                                    <th>Name</th>
                                    <th>Department</th>
                                    {/* <th>Captures</th> */}
                                    {/* <th>Session</th>
                                    <th>Style</th> */}
                                </tr>
                            </thead>
                            <tbody>{rows}</tbody>
                        </Table>
                    </Center>
                </Stack>

                {/* <Box my={'lg'}>
                    <Title order={5}>Uploads</Title>
                    {previews}
                </Box> */}

                <Group position='apart' mt='lg'>
                    <Button variant='default' onClick={onCancel}>
                        Cancel
                    </Button>
                    <Button type='submit' disabled={!form.isValid() || !form.isDirty()} loading={isSaving}>
                        {mode === ViewModes.ADD ? 'Create Style' : 'Update Styles'}
                    </Button>
                </Group>
                {/* </FocusTrap> */}
            </form>
        </>
    );
};

const mapStateToProps = (state, props) => {
    //logger.log(state,props, ' mapstate')
    return {
        user: state.auth.user,
        success: state.seasonReducer.success,
        error: state.seasonReducer.error,
    };
};

const mapDispatch = { selectTask };

export default connect(mapStateToProps, mapDispatch)(BatchSeasonStyleEdit);

// const SelectItem = forwardRef(({ label, color, ...others }, ref) => {
//     const isUnassigned = label.toLowerCase() === 'unassign' ? true : false;

//     return (
//         <div ref={ref} {...others}>
//             <Group noWrap>
//                 <Avatar variant='filled' title={`${label}`} color={isUnassigned ? 'gray' : color} size={30} radius={40}>
//                     {isUnassigned ? <IconUser style={{ opacity: 0.5 }} /> : label.charAt(0)}
//                 </Avatar>
//                 <Text sx={{ paddingLeft: 0 }}>{label}</Text>
//             </Group>
//         </div>
//     );
// });
