import ReactVirtualizedAutoSizer from 'react-virtualized-auto-sizer';
import { Badge, Button, createStyles, Grid, Group, Menu, Tabs, Text, Title } from '@mantine/core';
import { useEffect, useRef, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useNavigate, useParams, useSearchParams } from 'react-router-dom';

import { useForm } from '@mantine/form';
import { Crumbline, TabCounterLabel } from '../../../../core/components';

import { logger } from 'utils/logger';
import { getStyleStatusColor } from 'utils/helper';
import {
    batchUpdateStyleAssetSuccess,
    clearNotifications,
    fetchStyle,
    fetchStyleAssets,
    fetchStyleScans,
    fetchStyleTimeline,
    getStyleAssetsSuccess,
    getStyleSuccess,
    getStyleTimelineSuccess,
    setDisplayPrefs,
    updateStyle,
    updateStyleSuccess,
} from 'modules/Styles/styleSlice';
import { selectStyle } from '../../styleSlice';
import StyleTabGeneral from './StyleTabGeneral';
import StyleTabMedia from './StyleTabMedia';
import { StyleAssetFilterModel } from 'core/models/StyleAssetFilterModel';
import { SHELL_TAB_BAR_HEIGHT } from 'utils/constants';
import { ListViewOption } from 'core/components/listToggle/listToggleControl';
import { closeAllModals, openModal } from '@mantine/modals';
import StyleTabScans from './StyleTabScans';
import { ScanFilterModel } from 'core/models/ScanFilterModel';
import { IconChevronDown, IconRocket } from '@tabler/icons';
import { SampleEventType, SampleStatusTypes } from 'core/models/SampleModel';
import Distribution from '../distribution/Distribution';
import StyleTabOverview from './StyleTabOverview';
import { fetchGuides } from 'modules/Guides/guideSlice';

const useStyles = createStyles((theme) => ({
    tab: {
        // marginLeft:`-${theme.spacing.lg}px`,
        // marginRight:`-${theme.spacing.lg}px`
    },
    col: {
        padding: 0,
        flexGrow: 1,
        height: '100%',
        borderRight: `1px solid ${theme.colors.gray[2]}`,
        borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[2],
    },
    tabContent: {
        // padding: theme.spacing.xs,
        paddingTop: 0,
        height: '100%',
    },
}));

const Style = ({ style, guides, displayPrefs, aggData, departments, success, error }) => {
    const dispatch = useDispatch();
    const { classes } = useStyles();
    const params = useParams();
    const [activeTab, setActiveTab] = useState('overview');
    const [mediaFilter, setMediaFilter] = useState(new StyleAssetFilterModel());
    const [scanFilter, setScanFilter] = useState(new ScanFilterModel());
    const [searchParams, setSearchParams] = useSearchParams();
    const [listView, setListView] = useState(ListViewOption.LIST);
    const [guideDataSet, setGuideDataSet] = useState(false);
    const hasInit = useRef(false);
    const navigate = useNavigate();
    /*
  const onChange = (active, tabKey) => {
    setActiveTab(active);
    logger.log('tabKey', tabKey);
  };
  */

    // get the sample from the url
    useEffect(() => {
        if ((params && params.id && !style && !hasInit.current) || (style && params.id !== style.id)) {
            logger.log('-------------- ASSETS FECTED - START');

            hasInit.current = true;
            dispatch(clearNotifications());
            dispatch(fetchStyle(params.id));
            dispatch(fetchGuides({}));

            // setTimeout(() => {
            //   dispatch(fetchStyleTimeline(params.id));
            // },5000)
        }
    }, [params]);

    useEffect(() => {
        if (!success) return;

        logger.log(' SUCCESS ================', success.type);

        switch (success.type) {
            case getStyleSuccess.toString(): {
                // fetch the assets.
                if (!style) {
                    openModal({
                        title: 'Style not found',
                        children: (
                            <>
                                <Text size='sm'>This style doesn't exist make sure your style code is correct</Text>
                                <Group position='right' mt={'sm'}>
                                    <Button
                                        mt='md'
                                        onClick={() => {
                                            closeAllModals();
                                            navigate(-1);
                                        }}>
                                        Go Back
                                    </Button>
                                </Group>
                            </>
                        ),
                    });
                } else {
                    // dispatch(fetchStyleTimeline(params.id));
                }

                dispatch(fetchStyleAssets(params.id));
                break;
            }
            case updateStyleSuccess.toString(): {
                dispatch(fetchStyle(params.id));
                break;
            }
            case batchUpdateStyleAssetSuccess.toString(): {
                dispatch(fetchStyleAssets(params.id));
                break;
            }
            case getStyleAssetsSuccess.toString(): {
                // fetch the assets.
                setGuideDataSet(false);

                logger.log('-------------- ASSETS FECTED');
                dispatch(fetchStyleTimeline(params.id));
                // dispatch(clearNotifications())
                break;
            }
            case getStyleTimelineSuccess.toString(): {
                dispatch(fetchStyleScans(params.id));
                break;
            }
            default: {
                break;
            }
        }
    }, [success]);

    const form = useForm({
        initialValues: {
            styleNumber: '',
            photoCode: '',
            completed: '',
            sampleId: '',
            deptCode: '',
        },

        // validate: {
        //   email: (value) => (/^\S+@\S+$/.test(value) ? null : 'Invalid email'),
        // },
    });

    useEffect(() => {
        form.setValues({ ...style });

        logger.log(style);
        // fetch the style guides.
    }, [style]);

    useEffect(() => {
        var newData = [];
        if (style && style.assets && guides && guides.length && !guideDataSet) {
            // grab the guides and categories for the assets
            newData = style.assets.map((val) => {
                let style = { ...val };
                if (val.guideID && guides) {
                    style.guide = guides.find((guide) => guide.id === val.guideID);
                    if (style.guide && style.guide.guideCategories) {
                        style.guideCategory = style.guide.guideCategories.find((cat) => cat.id === style.catId);
                    }
                }

                return style;
            });
            setGuideDataSet(true);
            dispatch(selectStyle({ ...style, assets: newData }));
        }

        // filterSamples();
    }, [style, guides, dispatch]);

    const onTabChange = (val) => {
        var params = Object.fromEntries([...searchParams]);
        params.tab = val;
        setSearchParams(params);
    };

    /**
     * Effect to handle location change
     */
    useEffect(() => {
        // const params = new URLSearchParams(location.search);
        const currentParams = Object.fromEntries([...searchParams]);
        // let displayType = params.get('displayType');
        let displayType = currentParams.displayType;

        logger.log('location change ', currentParams, ' DISPLAY: ', displayType, params);
        // if display type changes
        if (displayType && displayType !== listView) {
            displayType = displayType === ListViewOption.LIST ? ListViewOption.LIST : displayType;
            // setListView(displayType);
            const prefs = { ...displayPrefs, mediaDisplayType: displayType };
            dispatch(setDisplayPrefs(prefs));
        }

        if (currentParams.tab) {
            setActiveTab(currentParams.tab);
        }
    }, [searchParams, listView]);

    // useEffect(() => {
    //     const currentParams = Object.fromEntries([...searchParams]);
    //     if (currentParams.tab) {
    //         setActiveTab(currentParams.tab);
    //     }
    // }, [searchParams, dispatch, setSearchParams]);

    // const containerStyle = { width:'1000px', marginTop:'20%', paddingLeft:'20%'}

    const onStyleAction = (event, data) => {
        switch (event.type) {
            case SampleEventType.SAMPLE_DISTRIBUTE: {
                openModal({
                    title: 'New Distribution (1)',
                    size: '80%',
                    children: (
                        <>
                            <Distribution selectedItems={[style]} aggData={aggData} />
                        </>
                    ),
                });
                break;
            }
            default: {
                break;
            }
        }
    };

    const crumbItems = [
        { title: 'Warehouse', route: '/warehouse', search: '' },
        {
            title: style?.status === SampleStatusTypes.SAMPLE_DISTRIBUTED ? 'Distributed Styles' : 'WIP Styles',
            route: '/warehouse/styles?',
            search: style?.status === SampleStatusTypes.SAMPLE_DISTRIBUTED ? 'status=D' : 'status=R',
        },
        { title: style?.styleNumber, route: `warehouse/styles/${style?.styleNumber}` },
    ];

    const onFormSubmit = (vals) => {
        logger.log('Submitting form');
        // we only allow updating of department

        if (vals.deptCode) {
            // find the string.
            const department = departments.find(val => val.code === vals.deptCode);

            const data = {
                id: style.id,
                stylePart: style.stylePart,
                styleSort: style.styleSort,
                dept: department?department.name:'', // String name
                deptCode: department?department.code:'', // code
            };

            logger.log(data);
            dispatch(updateStyle(data));
        }


        // dispatch(updateStyl(data, user));
    };

    return (
        <div
            style={{
                height: '100%',
                display: 'flex',
                flexDirection: 'column',
                overflow: 'hidden',
            }}>
            <Group
                className='main-container-header'
                sx={(theme) => ({
                    width: '100%',
                    top: '0 !important',
                    borderBottom: `1px solid ${theme.colors.gray[3]}`,
                    borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[2],
                    backgroundColor: theme.colorScheme === 'dark' ? theme.colors.dark[8] : theme.white,
                })}>
                <Group px='lg' position='apart' sx={{ width: '100%' }}>
                    <Crumbline items={crumbItems}></Crumbline>
                    {/* <Button>Create Sample</Button> */}
                </Group>
            </Group>
            <Group pt={'lg'} px={'lg'} position='apart'>
                <Group>
                    <Title order={3}>{style?.name}</Title>
                    {style?.status ? <Badge color={getStyleStatusColor(style?.status)}>{style?.status}</Badge> : null}
                </Group>
                {/* {style?.styleNumber} -  */}
                <Menu position='bottom-end'>
                    <Menu.Target>
                        <Button variant='outline' rightIcon={<IconChevronDown size={14} />}>
                            Actions
                        </Button>
                    </Menu.Target>

                    <Menu.Dropdown>
                        <Menu.Item onClick={() => onStyleAction({ type: SampleEventType.SAMPLE_DISTRIBUTE })} icon={<IconRocket size={14} />}>
                            Distribute
                        </Menu.Item>
                    </Menu.Dropdown>
                </Menu>
            </Group>

            <Grid mb='lg' style={{ margin: 0, height: 'auto', flexGrow: 1 }}>
                <Grid.Col span={12} className={`${classes.col} body-panels`} sx={{ height: '100%' }}>
                    <div style={{ height: '100%' }}>
                        <ReactVirtualizedAutoSizer disableWidth>
                            {({ height, width }) => {
                                return (
                                    <Tabs
                                        value={activeTab}
                                        sx={(theme) => ({
                                            borderRight: `1px solid ${theme.colors.gray[2]}`,
                                            '.mantine-Tabs-tabsList': {
                                                paddingLeft: theme.spacing.lg,
                                                paddingRight: theme.spacing.lg,
                                                paddingTop: 22,
                                            },
                                            borderColor: theme.colorScheme === 'dark' ? theme.colors.dark[6] : theme.colors.gray[2],
                                        })}
                                        onTabChange={(e) => onTabChange(e)}>
                                        <Tabs.List>
                                            <Tabs.Tab value='overview'>Overview</Tabs.Tab>
                                            <Tabs.Tab value='general'>General</Tabs.Tab>
                                            <Tabs.Tab value='media'>
                                                <TabCounterLabel label={'Assets'} data={style} accessor={'assets'} />
                                            </Tabs.Tab>

                                            <Tabs.Tab value='scans'>Scans</Tabs.Tab>
                                            {/* <Tabs.Tab value="activity">Activity</Tabs.Tab> */}
                                        </Tabs.List>

                                        <Tabs.Panel value='overview' className={classes.shellTabPanel}>
                                            <div
                                                className={`${classes.tabContent}`}
                                                style={{
                                                    height: height - SHELL_TAB_BAR_HEIGHT,
                                                    overflow: 'auto',
                                                }}>
                                                <StyleTabOverview form={form} sample={style} mediaFilter={mediaFilter} setMediaFilter={setMediaFilter} />
                                            </div>
                                        </Tabs.Panel>

                                        <Tabs.Panel value='general'>
                                            <div className={classes.tabContent}>
                                                <StyleTabGeneral form={form} sample={style} onFormSubmit={onFormSubmit} />
                                            </div>
                                        </Tabs.Panel>

                                        <Tabs.Panel value='media' className={classes.shellTabPanel}>
                                            <div
                                                className={`${classes.tabContent}`}
                                                style={{
                                                    height: height - SHELL_TAB_BAR_HEIGHT,
                                                }}>
                                                <StyleTabMedia
                                                    form={form}
                                                    // onMediaAction={onMediaAction}
                                                    mediaFilter={mediaFilter}
                                                    setMediaFilter={setMediaFilter}
                                                />
                                            </div>
                                        </Tabs.Panel>

                                        <Tabs.Panel value='scans' className={classes.shellTabPanel}>
                                            <div
                                                className={`${classes.tabContent}`}
                                                style={{
                                                    height: height - SHELL_TAB_BAR_HEIGHT,
                                                }}>
                                                <StyleTabScans
                                                    form={form}
                                                    // onMediaAction={onMediaAction}
                                                    scanFilter={scanFilter}
                                                    setMediaFilter={setScanFilter}
                                                />
                                            </div>
                                        </Tabs.Panel>
                                    </Tabs>
                                );
                            }}
                        </ReactVirtualizedAutoSizer>
                    </div>
                </Grid.Col>
            </Grid>
        </div>
    );
};

const mapStateToProps = (state, props) => {
    //logger.log(state,props, ' mapstate')
    return {
        user: state.auth.user,
        style: state.styleReducer.selectedStyle,
        guides: state.guideReducer.guides,
        success: state.styleReducer.success,
        displayPrefs: state.styleReducer.displayPrefs,
        departments: state.appReducer.departments,
        aggData: state.appReducer.aggData,
        error: state.styleReducer.error,
    };
};

const mapDispatch = { selectStyle };

export default connect(mapStateToProps, mapDispatch)(Style);
