/**
 * @file stylesSlice.js
 * @description Sets initial state for the warehouse module.  Creates reducers and actions for API Calls
 * @author Ben Yee
 */
import { createSlice } from '@reduxjs/toolkit';
import { ListViewOption } from 'core/components/listToggle/listToggleControl';
import { getScans } from 'services/scanService';
import { getStyle, getStyleAssets, getStyles } from 'services/styleService';
import { logger } from 'utils/logger';
import * as styleService from 'services/styleService';
import { StatusTypes } from 'utils/constants';
import { SampleStatusTypes } from 'core/models/SampleModel';
import { SampleFilterModel } from 'core/models/SampleFilterModel';

const styleSlice = createSlice({
  name: 'styleSlice',
  initialState: {
    styles: [],
    selectedStyle: null,
    selectedStyles: [],
    displayPrefs: { mediaDisplayType: ListViewOption.LIST },
    wipStyleFilter: { ...new SampleFilterModel()},
    distStyleFilter: { ...new SampleFilterModel()},
    // standard props
    error: null,
    success: null,
    loading: false,
    action: null,
  },
  reducers: {
    setDisplayPrefs(state, action) {
      state.displayPrefs = { ...action.payload };
    },
    selectStyle(state, action) {
      logger.log('Setting Style: ', action.payload);
      state.selectedStyle = action.payload ? { ...action.payload } : null;
    },
    selectStyles(state, action) {
      logger.log('setting selected styles', action.payload);
      state.selectedStyles = action.payload ? [...action.payload] : [];
    },
    setWipStyleFilter(state, action) {
      logger.log('Setting Style: ', action.payload);
      state.wipStyleFilter = action.payload ? { ...action.payload } : null;
    },
    setDistStyleFilter(state, action) {
      logger.log('Setting Style: ', action.payload);
      state.distStyleFilter = action.payload ? { ...action.payload } : null;
    },
    //////////////////////
    //
    // GET STYLES
    //
    //////////////////////
    getStylesStart(state, action) {
      state.success = null;
      state.error = null;
    },
    getStylesSuccess(state, action) {
      logger.log('getStyleSuccess : SUCCESS');

      state.loading = false;
      state.success = { type: action.type, message: 'Get Styles Success' };

      state.styles = [...action.payload];
      //state.openIssuesCount = action.payload.open_issues_count
      state.error = null;
    },
    getStylesFailure(state, action) {
      logger.log(state, action, 'fail');
      state.error = action.payload;
    },

    //////////////////////
    //
    // SINGLE STYLE
    //
    //////////////////////
    getStyleStart(state, action) {
      state.success = null;
      state.error = null;
    },
    getStyleSuccess(state, action) {
      logger.log('getStyleSuccess : SUCCESS');

      state.loading = false;
      state.success = { type: action.type, message: 'Get Style Success', data: action.payload };
      // if(action.payload.urls){
      //   state.selectedProject = {...state.selectedProject, urls:action.payload.urls}
      // }
      state.selectedStyle = action.payload ? { ...action.payload, scans: null } : null;
      //state.openIssuesCount = action.payload.open_issues_count
      state.error = null;
    },

    getStyleAssetsSuccess(state, action) {
      logger.log('getStyleSuccess : SUCCESS');

      state.loading = false;
      state.success = { type: action.type, message: 'Get Styles Assets Success' };

      state.selectedStyle = { ...state.selectedStyle, assets: action.payload };
      //state.openIssuesCount = action.payload.open_issues_count
      state.error = null;
    },

    getStyleFailure(state, action) {
      logger.log(state, action, 'fail');
      state.error = action.payload;
    },

    getStyleScansSuccess(state, action) {
      logger.log('getStyleScansSuccess : SUCCESS');

      state.loading = false;
      state.success = { type: action.type, message: 'Get Style Scans Success' };

      state.selectedStyle = { ...state.selectedStyle, scans: action.payload };
      //state.openIssuesCount = action.payload.open_issues_count
      state.error = null;
    },

    getStyleTimelineSuccess(state, action) {
      logger.log('getStyleSuccess : SUCCESS');

      state.loading = false;
      state.success = { type: action.type, message: 'Get Style Timeline Success' };

      if(state.selectedStyle){
        state.selectedStyle = {...state.selectedStyle,timeline:action.payload.Timeline};
      }      
      //state.openIssuesCount = action.payload.open_issues_count
      state.error = null;
    },
    updateStyleSuccess(state, action) {
      logger.log('updateStyleSuccess : SUCCESS');

      state.loading = false;
      state.success = { type: action.type, message: 'Update Style Success' };
      // state.selectedStyle = { ...action.payload };
      state.error = null;
    },
    updateStyleFailure(state, action) {
      logger.log(state, action, 'fail');
      state.error = action.payload;
    },
    updateStyleAssetSuccess(state, action) {
      logger.log('updateStyleSuccess : SUCCESS');

      state.loading = false;
      state.success = { type: action.type, message: 'Update Style Success' };
      // state.selectedStyle = { ...action.payload };
      state.error = null;
    },
    udpateStyleAssetFailure(state, action) {
      logger.log(state, action, 'fail');
      state.error = action.payload;
    },

    batchUpdateStyleAssetSuccess(state, action) {
      state.loading = false;
      state.success = { type: action.type, message: 'Updated Styles Successfully' };
      // state.selectedStyle = { ...action.payload };
      state.error = null;
    },

    distributeStylesSuccess(state, action) {
      logger.log('updateStyleSuccess : SUCCESS');

      state.loading = false;
      state.success = { type: action.type, message: 'Update Style Success' };

      // update the styles status for distributed items
      const tmpStyles = [...state.styles].map(item => {
        const match = action.payload.find(id => id === item.id);
        if(match){
          return {...item, status:SampleStatusTypes.SAMPLE_DISTRIBUTED}
        }else{
          return item;
        }
       
      })

      state.styles = [...tmpStyles];
      state.error = null;
    },
    distributeStylesFailure(state, action) {
      logger.log(state, action, 'fail');
      state.error = action.payload;
    },


    clearNotifications(state, action) {
      state.error = null;
      state.success = null;
    },

    onServiceStart(state, action) {
      state.success = null;
      state.loading = true;
      state.error = null;
      // state.action = action.type;
    },
    onServiceFailure(state, action) {
      logger.log(state, action, 'fail');
      state.error = action.payload;
      state.loading = false;
    },

  },
});

// STYLE
export const fetchStyle = (id, user) => async (dispatch) => {
  try {
    logger.log(id, ' IN SLICE');
    dispatch(getStyleStart());
    const data = await getStyle(id, user);
    logger.log(data);
    dispatch(getStyleSuccess(data));
  } catch (err) {
    logger.log('err', err);
    dispatch(getStyleFailure(err));
  }
};
export const fetchStyles = (id, user) => async (dispatch) => {
  try {
    logger.log(id, ' IN SLICE');
    dispatch(getStylesStart());
    const data = await getStyles(id, user);
    logger.log(data);
    dispatch(getStylesSuccess(data));
  } catch (err) {
    logger.log('err', err);
    dispatch(getStyleFailure(err));
  }
};
export const fetchStyleAssets = (id) => async (dispatch) => {
  try {
    logger.log(id, ' IN SLICE');
    dispatch(getStyleStart());
    const data = await getStyleAssets(id);
    dispatch(getStyleAssetsSuccess(data));
  } catch (err) {
    logger.log('err', err);
    dispatch(getStyleFailure(err));
  }
};

export const fetchStyleScans = (id) => async (dispatch) => {
  try {
    logger.log(id, ' IN SLICE');
    dispatch(getStyleStart());
    const data = await getScans({ styleCode: id });
    logger.log(data);
    dispatch(getStyleScansSuccess(data));
  } catch (err) {
    logger.log('err', err);
    dispatch(getStyleFailure(err));
  }
};

export const fetchStyleTimeline = (id) => async (dispatch) => {
  try {
    dispatch(getStyleStart());
    const data = await styleService.getStyleTimeline({ id });
    logger.log(data);
    dispatch(getStyleTimelineSuccess(data));
  } catch (err) {
    logger.log('err', err);
    dispatch(getStyleFailure(err));
  }
};


export const updateStyle = (style) => async (dispatch) => {
  try {
    dispatch(onServiceStart());
    const data = await styleService.updateStyle(style);
    dispatch(updateStyleSuccess(data));
  } catch (err) {
    logger.log('err', err);
    dispatch(updateStyleFailure(err));
  }
};

export const updateStyleAsset = (style, asset) => async (dispatch) => {
  try {
    dispatch(onServiceStart());
    const data = await styleService.updateStyleAsset(style, asset);
    dispatch(updateStyleAssetSuccess(data));
  } catch (err) {
    logger.log('err', err);
    dispatch(updateStyleAssetFailure(err));
  }
};

export const batchUpdateStyleAsset = (style, assets) => async (dispatch) => {
  try {
    dispatch(onServiceStart());
    for(var i = 0; i<assets.length;i++){
      await styleService.updateStyleAsset(style, assets[i]);
    }    
    dispatch(batchUpdateStyleAssetSuccess());
  } catch (err) {
    logger.log('err', err);
    dispatch(updateStyleAssetFailure(err));
  }
};


export const distributeStyles = (styles) => async (dispatch) => {
  try {
    dispatch(onServiceStart());
    const data = await styleService.distributeStyles(styles);
    dispatch(distributeStylesSuccess(styles));
  } catch (err) {
    logger.log('err', err);
    dispatch(distributeStylesFailure(err));
  }
};

// STYLES
export const fetchStylesByUnit = (criteria) => async (dispatch) => {
    try {
        dispatch(getStylesStart());
        const data = await styleService.getStylesByUnit(criteria);
        dispatch(getStylesSuccess(data));
    } catch (err) {
        logger.log('err', err);
        dispatch(getStylesFailure(err));
    }
};

export const {
  selectStyle,
  selectStyles,

  setWipStyleFilter,
  setDistStyleFilter,

  setDisplayPrefs,

  getStyleStart,
  getStyleFailure,
  getStyleSuccess,


  getStyleAssetsSuccess,

  getStyleScansSuccess,

  getStyleTimelineSuccess,

  getStylesStart,
  getStylesFailure,
  getStylesSuccess,

  onServiceStart,
  onServiceFailure,

  updateStyleSuccess,
  updateStyleFailure,

  updateStyleAssetSuccess,
  updateStyleAssetFailure,

  batchUpdateStyleAssetSuccess,

  distributeStylesSuccess,
  distributeStylesFailure,

  clearNotifications,
} = styleSlice.actions;

export default styleSlice.reducer;
