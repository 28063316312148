import { findIndex, forOwn } from 'lodash';
import moment from 'moment';
import { useEffect, useLayoutEffect, useRef, useState } from 'react';
import { useLocation } from 'react-router-dom';
import update from 'immutability-helper';
import { logger } from 'utils/logger';
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { AppConstants, CompanyFormatFields, CompanyFormats, DataFormats, ResponseCodes, colorThemes, status_unassigned, statuss } from './constants';
import { SampleStatusTypes } from 'core/models/SampleModel';
var _ = require('lodash');
/**
 * @file helper.js
 * Lib of helper components
 * @author Ben Yee
 */

export const useQuery = () => {
    return new URLSearchParams(useLocation().search);
};

export const getSecondaryColor = (theme) => {
    const secondaryColor = theme.colorScheme === 'dark' ? theme.colors.dark[1] : theme.colors.gray[7];

    return secondaryColor;
};

export const createFrame = () => {
    return {
        id: null,
        label: '',
        items: [],
        width: 100,
        height: 100,
    };
};

export const cacheToken = (token) => {
    localStorage.setItem('oi-hgio_token', encode(token));
};

export const clearToken = () => {
    logger.log('trying to clear token');
    localStorage.removeItem('oi-hgio_token');
};

export const cacheProp = (prop, value) => {
    localStorage.setItem(prop, encode(value));
};

export const clearProp = (prop) => {
    localStorage.removeItem(prop);
};

export const getProp = (prop) => {
    return decode(localStorage.getItem(prop));
};

function decode(k) {
    if (!k) {
        return '';
    }
    return atob(k.replace(/-/g, ''));
}

function encode(s) {
    return btoa('' + s).replace(/(.{5})/g, '$1-');
}

export const getToken = () => {
    return decode(localStorage.getItem('oi-hgio_token'));
};

export const getUserInitials = (first, last) => {
    return first && last ? `${first.charAt(0).toUpperCase()}${last.charAt(0).toUpperCase()}` : '';
};

export const getUserAvatarColour = (chars) => {
    if (chars.length < 2) {
        return 0;
    }

    const charCode1 = chars.charCodeAt(0) * 33;
    const charCode2 = chars.charCodeAt(1) * 7;

    const index = Math.floor((charCode1 + charCode2) % 12);

    return colorThemes[index + 1];
};

export const getUserFullname = (first, last) => {
    return `${first} ${last}`;
};

export const useEffectOnce = (effect) => {
    const destroyFunc = useRef();
    const effectCalled = useRef(false);
    const renderAfterCalled = useRef(false);
    const [val, setVal] = useState(0);

    if (effectCalled.current) {
        renderAfterCalled.current = true;
    }

    useEffect(() => {
        // only execute the effect first time around
        if (!effectCalled.current) {
            destroyFunc.current = effect();
            effectCalled.current = true;
        }

        // this forces one render after the effect is run
        setVal((val) => val + 1);

        return () => {
            // if the comp didn't render since the useEffect was called,
            // we know it's the dummy React cycle
            if (!renderAfterCalled.current) {
                return;
            }
            if (destroyFunc.current) {
                destroyFunc.current();
            }
        };
    }, []);
};

export const fetchImagePath = async (url) => {
    function readFileAsync(file) {
        return new Promise((resolve, reject) => {
            // let reader = new FileReader();

            // reader.onload = () => {
            //   resolve(reader.result);
            // };

            // reader.onerror = reject;

            // reader.readAsArrayBuffer(file);
            file.blob().then((result) => {
                resolve(URL.createObjectURL(result));
            });
            //const blob = res.blob()
            // const img = new Image()
            // // img.src = URL.createObjectURL(blob);
            // logger.log(img);
            // return img.src;
        });
    }

    let data = await fetch(url, {
        headers: {
            hgio: getToken(),
            // 'Content-Type': 'application/x-www-form-urlencoded'
        },
        method: 'GET',
        // body: formData,
    })
        .then((response) => {
            //return response.blob();

            return response;
            //return response.body;
        })
        .catch((err) => {
            logger.error('ERROR fetching presigned image', err);
            throw err;
        });

    return readFileAsync(data);

    // var reader = new FileReader();
    // reader.readAsDataURL(blob);
    // var data = await readFileAsync(blob);

    // logger.log(data);
    // return blob;
};

export const setObjectFromProps = (oldObject, newObject) => {
    var updatedObj = { ...oldObject };

    forOwn(newObject, function (value, key) {
        updatedObj[key] = value;
    });
    return updatedObj;
};

export const getCaptureCataglogues = () => {
    return [
        { label: 'Today', value: { since: moment().hour(0).minute(0).format('YYYY-MM-DD-HH-mm-ss-SSS'), until: moment().format('YYYY-MM-DD-HH-mm-ss-SSS') } },
        { label: 'This Week', value: { since: moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD-HH-mm-ss-SSS'), until: moment().format('YYYY-MM-DD-HH-mm-ss-SSS') } },
        { label: 'This Month', value: { since: moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD-HH-mm-ss-SSS'), until: moment().format('YYYY-MM-DD-HH-mm-ss-SSS') } },
        { label: 'This Year', value: { since: moment().subtract(1, 'years').startOf('year').format('YYYY-MM-DD-HH-mm-ss-SSS'), until: moment().format('YYYY-MM-DD-HH-mm-ss-SSS') } },
        {
            label: 'Last Week',
            value: { since: moment().subtract(2, 'weeks').startOf('week').format('YYYY-MM-DD-HH-mm-ss-SSS'), until: moment().subtract(1, 'weeks').startOf('week').format('YYYY-MM-DD-HH-mm-ss-SSS') },
        },
        {
            label: 'Last Month',
            value: {
                since: moment().subtract(2, 'months').startOf('month').format('YYYY-MM-DD-HH-mm-ss-SSS'),
                until: moment().subtract(1, 'months').startOf('month').format('YYYY-MM-DD-HH-mm-ss-SSS'),
            },
        },
        {
            label: 'Last Year',
            value: { since: moment().subtract(2, 'years').startOf('year').format('YYYY-MM-DD-HH-mm-ss-SSS'), until: moment().subtract(1, 'years').startOf('year').format('YYYY-MM-DD-HH-mm-ss-SSS') },
        },
    ];
};

export const updateCaptureItems = (items, updatedItems) => {
    var tmpCaptures = [...items];

    const updateCapture = (val) => {
        logger.log('val: ', val);
        const idx = findCaptureIndex(tmpCaptures, val);

        if (idx > -1) {
            var newCapture = setObjectFromProps(tmpCaptures[idx], val);
            tmpCaptures = update(tmpCaptures, { [idx]: { $set: newCapture } });
            // arrCaptures = [...newState];
        }
    };

    logger.log(updatedItems);
    updatedItems.forEach((val, idx) => {
        updateCapture(val);
    });

    return tmpCaptures;
};

export const findCaptureIndex = (arr, val) => {
    let idx = -1;
    if (val) {
        idx = findIndex(arr, function (obj) {
            return obj.actionPart === val.actionPart && obj.actionSort === val.actionSort;
        });
    }
    return idx;
};

export const handleZipDownload = async (items, filenameKey, zipName, downloadProperty, extension = 'png', onProgress) => {
    var zip = new JSZip();

    function roundUpToTwoDecimals(num) {
        return Math.ceil(num * 100) / 100;
    }

    for (var i = 0; i < items.length; i++) {
        const item = items[i];
        const counter = i;
        const xhr = new XMLHttpRequest();
        await new Promise((resolve, reject) => {
            xhr.addEventListener('progress', (event) => {
                if (event.lengthComputable) {
                    const progress = event.loaded / event.total;
                    if (onProgress) {
                        onProgress(roundUpToTwoDecimals(progress / items.length + (1 / items.length) * counter));
                    }
                }
            });

            xhr.addEventListener('load', () => {
                if (xhr.status === 200) {
                    let fnPart = item[filenameKey].split('.');
                    const fn = `${fnPart[0]}.${extension}`;
                    zip.file(fn, xhr.response);
                    resolve(xhr.response);
                } else {
                    reject(new Error(`Failed to fetch file from ${item[downloadProperty]}. Status: ${xhr.status}`));
                }
            });
            xhr.open('GET', item[downloadProperty], true);
            // xhr.setRequestHeader('Content-Type', 'application/octet-stream');
            xhr.responseType = 'blob';
            xhr.send();
        });
        // return success;
    }

    // wait for all download
    const content = await zip.generateAsync({ type: 'blob' });
    saveAs(content, `${zipName}_media.zip`);
};

export const downloadFile = async (url, filename, onProgress, onError, onComplete) => {
    logger.log(url, filename);

    // const arr = filename.split('.');
    // const extension = arr.length > 1 ? arr[1] : '';

    // let type = 'text/plain';
    let type = 'application/octet-stream';
    // switch (extension.toLowerCase()) {
    //     case 'eip':
    //     case 'psd': {
    //         type = 'application/octet-stream';
    //         break;
    //     }
    //     case 'jpeg':
    //     case 'jpg': {
    //         type = 'image/jpeg';
    //         break;
    //     }
    //     case 'png': {
    //         type = 'image/png';
    //         break;
    //     }
    //     case 'pdf': {
    //         type = 'application/pdf';
    //         break;
    //     }
    //     default: {
    //         type = 'text/plain';
    //         break;
    //     }
    // }

    const xhr = new XMLHttpRequest();
    xhr.open('GET', url, true);
    xhr.responseType = 'arraybuffer';
    xhr.onprogress = (ev) => {
        const percent = (ev.loaded / ev.total) * 100;
        if (onProgress) onProgress(percent);
    };
    xhr.onload = function () {
        if (xhr.status === 200) {
            const arrayBuffer = xhr.response;

            let blob = new Blob([arrayBuffer], {
                type: type,
            });

            let tmpUrl = URL.createObjectURL(blob);

            logger.log(tmpUrl);

            // Create an anchor element
            const link = document.createElement('a');

            // Set the file URL as the anchor's href
            link.href = tmpUrl;

            // Set the download attribute with the desired filename
            link.download = filename;

            // Append the anchor element to the document
            document.body.appendChild(link);

            // Click the anchor to trigger the download
            link.click();

            // Remove the anchor element from the document
            document.body.removeChild(link);

            if (onComplete) onComplete();
        } else {
            // Handle errors here.
            logger.error('Failed to download PDF:', xhr.status, xhr.statusText);
            if (onError) onError();
        }
    };

    xhr.onerror = function () {
        // Handle network errors here.
        logger.error('Network error while downloading PDF.');
        if (onError) onError();
    };

    xhr.send();
};

export const isPureObject = (input) => {
    return null !== input && typeof input === 'object' && Object.getPrototypeOf(input).isPrototypeOf(Object);
};

export const requestCheck = (response) => {
    if (response && response.status !== 200) {
        throw new Error('Something went wrong');
    }
};

export const pluralize = (term, count) => {
    return count === 1 ? term : `${term}s`;
};

export const getFilterVars = (key, filterItems) => {
    var res = [];
    if (filterItems[key]) Object.keys(filterItems[key]).forEach((val) => res.push(val));
    return res;
};

export const getFilterMatch = (arr, item, itemKey) => {
    var itemMatch = false;
    for (let i = 0; i < arr.length; i++) {
        let tmpItem = arr[i];
        itemMatch = item[itemKey].indexOf(tmpItem) > -1 ? true : false;
        if (itemMatch) {
            itemMatch = true;
            break;
        }
    }
    return itemMatch;
};

/**
 * Method to handle the selection of items in a group, used in the multicheckbox select views
 * @param {*} e the synthetic event
 * @param {*} item the current item
 * @param {*} selected whether the current item is selected or not
 * @param {*} page the filtered page items which is a subset of the data
 * @param {*} selectedItems state selected items
 * @param {*} items a copy of the selected
 */
export const selectItems = (e, item, selected, page, items, isCheckbox=true) => {
    if (selected) {
        if (e.nativeEvent.metaKey) {
            // if command held down we add the item to the array
            // if it's not yet selected.
            const obj = items.find((tmpItem) => tmpItem.id === item.id);
            // if the object is already selected then we unselect it
            if (obj) {
                //items  = [...selectedItems];
                items = _.remove([...items], (image) => {
                    return image.id !== item.id;
                });
            } else {
                items = [...items, { id: item.id }];
            }
        }
        // if they held the shift key select all the items from the last selected item
        else if (e.nativeEvent.shiftKey) {
            var index = _.findIndex(page, (pageItem) => item.id === pageItem.id);
            var lastItem = items[items.length - 1];
            var lastIdx = lastItem ? _.findIndex(page, (pageItem) => lastItem.id === pageItem.id) : 0;

            var startIdx = index < lastIdx ? lastIdx : index;
            var endIdx = index < lastIdx ? index : lastIdx;
            for (let i = startIdx; i >= endIdx; i--) {
                // if there's an index of the selected item then break;
                var selectedIdx = _.findIndex(items, (item) => item?.id === page[i]?.id);

                if (selectedIdx === -1) {
                    items.push({ id: page[i].id });
                }
            }
        } else {
            var selectedIdx = _.findIndex(items, (tmpItem) => tmpItem.id === item.id);

            if (selectedIdx === -1) {

                // if it's a checkbox type selection then we just push the item
                // otherwise we treat it like a radio.
                if(isCheckbox){
                    items.push({ id: item.id });
                }else{
                    items.length = 0;
                    items.push({ id: item.id });
                }
                
            }
        }
    } else {
        var tmpItem = items.find((val) => val.id === item.id);
        index = items.indexOf(tmpItem);
        if (index > -1) {
            items.splice(index, 1);
        }
    }

    return items
};

export function parseValue(item) {
    if (!item) return;

    const keys = Object.keys(item);
    var newItem = {};

    keys.forEach((key, idx) => {
        var val = item[key];
        const valueKey = Object.keys(val)[0];
        var newVal = val[valueKey];

        switch (valueKey) {
            // String type
            case 'S': {
                break;
            }
            // Number
            case 'N': {
                break;
            }
            // Object Type
            case 'M': {
                newVal = parseValue(val);
                break;
            }
            // Array type
            case 'L': {
                let items = val['L'];
                newVal = items.map((tmp) => {
                    if (tmp['M']) {
                        return parseValue(tmp['M']);
                    } else if (tmp['S']) {
                        return tmp['S'];
                    }
                });
                break;
            }
            default: {
                break;
            }
        }
        newItem[key] = newVal;
    });

    return newItem;
}

export function filterMedia(mediaItems, mediaFilter, tasks = []) {
    // grab all the media items for the given mediaFilter status.
    var items = [];
    var excludeList = [];
    var findDiff = false;
    if (mediaFilter.status && mediaFilter.status.taskStatus && mediaFilter.status.taskStatus.length) {
        mediaFilter.status.taskStatus.forEach((status) => {
            tasks.forEach((task) => {
                if (task.status === status) {
                    let captures = task.items.map((item) => item.actionSort);
                    if (captures) items.push(...captures);
                } else if (status === status_unassigned.value) {
                    let captures = task.items.map((item) => item.actionSort);
                    if (captures) excludeList.push(...captures);
                }
            });
            if (status === status_unassigned.value) findDiff = true;
        });

        // if we are including unassigned we add in all media items and remove the ones that are assigned.
        if (findDiff) {
            const allMedia = mediaItems.map((item) => item.actionSort);
            const diff = _.difference(allMedia, excludeList);
            items = [...items, ...diff];
        }
    }

    let newData = mediaItems.filter((capture) => {
        var match = true;

        if (mediaFilter.sak.toString() && mediaFilter.sak.toString() !== '-1') {
            match = capture.sak.toString() === mediaFilter.sak.toString() ? true : false;
        }

        if (mediaFilter.star && mediaFilter.star > -1 && match) {
            match = parseInt(capture.star) === mediaFilter.star ? true : false;
        }

        if (mediaFilter.styles && mediaFilter.styles.length && match) {
            // loop over the tag and see if any match.
            var tagMatch = false;

            if (!capture.styles || (capture.styles && capture.styles.length === 0)) {
                tagMatch = false;
            } else {
                for (let i = 0; i < mediaFilter.styles.length; i++) {
                    let tag = mediaFilter.styles[i];

                    if (capture.styles.indexOf(tag) > -1) {
                        tagMatch = true;
                        break;
                    } else {
                        tagMatch = false;
                    }
                }
            }

            match = tagMatch;
        }

        if (mediaFilter.tags && mediaFilter.tags.length && match) {
            if (!capture.tags || (capture.tags && capture.tags.length === 0)) {
                tagMatch = false;
            } else {
                // loop over the tag and see if any match.
                tagMatch = true;
                for (let i = 0; i < mediaFilter.tags.length; i++) {
                    let tag = mediaFilter.tags[i].toLowerCase();
                    tagMatch = capture.tags.toLowerCase().indexOf(tag) > -1 ? true : false;
                    if (tagMatch) {
                        break;
                    }
                }
            }

            match = tagMatch;
        }

        if (mediaFilter.status && mediaFilter.status.taskStatus && mediaFilter.status.taskStatus.length && match) {
            // find all tasks with the given status and check if the media has been assigned to those tasks.

            var statusMatch = false;

            if (items.indexOf(capture.actionSort) > -1) {
                statusMatch = true;
            }

            match = statusMatch;
        }

        if (mediaFilter.psdLoaded.toString() && mediaFilter.psdLoaded.length && match) {
            var psdLoadedMatch = false;
            if (mediaFilter.psdLoaded.toString() === '1' && capture.psdLoaded && capture.psdLoaded === 'Y') {
                psdLoadedMatch = true;
            } else if (mediaFilter.psdLoaded.toString() === '0' && !capture.psdLoaded) {
                psdLoadedMatch = true;
            }
            match = psdLoadedMatch;
        }

        if (mediaFilter.keyword && mediaFilter.keyword.length && match) {
            let tagMatch = true;
            let fnMatch = true;
            let pcMatch = true;

            if (capture.tags) {
                tagMatch = capture.tags.toLowerCase().indexOf(mediaFilter.keyword.toLowerCase()) > -1 ? true : false;
            }

            fnMatch = capture.originalFilename.toLowerCase().indexOf(mediaFilter.keyword.toLowerCase()) > -1 ? true : false;

            if (capture.photoCode) {
                pcMatch = capture.photoCode.toLowerCase().indexOf(mediaFilter.keyword.toLowerCase()) > -1 ? true : false;
            }

            match = tagMatch || fnMatch || pcMatch;
        }

        return match;
    });

    if (mediaFilter.groupBy) {
        var uniq = [...new Set(Object.keys(newData).map((e) => newData[e][mediaFilter.groupBy]))];
        // create the group by items
        var groupedData = uniq.map((group) => {
            var subRows = newData.filter((val) => val[mediaFilter.groupBy] === group);
            return { type: 'group', name: group, subRows, id: group };
        });
        newData = groupedData;
    }

    return newData;
}

export const getMediaFilterCount = (val) => {
    var count = 0;

    if (val.sak > -1) ++count;
    if (val.star > -1) ++count;
    if (val.catalogue.label.length) ++count;
    if (val.tags && val.tags.length) ++count;
    if (val.styles && val.styles.length) ++count;
    if (val.status && val.status.taskStatus && val.status.taskStatus.length) ++count;
    if (val.psdLoaded.length) ++count;

    return count;
};

export function filterStyleAssets(mediaItems, mediaFilter) {
    const newData = mediaItems.filter((capture) => {
        var match = true;

        if (mediaFilter.completed && mediaFilter.completed.length === 2 && mediaFilter.completed[0]) {
            const itemDate = moment(capture.completed);
            const startDate = mediaFilter.completed[0];
            const endDate = mediaFilter.completed[1];
            match = itemDate.isBetween(startDate, endDate) ? true : false;
        }

        if (mediaFilter.guides && mediaFilter.guides.length && match) {
            // loop over the tag and see if any match.
            var guideMatch = false;
            for (let i = 0; i < mediaFilter.guides.length; i++) {
                let tag = mediaFilter.guides[i];
                guideMatch = capture.guide?.id === tag ? true : false;
                if (guideMatch) {
                    break;
                }
            }
            match = guideMatch;
        }
        if (mediaFilter.guideCategories && mediaFilter.guideCategories.length && match) {
            // loop over the tag and see if any match.
            guideMatch = false;
            for (let i = 0; i < mediaFilter.guideCategories.length; i++) {
                let tag = mediaFilter.guideCategories[i];
                let arrGuideInfo = tag.split('|');
                let guideId = arrGuideInfo[0];
                let catId = arrGuideInfo[1];

                guideMatch = capture.guide?.id === guideId && capture.guideCategory?.id === catId ? true : false;
                if (guideMatch) {
                    break;
                }
            }
            match = guideMatch;
        }

        if (mediaFilter.tags && mediaFilter.tags.length && match) {
            // loop over the tag and see if any match.
            guideMatch = true;
            for (let i = 0; i < mediaFilter.tags.length; i++) {
                let tag = mediaFilter.tags[i].toLowerCase();
                guideMatch = capture.tags.toLowerCase().indexOf(tag) > -1 ? true : false;
                if (!guideMatch) {
                    guideMatch = false;
                    break;
                }
            }
            match = guideMatch;
        }

        if (mediaFilter.viewstatus && mediaFilter.viewstatus.length && match) {
            logger.log(mediaFilter.viewstatus, capture.viewstatus);
            match = mediaFilter.viewstatus.indexOf(capture.viewstatus) > -1 ? true : false;
        }

        if (mediaFilter.keyword && mediaFilter.keyword.length && match) {
            match = capture.tags.toLowerCase().indexOf(mediaFilter.keyword.toLowerCase()) > -1 ? true : false;

            if (!match) {
                match = capture.originalFileName.toLowerCase().indexOf(mediaFilter.keyword.toLowerCase()) > -1 ? true : false;
            }
        }

        return match;
    });
    return newData;
}

export const getStyleAssetFilterCount = (val) => {
    var count = 0;

    if (val.guideCategories && val.guideCategories.length) ++count;
    if (val.guides && val.guides.length) ++count;
    // if (val.catalogue.label.length) ++count;
    if (val.tags && val.tags.length) ++count;
    if (val.completed && val.completed[0]) ++count;

    return count;
};

export const getTalentAssetFilterCount = (val) => {
    var count = 0;

    // if (val.guideCategories && val.guideCategories.length) ++count;
    if (val.sessionId && val.sessionId.length) ++count;
    // if (val.catalogue.label.length) ++count;
    if (val.star && val.star > -1) ++count;
    if (val.sak && val.sak > -1) ++count;
    if (val.tags && val.tags.length) ++count;
    if (val.completed && val.completed[0]) ++count;

    return count;
};

export const getScanFilterCount = (val) => {
    var count = 0;
    if (val.department && val.department.length) ++count;
    if (val.colour && val.colour.length) ++count;
    if (val.size && val.size.length) ++count;
    if (val.seasons && val.seasons.length) ++count;
    // if (val.catalogue.label.length) ++count;
    if (val.tags && val.tags.length) ++count;
    if (val.completed && val.completed[0]) ++count;
    if (val.statuses && val.statuses.length) ++count;
    if (val.location && val.location.length) ++count;
    if (val.scanDateStart) ++count;
    return count;
};

export function parseObjectTypes(inputObject) {
    function parseValue(value) {
        if (typeof value === 'object' && value !== null) {
            if ('N' in value) {
                return parseInt(value.N);
            } else if ('S' in value) {
                return value.S;
            } else if ('L' in value) {
                return value.L.map(parseValue);
            } else if ('M' in value) {
                const parsedObject = {};
                for (const key in value.M) {
                    parsedObject[key] = parseValue(value.M[key]);
                }
                return parsedObject;
            }
        }
        return value;
    }

    function parseObject(obj) {
        const parsedObj = {};
        for (const key in obj) {
            parsedObj[key] = parseValue(obj[key]);
        }
        return parsedObj;
    }

    const parsedObject = { ...inputObject };

    for (const key in inputObject) {
        if (key === 'Items') {
            parsedObject[key] = inputObject[key].map((item) => parseObject(item));
        } else {
            parsedObject[key] = parseValue(inputObject[key]);
        }
    }

    return parsedObject;
}

export function bytesToMB(bytes) {
    if (bytes === 0) return '0 MB';

    const mb = bytes / (1024 * 1024);
    return `${mb.toFixed(2)} MB`;
}

export function getOfn(str) {
    var ofn = '';
    const arr = str.split('/');
    if (arr && arr.length) {
        var ofnArr = arr[arr.length - 1].split('?');
        ofn = ofnArr && ofnArr.length ? ofnArr[0] : '';
    }
    return ofn;
}

export const getColumnColor = (title, key = 'name') => {
    const status = statuss.find((val) => val[key] === title);
    const col = status.colors.primary === 'default' ? 'white' : status.colors.primary;
    return col;
};

export const getScanStatusColor = (status) => {
    var col = 'green';
    switch (status.toLowerCase()) {
        case 'failure': {
            col = 'red';
            break;
        }
        case 'pending': {
            col = 'yellow';
            break;
        }
        case 'a2 record not found': {
            col = 'pink';
            break;
        }
        case 'master record not found': {
            col = 'pink';
            break;
        }
        case 'parsed': {
            col = 'indigo';
            break;
        }
        case 'abondoned': {
            col = 'gray';
            break;
        }
        default: {
            break;
        }
    }

    return col;
};

export const getStyleStatusColor = (status) => {
    var col = 'green';

    switch (status.toUpperCase()) {
        case SampleStatusTypes.SAMPLE_ACTIVE: {
            col = 'green';
            break;
        }
        case SampleStatusTypes.SAMPLE_READY: {
            col = 'violet';
            break;
        }
        case SampleStatusTypes.SAMPLE_DISTRIBUTED: {
            col = 'blue';
            break;
        }
        case SampleStatusTypes.SAMPLE_ARCHIVED: {
            col = 'gray';
            break;
        }
        default: {
            break;
        }
    }

    return col;
};

export const getImageFile = async (url) => {
    return fetch(url, {
        headers: {
            hgio: getToken(),
        },
        method: 'GET',
    })
        .then((response) => {
            return response;
        })
        .catch((err) => {
            console.error('ERROR fetching image', err);
            throw err;
        });
};

export const getGuideInfo = (guideID, catId, guides) => {
    var category;
    const guide = guides.find((guide) => guide.id === guideID);
    if (guide && guide.guideCategories) {
        category = guide.guideCategories.find((cat) => cat.id === catId);
    }
    return { guide: guide, category: category };
};

export const filterSamples = (samples, sampleFilter) => {
    var data = samples?.filter((sample) => {
        var match = true;
        // filter by brand
        if (sampleFilter.brands && sampleFilter.brands.length) {
            match = sampleFilter.brands.indexOf(sample.facet_brand) > -1 ? true : false;
        }

        if (sampleFilter.categories && sampleFilter.categories.length && match) {
            match = sampleFilter.categories.indexOf(sample.metadata_toplevelcategory) > -1 ? true : false;
        }

        if (sampleFilter.departments && sampleFilter.departments.length && match) {
            match = sampleFilter.departments.indexOf(sample.departmentStr) > -1 ? true : false;
        }

        if (sampleFilter.depts && sampleFilter.depts.length && match) {
            match = sampleFilter.depts.indexOf(sample.dept) > -1 ? true : false;
        }

        if (sampleFilter.statuses && sampleFilter.statuses.length && match) {
            match = sampleFilter.statuses.indexOf(sample.status) > -1 ? true : false;
        }

        if (sampleFilter.seasons && sampleFilter.seasons.length && match) {
            match = sampleFilter.seasons.indexOf(sample.seasonCode) > -1 ? true : false;
        }

        if (sampleFilter.stylesCodes && sampleFilter.stylesCodes.length && match) {
            match = sampleFilter.stylesCodes.indexOf(sample.styleNumber) > -1 ? true : false;
        }

        if (sampleFilter.sessions && sampleFilter.sessions.length && match) {
            match = sampleFilter.sessions.indexOf(sample.sessionCode) > -1 ? true : false;
        }

        if (sampleFilter.issuers && sampleFilter.issuers.length && match) {
            match = sampleFilter.issuers.indexOf(sample.issuer) > -1 ? true : false;
        }

        if (sampleFilter.talents && sampleFilter.talents.length && match) {
            logger.log(sample);
            match = sampleFilter.talents.indexOf(sample.talentNames) > -1 ? true : false;
        }

        if (sampleFilter.liveData && sampleFilter.liveData.length && match) {
            if (parseInt(sampleFilter.liveData) === 1) {
                match = sample.liveData?.files?.length ? true : false;
            } else if (parseInt(sampleFilter.liveData) === 0) {
                match = sample.liveData?.files?.length === 0 ? true : false;
            }
        }

        // OTB Date filter dates
        const otbDate = new Date(sample.otbDate);

        if (sampleFilter.otbDate && sampleFilter.otbDate.dateStart && sampleFilter.otbDate.dateEnd && match) {
            match = otbDate >= sampleFilter.otbDate.dateStart && otbDate <= sampleFilter.otbDate.dateEnd ? true : false;
        } else if (sampleFilter.otbDate && sampleFilter.otbDate.dateStart && sampleFilter.otbDate.dateEnd === '' && match) {
            match = otbDate >= sampleFilter.otbDate.dateStart ? true : false;
        } else if (sampleFilter.otbDate && sampleFilter.otbDate.dateEnd && sampleFilter.otbDate.dateStart === '' && match) {
            match = otbDate <= sampleFilter.otbDate.dateEnd ? true : false;
        }

        // Completed Date filter dates
        const tmpCompleteDate = moment(sample.completed, 'YYYY-MM-DD-HH-mm-ss-SSS');
        const completedDate = tmpCompleteDate.toDate();

        if (sampleFilter.completed && sampleFilter.completed.dateStart && sampleFilter.completed.dateEnd && match) {
            match = completedDate >= sampleFilter.completed.dateStart && completedDate <= sampleFilter.completed.dateEnd ? true : false;
        } else if (sampleFilter.completed && sampleFilter.completed.dateStart && sampleFilter.completed.dateEnd === '' && match) {
            match = completedDate >= sampleFilter.completed.dateStart ? true : false;
        } else if (sampleFilter.completed && sampleFilter.completed.dateEnd && sampleFilter.completed.dateStart === '' && match) {
            match = completedDate <= sampleFilter.completed.dateEnd ? true : false;
        }

        // Distribution Date filter dates

        // const distDate = new Date(sample.distributionDate.replace(/(\d{4})-(\d{2})-(\d{2})(.*)/, '$1-$2-$3T$4Z'));
        const tmpDistDate = moment(sample.distributionDate, 'YYYY-MM-DD-HH-mm-ss-SSS');
        const distDate = tmpDistDate.toDate();

        if (sampleFilter.distribution && sampleFilter.distribution.dateStart && sampleFilter.distribution.dateEnd && match) {
            match = distDate >= sampleFilter.distribution.dateStart && distDate <= sampleFilter.distribution.dateEnd ? true : false;
        } else if (sampleFilter.distribution && sampleFilter.distribution.dateStart && sampleFilter.distribution.dateEnd === '' && match) {
            match = distDate >= sampleFilter.distribution.dateStart ? true : false;
        } else if (sampleFilter.distribution && sampleFilter.distribution.dateEnd && sampleFilter.distribution.dateStart === '' && match) {
            match = distDate <= sampleFilter.distribution.dateEnd ? true : false;
        }

        // for the status
        const statusKeys = Object.keys(sampleFilter.status);

        for (let i = 0; i < statusKeys.length; i++) {
            const key = statusKeys[i];
            if (sampleFilter.status[key] && sampleFilter.status[key].length && match) {
                if (parseInt(sampleFilter.status[key]) === 0 && sample[key]) {
                    match = parseInt(sample[key]) === 0 ? true : false;
                } else if (parseInt(sampleFilter.status[key]) > 0) {
                    match = parseInt(sample[key]) > 0 ? true : false;
                }
            }
            if (!match) {
                break;
            }
        }
        // if (sampleFilter.status.sampleCount && sampleFilter.status.sampleCount.length && match) {
        //     if(sampleFilter.status.sampleCount === 0){
        //         match = sample.sampleCount === 0?true:false;
        //     }else{
        //         match = sample.sampleCount > 0?true:false;
        //     }

        //     // match = parseInt(sampleFilter.status.sampleCount) sample.sampleCount && sample.sampleCount > 0 ? true : false;
        // }

        // if (sampleFilter.status.photographyCount && match) {
        //     match = sample.photographyCount && sample.photographyCount > 0 ? true : false;
        // }

        // if (sampleFilter.status.taskCount && match) {
        //     match = sample.taskCount && sample.taskCount > 0 ? true : false;
        // }

        // if (sampleFilter.status.taskCompleteCount && match) {
        //     match = sample.taskCompleteCount && sample.taskCompleteCount > 0 ? true : false;
        // }

        if (sample && sampleFilter.keyword && sampleFilter.keyword.length && match) {
            // we kick off the first check
            if (sample.ssPart) {
                match = sample.ssPart.toLowerCase().indexOf(sampleFilter.keyword.toLowerCase()) > -1 ? true : false;
            } else {
                match = false;
            }
            if (!match && sample.styleNumber) {
                match = sample.styleNumber.toLowerCase().indexOf(sampleFilter.keyword.toLowerCase()) > -1 ? true : false;
            }
            if (!match && sample.seasonCode) {
                match = sample.seasonCode.toLowerCase().indexOf(sampleFilter.keyword.toLowerCase()) > -1 ? true : false;
            }
            if (!match && sample.sessionCode) {
                match = sample.sessionCode.toLowerCase().indexOf(sampleFilter.keyword.toLowerCase()) > -1 ? true : false;
            }

            if (!match && sample.ssSort) {
                match = sample.ssSort.toLowerCase().indexOf(sampleFilter.keyword.toLowerCase()) > -1 ? true : false;
            }

            if (!match && sample.name) {
                match = sample.name.toLowerCase().indexOf(sampleFilter.keyword.toLowerCase()) > -1 ? true : false;
            }

            if (!match && sample.desc) {
                match = sample.desc.toLowerCase().indexOf(sampleFilter.keyword.toLowerCase()) > -1 ? true : false;
            }

            if (!match && sample.photoCode) {
                match = sample.photoCode.toLowerCase().indexOf(sampleFilter.keyword.toLowerCase()) > -1 ? true : false;
            }
        }

        return match;
    });

    if (sampleFilter.groupBy) {
        var uniq = [...new Set(Object.keys(data).map((e) => data[e][sampleFilter.groupBy]))];
        // create the group by items
        var groupedData = uniq.map((group) => {
            var subRows = data.filter((val) => val[sampleFilter.groupBy] === group);
            return { type: 'group', name: group, subRows, id: group };
        });
        data = groupedData;
    }

    return data;
};

export const sampleAlphanumericFilter = (row1, row2, columnName, isTableSort = true) => {
    var rowOneColumn = isTableSort ? row1.values[columnName] : row1[columnName];
    var rowTwoColumn = isTableSort ? row2.values[columnName] : row2[columnName];

    if (columnName.toLowerCase().indexOf('instructions') > -1) {
        let regex = /\["([^"]+)"\]/;
        let match = columnName.match(regex);

        if (match) {
            let key = match[1];
            try {
                rowOneColumn = isTableSort ? row1.original.instructions[key] : row1.instructions[key];
                rowTwoColumn = isTableSort ? row2.original.instructions[key] : row2.instructions[key];
            } catch (e) {
                rowOneColumn = '';
                rowTwoColumn = '';
            }
        } else {
            console.log('No match found.');
        }
    }

    if (rowOneColumn && isNaN(rowOneColumn) && rowTwoColumn) {
        return rowOneColumn.toString().toLowerCase() > rowTwoColumn?.toString().toLowerCase() ? 1 : -1;
    }
    return Number(rowOneColumn) > Number(rowTwoColumn) ? 1 : -1;
};

export const dateSortFilter = (row1, row2, columnName, isTableSort = true) => {
    var rowOneColumn = isTableSort ? row1.values[columnName] : row1[columnName];
    var rowTwoColumn = isTableSort ? row2.values[columnName] : row2[columnName];

    const val1 = moment(rowOneColumn, 'YYYY-MM-DD-hh-mm-ss').format();
    const val2 = moment(rowTwoColumn, 'YYYY-MM-DD-hh-mm-ss').format();

    return new Date(val2) - new Date(val1);
};

export const headerDateSort = (a, b, key) => {
    var val1 = 0;
    var val2 = 0;

    if (a.values[key] && a.values[key].length) {
        val1 = moment(a.values[key], DataFormats.DATE_FORMAT_CREATE_PARSE).format();
    }
    if (b.values[key] && b.values[key].length) {
        val2 = moment(b.values[key], DataFormats.DATE_FORMAT_CREATE_PARSE).format();
    }

    return new Date(val1) - new Date(val2);
};

export const getStatusColor = (status) => {
    if (!status) return;
    var color = 'red';
    switch (status.toUpperCase()) {
        case 'ACTIVE':
        case 'SUCCESS': {
            color = 'teal';
            break;
        }
        case 'PENDING': {
            color = 'orange';
            break;
        }
        case 'ARCHIVE': {
            color = 'gray';
            break;
        }
        case 'INACTIVE': {
            color = 'gray';
            break;
        }
        default: {
            break;
        }
    }

    return color;
};

export const createMembers = (aggData, allowUnassign = false) => {
    var newMembers = [];

    if (aggData && aggData.productionTeam) {
        const productionTeam = aggData.productionTeam.map((val, idx) => {
            // const themeCol = colorThemes[idx + 2];
            const userInitials = getUserInitials(val.name.split(' ')[0], val.name.split(' ')[1]);
            logger.log(userInitials);
            const themeCol = getUserAvatarColour(userInitials);
            return { name: val.name, color: themeCol, team: 'Production', email: val.email };
        });
        const postTeam = aggData.postProductionTeam.map((val, idx) => {
            // const themeCol = colorThemes[idx + 2];
            const userInitials = getUserInitials(val.name.split(' ')[0], val.name.split(' ')[1]);
            const themeCol = getUserAvatarColour(userInitials);
            return { name: val.name, color: themeCol, team: 'Post Production', email: val.email };
        });
        var tmpMembers = postTeam.concat(productionTeam);
        tmpMembers.sort((a, b) => a.name.localeCompare(b.name));

        newMembers = tmpMembers.map((val, idx) => {
            return { value: val.name, label: val.name, group: val.team, color: val.color, email: val.email };
        });

        if (allowUnassign) {
            newMembers = [{ value: status_unassigned.value, label: 'Unassign', group: 'Unassign', color: '', email: '' }, ...newMembers];
        }
    }

    return newMembers;
};

export const useWindowSize = () => {
    let [size, setSize] = useState([0, 0]);
    useLayoutEffect(() => {
        function updateSize() {
            setSize([window.innerWidth, window.innerHeight]);
        }
        window.addEventListener('resize', updateSize);
        updateSize();
        return () => window.removeEventListener('resize', updateSize);
    }, []);
    return size;
};

export const getTalentFromSession = (session, talents) => {
    var tmpTalents = [];
    var talentMap = {};
    if (session && session.samples) {
        var newData = [];

        newData = session.samples.map((val) => {
            if (val.talentCodes && val.talentCodes.length) {
                val.talentCodes.split(',').forEach((talentCode) => {
                    const talent = talents.find((item) => item.code.trim() === talentCode);
                    if (talent && !talentMap[talent.code]) {
                        talentMap[talent.code] = talent;
                        tmpTalents.push(talent);
                    }
                });
            }
        });
    }
    return tmpTalents;
};

export const DateRanges = {
    TODAY: 0,
    YESTERDAY: 1,
    THIS_WEEK: 2,
    LAST_WEEK: 3,
    LAST_30_DAYS: 4,
    LAST_90_DAYS: 5,
    LAST_12_MONTHS: 6,
    CUSTOM: 7,
};

export const DateSelections = [
    { label: 'Today', value: DateRanges.TODAY },
    // { label: 'Yesterday', value: DateRanges.YESTERDAY },
    { label: 'This week', value: DateRanges.THIS_WEEK },
    { label: 'Last week', value: DateRanges.LAST_WEEK },
    // { label: 'Last 30 days', value: DateRanges.LAST_30_DAYS },
    // { label: 'Last 90 days', value: DateRanges.LAST_90_DAYS },
    // { label: 'Last 12 months', value: DateRanges.LAST_12_MONTHS },
    { label: 'Custom', value: DateRanges.CUSTOM },
];

export const getDateRanges = (item) => {
    const now = new Date();
    let startDate = new Date(now);
    let endDate;

    switch (item.value) {
        case DateRanges.TODAY:
            startDate = new Date(now);
            startDate.setHours(0, 0, 0, 0); // Set time to the beginning of the day (00:00:00)
            endDate = new Date(now);
            endDate.setHours(23, 59, 59, 999);
            break;
        case DateRanges.YESTERDAY:
            startDate.setDate(now.getDate() - 1);
            startDate.setHours(0, 0, 0, 0);
            endDate = new Date(now);
            endDate.setDate(now.getDate() - 1);
            endDate.setHours(23, 59, 59, 999);
            break;
        case DateRanges.THIS_WEEK:
            startDate.setDate(now.getDate() - now.getDay());
            startDate.setHours(0, 0, 0, 0);
            endDate = new Date(now);
            break;
        case DateRanges.LAST_WEEK:
            startDate.setDate(now.getDate() - now.getDay() - 7);
            startDate.setHours(0, 0, 0, 0);
            endDate = new Date(now);
            endDate.setDate(now.getDate() - now.getDay() - 1);
            endDate.setHours(23, 59, 59, 999);
            break;
        case DateRanges.LAST_30_DAYS:
            startDate.setDate(now.getDate() - 30);
            startDate.setHours(0, 0, 0, 0);
            endDate = new Date(now);
            break;
        case DateRanges.LAST_90_DAYS:
            startDate.setDate(now.getDate() - 90);
            startDate.setHours(0, 0, 0, 0);
            endDate = new Date(now);
            break;
        case DateRanges.LAST_12_MONTHS:
            startDate.setMonth(now.getMonth() - 12);
            startDate.setHours(0, 0, 0, 0);
            endDate = new Date(now);
            break;
        default:
            startDate = null;
            endDate = null;
    }

    return { startDate, endDate };
};

/**
 * Helper to parse the error response code and determine whether we need to throw or not.
 * At the moment we just look at whether a repsonse has been aborted.
 * @param {*} err
 * @returns
 */
export const doThrowOnResponse = (err) => {
    if (err && err.code === ResponseCodes.ABORT_ERROR) {
        console.warn('doThrowOnResponse: ', err);
        return false;
    } else {
        console.error('doThrowOnResponse: ', err);
        return true;
    }
};

export const formatCompanySpecificData = (companyCode, data, formatType = 'DEFAULT') => {
    const companyFormats = CompanyFormats[companyCode];
    var result;

    if (companyFormats) {
        switch (formatType) {
            case CompanyFormatFields.ASSET_DISTRIBUTION_NAME: {
                const { style, shot } = data;
                result = companyFormats[formatType]({ style, shot });
                break;
            }
            default: {
                break;
            }
        }
    }

    if (!companyFormats || !companyFormats[formatType]) {
        throw new Error(`No format defined for company: ${companyCode} (${formatType})`);
    }
    return result;
};

export const getCompany = (aggData) => {
    var company;
    if (aggData && aggData.clients && aggData.clients.length) {
        company = aggData.clients[0];
    }
    return company;
};

export const formatDateFilterFields = (val) => {
    // Use moment to convert the date to UTC
    const utcMoment = moment(val);

    // Store the UTC date as an ISO string
    const utcDateString = utcMoment.toISOString();

    return utcDateString;
};

export const purgeFilters = (filters) => {
    const now = Date.now();
    const maxAge = AppConstants.FILTER_MAX_AGE_IN_DAYS * 24 * 60 * 60 * 1000;
    const newFilters = Object.entries(filters)
        .filter(([_, filter]) => {
            logger.log(filter.timestamp, now, maxAge);
            return filter.timestamp && now - filter.timestamp < maxAge;
        })
        .reduce((acc, [key, value]) => {
            acc[key] = value;
            return acc;
        }, {});

    return newFilters;
};
