import { ActionIcon, Box, Button, Divider, Group, Input, Modal, MultiSelect, Popover, SegmentedControl, Text } from '@mantine/core';
import { ListViewOption } from 'core/components/listToggle/listToggleControl';
import { selectSample, selectSamples } from 'modules/Warehouse/warehouseSlice';
import { useCallback, useEffect, useMemo, useState } from 'react';
import { connect, useDispatch } from 'react-redux';
import { useLocation, useNavigate, useParams } from 'react-router-dom';
import { CheckboxDropdown, ColumnFilterMenu, FilterButton, FormInputWrapper } from 'core/components';
import { logger } from 'utils/logger';
import { AppConstants, CompanyTableColumns, SeasonActions, SessionActions, ViewModes } from 'utils/constants';
import { useForm } from '@mantine/form';
import { IconChecklist, IconChevronDown, IconChevronRight, IconFileSymlink, IconPlus, IconSearch, IconTrash, IconX } from '@tabler/icons';
import SampleList from 'modules/Warehouse/features/samples/SampleList';
import SampleGrid from 'modules/Warehouse/features/samples/SampleGrid';
import SampleEdit from 'modules/Warehouse/features/sample/SampleEdit';
import SamplesUpload from 'modules/Warehouse/features/samples/SamplesUpload';
import { createSession, fetchSeasonStyles, fetchSeasonStylesSuccess, getSeasonSuccess, removeStylesFromSeason, setSeasonSampleFilters, updateSeasonStyle } from 'modules/Seasons/seasonSlice';
import { closeAllModals, openConfirmModal, openModal } from '@mantine/modals';
import { EmptyState } from 'core/components/emptyState/EmptyState';
import { SampleEventType } from 'core/models/SampleModel';
import { SeasonEventType } from 'core/models/SeasonModel';
import SessionEdit from 'modules/Sessions/features/session/SessionEdit';
import { selectSession } from 'modules/Sessions/sessionSlice';
import { SessionModel } from 'core/models';
import { getCompany, selectItems } from 'utils/helper';
import AssignToSession from './AssignToSession';
import { showNotification } from '@mantine/notifications';
import StyleEdit from 'modules/Styles/features/style/StyleEdit';
import { useAbortController } from 'core/hooks/useAbortableController';
import CustomDatePicker from 'core/components/datePicker/CustomDatePicker';
import moment from 'moment';
import { useLocalStorage } from '@mantine/hooks';
import { SampleFilterModel } from 'core/models/SampleFilterModel';
import BatchSeasonStyleEdit from './BatchSeasonStyleEdit';

const SeasonTabStyles = ({ user, season, selectedSamples, success, error, onStatusClick, aggData, sampleFilters, setSampleFilters }) => {
    const dispatch = useDispatch();
    const [filterOpen, setFilterOpen] = useState(false);
    const [listView, setListView] = useState(ListViewOption.LIST);
    const navigate = useNavigate();
    const location = useLocation();
    const [selectedItems, setSelectedItems] = useState(selectedSamples);
    const [filterCount, setFilterCount] = useState(0);
    const [sampleData, setSampleData] = useState([]);
    // const [sampleFilter, setSampleFilter] = useState({ ...SampleFilterModel });
    const [filterItems, setFilterItems] = useState(null);
    const [sampleModalOpen, setSampleModalOpen] = useState(false);
    const [sampleUploadShown, setSampleUploadShown] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    // const [columns, setColumns] = useState([...defaultColumns]);
    const [columns, setColumns] = useState(defaultColumns);
    const params = useParams();

    const abortController = useAbortController();

    // const columns = defaultColumns;
    const [hiddenColumns, setHiddenColumns] = useLocalStorage({
        key: 'oi-season-styles-hidden-columns',
        defaultValue: [...defaultColumns].filter((val) => Object.hasOwn(val, 'show') && val.show === false && val.accessor).map((val) => val.accessor),
    });

    const sampleFilter = useMemo(() => {
        return sampleFilters[params.id] ? sampleFilters[params.id].filter : { ...new SampleFilterModel() };
    }, [season, sampleFilters]);

    const setSampleFilter = useCallback(
        (filter) => {
            dispatch(setSampleFilters({ seasonId: params.id, filter }));
        },
        [season, sampleFilters, sampleFilter],
    );

    // updat the columns with company specific columns on the aggData Update
    useEffect(() => {
        var tmpCols = [
            ...defaultColumns,
            ...(aggData ? CompanyTableColumns[getCompany(aggData)?.code]?.seasonStyles || [] : []),
            {
                Header: '',
                accessor: 'action',
                width: 30,
            },
        ];

        logger.log(' COLUMNS:', tmpCols, getCompany(aggData)?.code);
        setColumns([...tmpCols]);
    }, [aggData]);

    const selectAllMenu = (
        <div>
            {/* <Button variant='default' onClick={(e) => onMenuItemClick(e)}>Create new Season</Button>
      <Button variant='default' onClick={(e) => onMenuItemClick(e)}>Add to Season</Button> */}
            <Button variant='default' onClick={(e) => onMenuItemClick({ type: SeasonEventType.CREATE_SESSION })}>
                Create Session
            </Button>

            <Button variant='default' onClick={(e) => onMenuItemClick({ type: SeasonEventType.SEASON_ASSIGN_STYLES_TO_SESSION })}>
                Assign to Session
            </Button>

            <Button variant='default' onClick={(e) => onMenuItemClick({ type: SeasonEventType.REMOVE_SAMPLE, data: selectedItems })}>
                Remove from Event
            </Button>

            <Button variant='default' onClick={(e) => onMenuItemClick({ type: SeasonEventType.SEASON_BATCH_EDIT_STYLES, data: selectedItems })}>
                Batch Update Styles
            </Button>

            <Button variant='default' onClick={(e) => onSelectAll(e)}>
                Deselect All
            </Button>
        </div>
    );

    const filterSamples = useCallback(() => {
        var data = season?.styles?.filter((sample) => {
            var match = true;
            // filter by brand
            if (sampleFilter.brands && sampleFilter.brands.length) {
                match = sampleFilter.brands.indexOf(sample.facet_brand) > -1 ? true : false;
            }

            if (sampleFilter.categories && sampleFilter.categories.length && match) {
                match = sampleFilter.categories.indexOf(sample.metadata_toplevelcategory) > -1 ? true : false;
            }

            if (sampleFilter.photoCode && sampleFilter.photoCode.length && match) {
                match = sampleFilter.photoCode.indexOf(sample.instructions['Web Photography Code']) > -1 ? true : false;
            }
            if (sampleFilter.sampleSize && sampleFilter.sampleSize.length && match) {
                match = sampleFilter.sampleSize.indexOf(sample.instructions['Web Photography Sample Size']) > -1 ? true : false;
            }
            if (sampleFilter.shotStyle && sampleFilter.shotStyle.length && match) {
                match = sampleFilter.shotStyle.indexOf(sample.instructions['Web Photography Style']) > -1 ? true : false;
            }
            if (sampleFilter.departments && sampleFilter.departments.length && match) {
                match = sampleFilter.departments.indexOf(sample.instructions['departmentStr']) > -1 ? true : false;
            }
            // if (sampleFilter.sessions && sampleFilter.sessions.length && match) {
            //     match = sampleFilter.sessions.some((object) => sample.studioSessionCodes.includes(object));
            // }

            // { label: 'All', value: '' },
            // { label: 'Yes', value: '1' },
            // { label: 'No', value: '0' }
            if (sampleFilter.status.hasSession === '1') {
                if (sampleFilter.sessions && sampleFilter.sessions.length && match) {
                    match = sampleFilter.sessions.some((object) => sample.studioSessionCodes?.includes(object));
                } else {
                    if (sample.studioSessionCodes?.length === 0 || !sample.studioSessionCodes) {
                        match = false;
                    }
                }
                // logger.log(match);
            } else if (sampleFilter.status.hasSession === '0' && match) {
                match = sample.studioSessionCodes?.length === 0 || !sample.studioSessionCodes ? true : false;
            }

            if (sampleFilter.multiShotFlag === '1' && match) {
                match = sample.multiShotFlag && sample.multiShotFlag === 'Y' ? true : false;
                // logger.log(match);
            } else if (sampleFilter.multiShotFlag === '0' && match) {
                match = sample.multiShotFlag?.length === 0 || !sample.multiShotFlag ? true : false;
            }

            // for the status
            // remove the hasSession item
            const statusKeys = Object.keys(sampleFilter.status).filter((item) => item !== 'hasSession');

            for (let i = 0; i < statusKeys.length; i++) {
                const key = statusKeys[i];
                if (sampleFilter.status[key] && sampleFilter.status[key].length && match) {
                    if (parseInt(sampleFilter.status[key]) === 0 && sample[key]) {
                        match = parseInt(sample[key]) === 0 ? true : false;
                    } else if (parseInt(sampleFilter.status[key]) > 0) {
                        match = parseInt(sample[key]) > 0 ? true : false;
                    }
                }
                if (!match) {
                    break;
                }
            }

            const otbDate = new Date(sample.instructions['Web Stock OTB Date']);

            // Deadline filter dates
            if (sampleFilter.otbDate.dateStart && sampleFilter.otbDate.dateEnd && match) {
                match = otbDate >= moment(sampleFilter.otbDate.dateStart).toDate() && otbDate <= moment(sampleFilter.otbDate.dateEnd).toDate() ? true : false;
            } else if (sampleFilter.otbDate.dateStart && sampleFilter.otbDate.dateEnd === '' && match) {
                match = otbDate >= moment(sampleFilter.otbDate.dateStart).toDate() ? true : false;
            } else if (sampleFilter.otbDate.dateEnd && sampleFilter.otbDate.dateStart === '' && match) {
                match = otbDate <= moment(sampleFilter.otbDate.dateEnd).toDate() ? true : false;
            }

            // if (sampleFilter.status.sampleCount && match) {
            //     match = sample.sampleCount && sample.sampleCount > 0 ? true : false;
            // }

            // if (sampleFilter.status.photographyCount && match) {
            //     match = sample.photographyCount && sample.photographyCount > 0 ? true : false;
            // }

            // if (sampleFilter.status.taskCount && match) {
            //     match = sample.taskCount && sample.taskCount > 0 ? true : false;
            // }

            // if (sampleFilter.status.taskCompleteCount && match) {
            //     match = sample.taskCompleteCount && sample.taskCompleteCount > 0 ? true : false;
            // }

            if (sample && sampleFilter.keyword && sampleFilter.keyword.length && match) {
                // match = sample.seasonPart.toLowerCase().indexOf(sampleFilter.keyword.toLowerCase()) > -1 ? true : false;
                match = sample.instructions['Web Photography Code'].toLowerCase().indexOf(sampleFilter.keyword.toLowerCase()) > -1 ? true : false;

                if (!match && sample.seasonSort) {
                    match = sample.seasonSort.toLowerCase().indexOf(sampleFilter.keyword.toLowerCase()) > -1 ? true : false;
                }

                if (!match && sample.instructions['Web Photography Notes']) {
                    match = sample.instructions['Web Photography Notes'].toLowerCase().indexOf(sampleFilter.keyword.toLowerCase()) > -1 ? true : false;
                }

                // if (!match) {
                //     match = sample.instructions['Web Photography Code'].toLowerCase().indexOf(sampleFilter.keyword.toLowerCase()) > -1 ? true : false;
                // }

                if (!match && sample.instructions['ODBMS APN (GTIN)']) {
                    match = sample.instructions['ODBMS APN (GTIN)'].toLowerCase().indexOf(sampleFilter.keyword.toLowerCase()) > -1 ? true : false;
                }

                if (!match && sample.instructions['department']) {
                    match = sample.instructions['department'].toLowerCase().indexOf(sampleFilter.keyword.toLowerCase()) > -1 ? true : false;
                }

                if (!match && sample.instructions.Name) {
                    match = sample.instructions.Name.toLowerCase().indexOf(sampleFilter.keyword.toLowerCase()) > -1 ? true : false;
                }
            }

            // if (sample && sampleFilter.keyword && sampleFilter.keyword.length && match) {
            //     // we kick off the first check
            //     if (sample.ssPart) {
            //         match = sample.ssPart.toLowerCase().indexOf(sampleFilter.keyword.toLowerCase()) > -1 ? true : false;
            //     } else {
            //         match = false;
            //     }
            //     if (!match && sample.styleNumber) {
            //         match = sample.styleNumber.toLowerCase().indexOf(sampleFilter.keyword.toLowerCase()) > -1 ? true : false;
            //     }
            //     if (!match && sample.seasonCode) {
            //         match = sample.seasonCode.toLowerCase().indexOf(sampleFilter.keyword.toLowerCase()) > -1 ? true : false;
            //     }
            //     if (!match && sample.sessionCode) {
            //         match = sample.sessionCode.toLowerCase().indexOf(sampleFilter.keyword.toLowerCase()) > -1 ? true : false;
            //     }

            //     if (!match && sample.ssSort) {
            //         match = sample.ssSort.toLowerCase().indexOf(sampleFilter.keyword.toLowerCase()) > -1 ? true : false;
            //     }

            //     if (!match && sample.name) {
            //         match = sample.name.toLowerCase().indexOf(sampleFilter.keyword.toLowerCase()) > -1 ? true : false;
            //     }

            //     if (!match && sample.desc) {
            //         match = sample.desc.toLowerCase().indexOf(sampleFilter.keyword.toLowerCase()) > -1 ? true : false;
            //     }

            //     if (!match && sample.photoCode) {
            //         match = sample.photoCode.toLowerCase().indexOf(sampleFilter.keyword.toLowerCase()) > -1 ? true : false;
            //     }
            // }

            return match;
        });

        if (sampleFilter.groupBy) {
            var uniq = [...new Set(Object.keys(data).map((e) => data[e][sampleFilter.groupBy]))];
            // create the group by items
            var groupedData = uniq.map((group) => {
                var subRows = data.filter((val) => val[sampleFilter.groupBy] === group);
                return { type: 'group', name: group, subRows, id: group };
            });
            data = groupedData;
        }

        if (data) setSampleData(data);
    }, [
        sampleFilter.brands,
        sampleFilter.categories,
        setSampleData,
        season,
        sampleFilter.groupBy,
        sampleFilter.keyword,
        sampleFilter.departments,
        sampleFilter.photoCode,
        sampleFilter.sampleSize,
        sampleFilter.sessions,
        sampleFilter.shotStyle,
        sampleFilter.status,
    ]);

    // Request handler hide preloading
    useEffect(() => {
        if (success) {
            switch (success.type) {
                case fetchSeasonStylesSuccess.toString(): {
                    setIsLoading(false);
                    filterSamples();

                    break;
                }
                case getSeasonSuccess.toString(): {
                    dispatch(fetchSeasonStyles(params.id, null, abortController.current.signal));
                    break;
                }
                default: {
                    break;
                }
            }
        }
    }, [success]);

    /**
     * Handles the list toggle display
     * @param {*} val
     */
    // const listToggleHandler = (val) => {
    //     navigate({ search: `?displayType=${val}` });
    // };

    /**
     * Effect to fetch the samples
     */
    // useEffect(() => {
    //     if ((!hasInit.current && season && season.id) || (season && season.id !== params.id)) {
    //         setTimeout(() => {
    //             dispatch(fetchSeasonStyles(params.id, user));
    //         }, 100);
    //         hasInit.current = true;
    //     }
    // }, [dispatch, hasInit, season, params.id, user]);

    useEffect(() => {
        //setSampleData(samples);

        // set the brands/season/category
        var brands = {};
        var categories = {};
        var seasons = {};
        var sampleSize = {};
        var shotStyle = {};
        var department = {};
        var sessions = {};
        var photoCode = {};

        season?.styles?.forEach((item, idx) => {
            brands[item.facet_brand] = item;
            categories[item.metadata_toplevelcategory] = item;
            seasons[item.facet_season] = item;
            if (item.instructions) {
                sampleSize[item.instructions['Web Photography Sample Size'] ?? ''] = item;
                shotStyle[item.instructions['Web Photography Style']] = item;
                department[item.instructions['departmentStr']] = item;

                if (item.studioSessionCodes) {
                    const sessionCodes = item.studioSessionCodes;
                    sessionCodes.forEach((code) => {
                        sessions[code.trim()] = item;
                    });
                }

                photoCode[item.instructions['Web Photography Code']] = item;
            }
        });

        setFilterItems({ brands, categories, seasons, sampleSize, shotStyle, department, sessions, photoCode });

        filterSamples();
    }, [season?.styles, filterSamples]);

    /**
     * Effect to handle location change
     */
    useEffect(() => {
        const params = new URLSearchParams(location.search);
        let displayType = params.get('displayType');
        logger.log('location change ', location, ' DISPLAY: ', displayType, params);
        // if display type changes
        if (displayType && displayType !== listView) setListView(displayType);
    }, [location, listView]);

    const onItemClick = (item) => {
        // we need to select the current sample
        // dispatch(selectSample(item));
        // openSampleDetail(item);
        // update the route
        // navigate(`/warehouse/samples/${item.stylenumber}`);
        // dispatch(selectSample(item));

        // openSampleDetail(item);
        // update the route
        // navigate(`/warehouse/feed/${item.stylenumber}`);

        // changed to show modal so we allow for photoCode edit
        // navigate(`/warehouse/styles/${item.seasonSort}`);

        // TODO ADD THIS BACK IN
        openStyleModal(item);
    };

    const onItemSelected = (e, item, selected, rows) => {
        var items = [...selectedItems];
        selectItems(
            e,
            item,
            selected,
            rows.map((val) => val.original),
            items,
        );
        // if the item is a group then push the elements into the group.
        // if (item.type && item.type === 'group') {
        //     if (selected) {
        //         items.push({ id: item.id });
        //         item.subRows.forEach((row) => {
        //             var tmpItem = items.find((val) => val.id === row.id);
        //             if (!tmpItem) items.push({ id: row.id });
        //         });
        //     } else {
        //         _.remove(items, (val) => {
        //             return val.id === item.id;
        //         });

        //         item.subRows.forEach((row) => {
        //             _.remove(items, (val) => {
        //                 return val.id === row.id;
        //             });
        //         });
        //     }
        // } else {
        //     if (selected) {
        //         if (!items.find((val) => item.id === val.id))
        //             items.push({ id: item.id });
        //     } else {
        //         _.remove(items, (val) => {
        //             return val.id === item.id;
        //         });
        //     }
        // }

        // selectSamples(items);
        setSelectedItems(items);
    };

    const onSelectAll = () => {
        // if there are items selected remove them
        if (selectedItems.length) {
            setSelectedItems([]);
            // dispatch(selectSamples([]));
        } else {
            setSelectedItems([...sampleData]);
            // dispatch(selectSamples([...sampleData]));
        }
    };

    const onMenuItemClick = (e) => {
        logger.log(e);
        switch (e.type) {
            case SeasonEventType.CREATE_SESSION: {
                openSessionModal();
                break;
            }
            case SeasonEventType.SEASON_ASSIGN_STYLES_TO_SESSION: {
                openAssignToSessionModal();
                break;
            }

            case SampleEventType.SAMPLE_ASSIGN_CAPTURES: {
                onItemClick(e.data);
                break;
            }

            case SampleEventType.SAMPLE_BUILD_OUTFIT: {
                navigate(`/warehouse/outfitter/create`);
                break;
            }

            case SampleEventType.SAMPLE_SHOW_SESSION: {
                navigate(`/sessions/${e.data}`);
                break;
            }

            case SampleEventType.SAMPLE_DELETE:
            case SeasonActions.REMOVE_SAMPLE: {
                openConfirmModal({
                    title: 'Style remove confirm',
                    children: (
                        <>
                            <Text size='sm'>Are you sure you want to remove the styles from this Event</Text>
                        </>
                    ),
                    labels: { confirm: 'Confirm', cancel: 'Cancel' },
                    onCancel: () => console.log('Cancel'),
                    onConfirm: () => {
                        var items = [];
                        var missCount = [];
                        e.data.forEach((val) => {
                            const tmpStyle = sampleData.find((item) => item.id === val.id);
                            if (!tmpStyle.studioSessionCodes || (tmpStyle.studioSessionCodes && tmpStyle.studioSessionCodes.length === 0)) {
                                items.push(tmpStyle);
                            } else {
                                missCount.push(val.id);
                            }
                        });

                        if (items.length) {
                            dispatch(removeStylesFromSeason(season, items));
                        }

                        closeAllModals();

                        if (missCount.length) {
                            openConfirmModal({
                                title: 'Unable to remove',
                                children: (
                                    <>
                                        <Text size='sm'>We weren't able to remove the following styles due to them being assigned to existing sessions.</Text>
                                    </>
                                ),
                                labels: { confirm: 'Confirm', cancel: 'Cancel' },
                                onCancel: () => console.log('Cancel'),
                                onConfirm: () => {
                                    closeAllModals();
                                },
                            });
                        }
                    },
                });

                break;
            }

            case SeasonEventType.SEASON_BATCH_EDIT_STYLES: {

                const payload = e.data.map((val) => {
                    const item = season.styles.find((o) => o.id === val.id);
                    return { ...item };
                });

                openModal({
                    title: 'Batch Update Styles',
                    size: 'xl',
                    children: (
                        <>
                            <BatchSeasonStyleEdit mode={ViewModes.EDIT} season={season} styles={payload} onCancel={closeAllModals} user={user} validate={{}} />
                        </>
                    ),
                });
                break;
            }

            default: {
                break;
            }
        }
    };

    const onCreateSessionSubmit = (vals) => {
        logger.log('onCreateSessionSumit', vals);
        var styles = vals.styles.map((val) => {
            const style = season.styles.find((sample) => val === sample.id);

            return {
                ...style,
                targetSort: style.seasonSort,
                name: style.instructions['Name'],
                descr: '',
            };
        });
        dispatch(createSession({ ...vals, styles: styles }, user));
    };

    const openSessionModal = (styleArray) => {
        const data = styleArray ? styleArray : selectedItems;

        const styles = data.map((val, idx) => {
            let item = season.styles.find((style) => style.id === val.id);
            return item;
        });

        logger.log(styles);

        dispatch(selectSession(new SessionModel()));

        openModal({
            title: 'Create a new Session',
            size: 'xl',
            children: (
                <>
                    <SessionEdit
                        styles={styles}
                        availableStyles={season.styles.map((val) => ({ ...val, targetSort: val.seasonSort }))}
                        season={season}
                        onSubmit={onCreateSessionSubmit}
                        onCancel={() => {
                            closeAllModals();
                        }}
                    />
                </>
            ),
        });
    };

    const openAssignToSessionModal = (styleArray) => {
        const data = styleArray ? styleArray : selectedItems;

        const styles = data.map((val, idx) => {
            let item = season.styles.find((style) => style.id === val.id);
            return item;
        });

        logger.log(styles);

        // dispatch(selectSession(new SessionModel()));

        openModal({
            title: 'Assign Styles to Session',
            size: 'lg',
            children: (
                <>
                    <AssignToSession
                        styles={styles}
                        availableStyles={season.styles.map((val) => ({ ...val, targetSort: val.seasonSort }))}
                        season={season}
                        // onSubmit={onCreateSessionSubmit}
                        onCancel={() => {
                            closeAllModals();
                        }}
                    />
                </>
            ),
        });
    };

    const openStyleModal = (item) => {
        dispatch(selectSample(item));

        openModal({
            title: 'Sample Edit',
            size: 'xl',
            children: (
                <>
                    <StyleEdit
                        sample={item}
                        onSubmit={(values) => {
                            // save the style
                            const sample = { ...values, styleCode: values.seasonSort, photoCode: values.photoCode };
                            dispatch(updateSeasonStyle(season, sample));
                        }}
                        onCancel={() => {
                            closeAllModals();
                        }}
                    />
                </>
            ),
        });
    };

    const setFilterVars = (vals) => {
        logger.log(vals);
        setSampleFilter({ ...vals, pageIndex: 0 });
    };

    useEffect(() => {
        // filter the data.
        filterSamples();
    }, [filterSamples]);

    const getFilterCount = (val) => {
        var count = 0;
        Object.entries(val).forEach(([key, value]) => {
            logger.log(`${key}: ${value}`);
            if (value?.length) {
                ++count;
            }
            if (key === 'status') {
                var statusCount = 0;
                Object.entries(value).forEach(([key, value]) => {
                    if (value) {
                        statusCount = 1;
                    }
                });

                count += statusCount;
            }
            if (key === 'otbDate') {
                if (value.dateStart.toString() || value.dateEnd.toString()) {
                    ++count;
                }
            }
        });

        return count;
    };

    useEffect(() => {
        const count = getFilterCount(sampleFilter);
        setFilterCount(count);
    }, [sampleFilter]);

    const onClearClick = (e) => {
        e.stopPropagation();
        e.nativeEvent.stopPropagation();
        setSampleFilter({ ...new SampleFilterModel(), otbDate: { dateStart: '', dateEnd: '' } });
        logger.log('CLEAR CLICK', e);
    };

    const filterButtonHandler = (e) => {
        logger.log('BUTTON CLICK', e, e.nativeEvent);
        setFilterOpen((o) => !o);
    };

    // const onSampleAction = (e) => {
    //     switch (e.type) {
    //         case SeasonActions.ASSIGN_SAMPLE: {
    //             openSampleAssign();
    //             break;
    //         }
    //     }
    // };

    // const openSampleAssign = () => {
    //     openModal({
    //         title: 'Apply Styles',
    //         size: 'xl',
    //         children: <>{/* <AssignSample season={season} /> */}</>,
    //     });
    // };

    // const openSampleDetail = (sample) => {
    //     openModal({
    //         title: 'Assign Style Captures',
    //         size: '100%',
    //         children: <>{/* <ShotAssign season={season} sample={sample} /> */}</>,
    //     });
    // };

    const rowMenuItems = [
        {
            handler: (data) => {
                openSessionModal([data]);
            },
            label: 'Create Session',
            icon: <IconPlus size={14} />,
        },
        {
            handler: (data) => {
                openAssignToSessionModal([data]);
            },
            label: 'Assign to Session',
            icon: <IconFileSymlink size={14} />,
        },
        {
            handler: (data) => {
                onMenuItemClick({ e: SessionActions.REMOVE_SAMPLE, data: [data] });
            },
            label: 'Unassign',
            icon: <IconTrash size={14} />,
        },
    ];

    const onSampleSave = () => {
        // refetch the seasons style.
        showNotification({
            title: 'Success',
            message: 'New Style created',
            position: 'top-center',
            color: 'teal',
        });

        // close the modal
        setSampleModalOpen(false);
        dispatch(fetchSeasonStyles(season.id, null, abortController.current.signal));
    };

    const columnFilterHandler = (type, data, isShown) => {
        const show = !isShown;
        const idx = hiddenColumns.indexOf(data.accessor);
        var cols = [...hiddenColumns];

        logger.log(cols, data, isShown, idx)
        // if we need to add
        if (!show && idx < 0) {
            cols.push(data.accessor);
        }

        if (!show && idx > -1) {
            cols.splice(idx, 1);
        }

        if (show && idx > -1) {
            cols.splice(idx, 1);
        }
        logger.log(" AFTER SPLCE", cols, data, isShown)
        setHiddenColumns([...cols]);
    };
    return (
        <div style={{ height: '100%' }}>
            <Box
                px='lg'
                sx={{
                    height: '100%',
                    display: 'flex',
                    flexDirection: 'column',
                    alignItems: 'stretch',
                    position: 'relative',
                }}>
                <Group className='container-action-bar' sx={{ width: '100%' }} position='apart' height={'100%'} pt='xl' pb={'sm'} mb={'sm'}>
                    <Group>
                        <CheckboxDropdown selectedItems={selectedItems} onCheckboxClick={onSelectAll} selectMenu={selectAllMenu} />
                        <Input
                            value={sampleFilter.keyword}
                            sx={{ width: 250 }}
                            onChange={(e) =>
                                setSampleFilter({
                                    ...sampleFilter,
                                    keyword: e.currentTarget.value,
                                    pageIndex: 0,
                                })
                            }
                            placeholder={`Search across ${season?.styles?.length ? season.styles.length : ''} Styles`}
                            rightSection={
                                <ActionIcon
                                    onClick={() =>
                                        setSampleFilter({
                                            ...sampleFilter,
                                            keyword: '',
                                        })
                                    }>
                                    {sampleFilter.keyword.length ? <IconX size={14} /> : null}
                                </ActionIcon>
                            }
                            icon={<IconSearch />}
                        />

                        <Text size='sm'>
                            Total{' '}
                            <strong>
                                {sampleData?.length} of {season?.styles?.length}
                            </strong>{' '}
                        </Text>

                        {/* <Button
                            onClick={() =>
                                onSampleAction({
                                    type: SeasonActions.ASSIGN_SAMPLE,
                                })
                            }
                            leftIcon={<IconPlus size={14} />}
                            variant={'light'}>
                            Assign
                        </Button> */}
                    </Group>

                    <Group>
                        <Button leftIcon={<IconPlus size={AppConstants.ICON_SIZE} stroke={AppConstants.ICON_STROKE_WIDTH} />} onClick={() => setSampleModalOpen(true)}>
                            Create Style
                        </Button>

                        <Popover shadow='sm' opened={filterOpen} onClose={() => setFilterOpen(false)} width={600} withArrow closeOnClickOutside={true}>
                            <Popover.Target>
                                <div>
                                    <FilterButton onClick={filterButtonHandler} onClear={onClearClick} count={filterCount} />
                                </div>
                            </Popover.Target>

                            <Popover.Dropdown>
                                <div style={{ display: 'flex' }}>
                                    <FilterPanel
                                        filter={sampleFilter}
                                        onCancel={() => setFilterOpen(false)}
                                        onApply={(vals) => {
                                            setFilterVars(vals);
                                            setFilterOpen(false);
                                        }}
                                        filterItems={filterItems}
                                    />
                                </div>
                            </Popover.Dropdown>
                        </Popover>

                        <ColumnFilterMenu columns={columns} hiddenColumns={hiddenColumns} onChange={(val, data, isShown) => columnFilterHandler(val, data, isShown)} />
                        {/* <Select
                            placeholder='Group By'
                            value={sampleFilter.groupBy}
                            onChange={(val) =>
                                setSampleFilter({
                                    ...sampleFilter,
                                    groupBy: val,
                                })
                            }
                            data={[
                                { value: '', label: 'No Group' },
                                {
                                    value: 'styleNumber',
                                    label: 'Group by Style',
                                },
                                {
                                    value: 'colourName',
                                    label: 'Group by Colour',
                                },
                            ]}
                        /> */}
                    </Group>
                </Group>

                {/* <ScrollArea style={{height:500}} > */}

                {season && sampleData ? (
                    <Box
                        sx={(theme) => ({ flexGrow: 1, overflow: 'auto', marginRight: -theme.spacing.xl })}
                        className={sampleFilter.groupBy && sampleFilter.groupBy.length ? 'main-container-content show-data-grouped' : 'main-container-content show-data-grouped-hidden'}>
                        {listView === ListViewOption.LIST ? (
                            // <Box sx={(theme) => ({ '.h-scroll-table-container': { paddingRight: theme.spacing.xl }, height: sampleData.length !== 0 ? 'auto' : '100%' })}>
                            <SampleList
                                data={sampleData}
                                onItemClick={onItemClick}
                                onMenuItemClick={onMenuItemClick}
                                onItemSelected={onItemSelected}
                                onStatusClick={onStatusClick}
                                selectedItems={selectedItems}
                                filter={sampleFilter}
                                loading={isLoading}
                                actionMenu={rowMenuItems}
                                paginationSize={200}
                                setFilter={setSampleFilter}
                                columns={columns}
                                hiddenColumns={hiddenColumns}
                            />
                        ) : (
                            // </Box>
                            <SampleGrid data={sampleData} onItemClick={onItemClick} onItemSelected={onItemSelected} selectedItems={selectedItems} />
                        )}
                    </Box>
                ) : null}

                {sampleData && sampleData.length === 0 && !isLoading ? (
                    <Box
                        sx={{
                            position: 'absolute',
                            left: 0,
                            right: 0,
                            top: 200,
                        }}>
                        <EmptyState />
                    </Box>
                ) : null}

                {/* </ScrollArea> */}

                {/* {filterOpen ? (
                    <Box
                        style={{
                            backgroundColor: '#000',
                            opacity: '0.05',
                            position: 'absolute',
                            top: '155px',
                            right: 0,
                            bottom: 0,
                            left: '0px',
                            zIndex:2
                        }}></Box>
                ) : null} */}

                <Modal withCloseButton={true} size={'lg'} title='Create a new Style' opened={sampleModalOpen} trapFocus={true} onClose={() => setSampleModalOpen(false)}>
                    <SampleEdit season={season} onCancel={() => setSampleModalOpen(false)} onSampleSave={() => onSampleSave()} />
                </Modal>

                <Modal withCloseButton={true} size={'lg'} title='Batch Upload Samples' opened={sampleUploadShown} trapFocus={true} onClose={() => setSampleUploadShown(false)}>
                    <SamplesUpload />
                </Modal>
            </Box>
        </div>
    );
};

const FilterPanel = ({ onCancel, onApply, filter, filterItems }) => {
    logger.log(filter);
    const form = useForm({
        initialValues: {
            ...filter,
            otbDate: {
                dateStart: filter.otbDate?.dateStart?.toString().length ? moment(filter.otbDate?.dateStart).toDate() : '',
                dateEnd: filter.otbDate?.dateEnd?.toString().length ? moment(filter.otbDate?.dateEnd).toDate() : '',
            },
        },
    });

    // logger.log(filter, filterItems, form.values);
    // useEffect(() => {
    //     if (filter && form) {
    //         form.setValues({ ...filter });
    //     }
    // }, [filter, form]);

    const getFilterVars = useCallback(
        (key) => {
            var res = [];
            if (filterItems[key]) Object.keys(filterItems[key]).forEach((val) => res.push(val));
            return res.sort((a, b) => a.localeCompare(b, undefined, { numeric: true }));
        },
        [filter, filterItems],
    );

    const onSegmentChange = (e) => {
        logger.log(e);
    };

    return (
        <Box shadow='xs' style={{ width: '100%' }}>
            <form onSubmit={form.onSubmit((values) => onApply(values))}>
                <FormInputWrapper label='Photo Code' labelWidth={'120px'}>
                    <MultiSelect data={getFilterVars('photoCode')} label='' placeholder='Pick all that you like' searchable nothingFound='Nothing found' {...form.getInputProps('photoCode')} />
                </FormInputWrapper>
                <FormInputWrapper label='Sample Size' labelWidth={'120px'}>
                    <MultiSelect data={getFilterVars('sampleSize')} label='' placeholder='Pick all that you like' searchable nothingFound='Nothing found' {...form.getInputProps('sampleSize')} />
                </FormInputWrapper>
                <FormInputWrapper label='Style' labelWidth={'120px'}>
                    <MultiSelect data={getFilterVars('shotStyle')} label='' placeholder='Pick all that you like' searchable nothingFound='Nothing found' {...form.getInputProps('shotStyle')} />
                </FormInputWrapper>
                <FormInputWrapper label='Department' labelWidth={'120px'}>
                    <MultiSelect data={getFilterVars('department')} label='' placeholder='Pick all that you like' searchable nothingFound='Nothing found' {...form.getInputProps('departments')} />
                </FormInputWrapper>
                {/* <FormInputWrapper label='Sessions' labelWidth={'120px'}>
                    <MultiSelect
                        data={getFilterVars('sessions')}
                        label=''
                        placeholder='Pick all that you like'
                        searchable
                        nothingFound='Nothing found'
                        {...form.getInputProps('sessions')}
                    />
                </FormInputWrapper> */}
                {/* <FormInputWrapper label='Status' id='input-brands' labelWidth={'120px'}>
                    <Stack>
                        <Checkbox label='Sample Acquired' {...form.getInputProps('status.sampleCount', { type: 'checkbox' })} />
                        <Checkbox label='Has Photography' {...form.getInputProps('status.photographyCount', { type: 'checkbox' })} />
                        <Checkbox label='Has Tasks' {...form.getInputProps('status.taskCount', { type: 'checkbox' })} />
                        <Checkbox label='Tasks Completed' {...form.getInputProps('status.completedTaskCount', { type: 'checkbox' })} />
                    </Stack>

                </FormInputWrapper> */}
                <FormInputWrapper label='OTB Date' id='input-shootdate' labelWidth={'120px'}>
                    <Group noWrap>
                        <CustomDatePicker sx={{ width: 190 }} placeholder='From OTB Date' {...form.getInputProps('otbDate.dateStart')} />
                        <Text>to</Text>
                        <CustomDatePicker sx={{ width: 190 }} placeholder='To OTB Date' {...form.getInputProps('otbDate.dateEnd')} />
                    </Group>
                </FormInputWrapper>

                <Divider />
                <FormInputWrapper label='Has Session' labelWidth={'120px'}>
                    <Box sx={{ width: '100%' }}>
                        <SegmentedControl
                            sx={{ flexShrink: 0 }}
                            data={[
                                { label: 'All', value: '' },
                                { label: 'Yes', value: '1' },
                                { label: 'No', value: '0' },
                            ]}
                            placeholder='Has Session'
                            onChange={(e) => onSegmentChange(e)}
                            {...form.getInputProps('status.hasSession')}
                        />

                        {form.values.status.hasSession === '1' ? (
                            <MultiSelect
                                mt={'sm'}
                                sx={{ flexGrow: `1 !important` }}
                                data={getFilterVars('sessions')}
                                label=''
                                placeholder='Pick all that you like'
                                searchable
                                nothingFound='Nothing found'
                                {...form.getInputProps('sessions')}
                            />
                        ) : null}
                    </Box>
                </FormInputWrapper>
                <Divider />
                <FormInputWrapper label='Multi Shot' labelWidth={'120px'}>
                    <SegmentedControl
                        data={[
                            { label: 'All', value: '' },
                            { label: 'Yes', value: '1' },
                            { label: 'No', value: '0' },
                        ]}
                        placeholder='Has Photography'
                        {...form.getInputProps('multiShotFlag')}
                    />
                </FormInputWrapper>
                <FormInputWrapper label='Sample Acquired' labelWidth={'120px'}>
                    <SegmentedControl
                        data={[
                            { label: 'All', value: '' },
                            { label: 'Yes', value: '1' },
                            { label: 'No', value: '0' },
                        ]}
                        placeholder='Sample Acquired'
                        {...form.getInputProps('status.sampleCount')}
                    />
                </FormInputWrapper>

                <FormInputWrapper label='Has Photography' labelWidth={'120px'}>
                    <SegmentedControl
                        data={[
                            { label: 'All', value: '' },
                            { label: 'Yes', value: '1' },
                            { label: 'No', value: '0' },
                        ]}
                        placeholder='Has Photography'
                        {...form.getInputProps('status.photographyCount')}
                    />
                </FormInputWrapper>

                <FormInputWrapper label='Has Tasks' labelWidth={'120px'}>
                    <SegmentedControl
                        data={[
                            { label: 'All', value: '' },
                            { label: 'Yes', value: '1' },
                            { label: 'No', value: '0' },
                        ]}
                        placeholder='Has Tasks'
                        {...form.getInputProps('status.taskCount')}
                    />
                </FormInputWrapper>

                <FormInputWrapper label='Tasks Complete' labelWidth={'120px'}>
                    <SegmentedControl
                        data={[
                            { label: 'All', value: '' },
                            { label: 'Yes', value: '1' },
                            { label: 'No', value: '0' },
                        ]}
                        placeholder='Tasks Complete'
                        {...form.getInputProps('status.taskCompletedCount')}
                    />
                </FormInputWrapper>

                {/* <FormInputWrapper label='Brands' id='input-brands' labelWidth={'120px'}>
                    <MultiSelect data={getFilterVars('brands')} label='' placeholder='Pick all that you like' searchable nothingFound='Nothing found' {...form.getInputProps('brands')} />
                </FormInputWrapper>

                <FormInputWrapper label='Category' id='input-category' labelWidth={'120px'}>
                    <MultiSelect data={getFilterVars('categories')} label='' placeholder='Pick all that you like' searchable nothingFound='Nothing found' {...form.getInputProps('categories')} />
                </FormInputWrapper>

                <FormInputWrapper label='Department' id='input-department' labelWidth={'120px'}>
                    <MultiSelect
                        data={['React', 'Angular', 'Svelte', 'Vue', 'Riot', 'Next.js', 'Blitz.js']}
                        label=''
                        placeholder='Pick all that you like'
                        searchable
                        nothingFound='Nothing found'
                        {...form.getInputProps('department')}
                    />
                </FormInputWrapper>

                <FormInputWrapper label='Location' id='input-location' labelWidth={'120px'}>
                    <MultiSelect
                        data={['React', 'Angular', 'Svelte', 'Vue', 'Riot', 'Next.js', 'Blitz.js']}
                        label=''
                        placeholder='Pick all that you like'
                        searchable
                        nothingFound='Nothing found'
                        {...form.getInputProps('location')}
                    />
                </FormInputWrapper>

                <FormInputWrapper label='Status' id='input-status' labelWidth={'120px'}>
                    <MultiSelect
                        data={['React', 'Angular', 'Svelte', 'Vue', 'Riot', 'Next.js', 'Blitz.js']}
                        label=''
                        placeholder='Pick all that you like'
                        searchable
                        nothingFound='Nothing found'
                        {...form.getInputProps('status')}
                    />
                </FormInputWrapper>

                <FormInputWrapper label='Colour' id='input-colour' labelWidth={'120px'}>
                    <ColorInput placeholder='Pick color' {...form.getInputProps('colour')} />
                </FormInputWrapper>

                <FormInputWrapper label='Assigned Product' id='input-colour' labelWidth={'120px'}>
                    <NativeSelect data={['', 'Yes', 'No']} placeholder='Assigned Product' withAsterisk />
                </FormInputWrapper>

                <FormInputWrapper label='Part of a Season' id='input-assigned-product' labelWidth={'120px'}>
                    <NativeSelect data={['', 'Yes', 'No']} placeholder='Assigned Product' withAsterisk />
                </FormInputWrapper>

                <FormInputWrapper label='Part of an Outfit' id='input-assigned-season' labelWidth={'120px'}>
                    <NativeSelect data={['', 'Yes', 'No']} placeholder='Assigned Product' withAsterisk />
                </FormInputWrapper> */}
                <Divider />
                <Group position='apart' mt='sm'>
                    <Button variant='subtle' component='button' size='sm' onClick={() => onCancel()}>
                        Cancel
                    </Button>
                    <Button type='submit' size='sm'>
                        Apply
                    </Button>
                </Group>
            </form>
        </Box>
    );
};

const mapStateToProps = (state, props) => {
    logger.log(state, props);
    return {
        user: state.auth.user,
        aggData: state.appReducer.aggData,
        season: state.seasonReducer.selectedSeason,
        sample: state.warehouse.selectedSample,
        samples: state.warehouse.samples,
        selectedSamples: state.seasonReducer.selectedSamples,
        success: state.seasonReducer.success,
        sampleFilters: state.seasonReducer.seasonSampleFilters,
        // sampleFilter: state.warehouse.sampleFilter,
        error: state.auth.error,
    };
};

const mapDispatchToProps = {
    selectSamples: selectSamples,
    setSampleFilters: setSeasonSampleFilters,
};

export default connect(mapStateToProps, mapDispatchToProps)(SeasonTabStyles);

const defaultColumns = [
    {
        // Build our expander column
        id: 'expander', // Make sure it has an ID
        Header: ({ getToggleAllRowsExpandedProps, isAllRowsExpanded }) => (
            <>
                {/* <span {...getToggleAllRowsExpandedProps()}>
{isAllRowsExpanded ? <IconChevronDown /> : <IconChevronRight />}
</span> */}
            </>
        ),
        Cell: ({ row }) =>
            // Use the row.canExpand and row.getToggleRowExpandedProps prop getter
            // to build the toggle for expanding a row
            row.canExpand ? (
                <span
                    {...row.getToggleRowExpandedProps({
                        style: {
                            // We can even use the row.depth property
                            // and paddingLeft to indicate the depth
                            // of the row
                            paddingLeft: `${row.depth * 2}rem`,
                        },
                    })}>
                    {row.isExpanded ? <IconChevronDown /> : <IconChevronRight />}
                </span>
            ) : null,
    },
    // {
    //     Header: 'Photo Code',
    //     accessor: 'instructions["Web Photography Code"]',
    //     sortType: 'alphanumeric',
    //     width: 150,
    // },
    // {
    //     Header: 'Style',
    //     accessor: 'seasonSort',
    //     sortType: 'alphanumeric',
    //     width: 100,
    // },
    // {
    //     Header: 'Multi Shot',
    //     accessor: 'multiShotFlag',
    //     sortType: 'alphanumeric',
    //     width: 100,
    //     show: true,
    // },
    // {
    //     Header: 'Status',
    //     accessor: 'styleCount',
    //     sortType: 'alphanumeric',
    // },
    // {
    //     Header: 'Session',
    //     accessor: 'studioSessionCodes',
    //     sortType: 'alphanumeric',
    // },
    // {
    //     Header: 'Name',
    //     accessor: 'instructions["Name"]',
    //     sortType: 'alphanumeric',
    //     show: true,
    // },
    // {
    //     Header: 'Notes',
    //     accessor: 'instructions["Web Photography Notes"]',
    //     sortType: 'alphanumeric',
    //     show: true,
    // },
    // {
    //     Header: 'Size',
    //     accessor: 'instructions["Web Photography Sample Size"]',
    //     sortType: 'alphanumeric',
    //     width: 100,
    //     show: true,
    // },
    // {
    //     Header: 'OTB Date',
    //     accessor: 'instructions["Web Stock OTB Date"]',
    //     sortType: 'alphanumeric',
    //     width: 80,
    //     show: true,
    // },
    // // {
    // //     Header: 'ODBMS APN (GTIN)',
    // //     accessor: 'instructions["ODBMS APN (GTIN)"]',
    // // },
    // {
    //     Header: 'Sample Notes',
    //     accessor: 'sampleNotes',
    //     sortType: 'alphanumeric',
    //     show: true,
    // },
    // {
    //     Header: 'Sample Grade',
    //     accessor: 'sampleGrade',
    //     sortType: 'alphanumeric',
    //     show: true,
    // },
    // {
    //     Header: 'Style',
    //     accessor: 'instructions["Web Photography Style"]',
    //     sortType: 'alphanumeric',
    //     width: 80,
    //     show: true,
    // },
    // // {
    // //     Header: 'Dept',
    // //     accessor: 'instructions["Dept"]',
    // // },
    // {
    //     Header: 'Department',
    //     accessor: 'instructions["departmentStr"]',
    //     sortType: 'alphanumeric',
    //     show: true,
    // },

    // {
    //   Header: 'Instructions',
    //   accessor: 'instructions',
    // },
];
