/**
 * @file talentsSlice.js
 * @description Sets initial state for the warehouse module.  Creates reducers and actions for API Calls
 * @author Ben Yee
 */
import { createSlice } from '@reduxjs/toolkit';
import { ListViewOption } from 'core/components/listToggle/listToggleControl';
import { getTalent, getTalents } from 'services/talentService';
import { logger } from 'utils/logger';
import * as talentService from 'services/talentService';

const talentSlice = createSlice({
    name: 'talentSlice',
    initialState: {
        talents: [],
        selectedTalent: null,
        selectedTalents: [],
        displayPrefs: { mediaDisplayType: ListViewOption.GRID, infoOpen: false, selectedAccordion:[] },
        talentFilter: { keyword: '', age: '', gender: [], agency: [], location: [], status: [], groupBy: '' },
        searchCursor: null,
        // standard props
        error: null,
        success: null,
        loading: false,
        action: null,
    },
    reducers: {
        setDisplayPrefs(state, action) {
            state.displayPrefs = { ...action.payload };
        },
        selectTalent(state, action) {
            logger.log('Setting Tatlent: ', action.payload);
            state.selectedTalent = action.payload ? { ...action.payload } : null;
        },
        selectTalents(state, action) {
            logger.log('setting selected talents', action.payload);
            state.selectedTalents = action.payload ? [...action.payload] : [];
        },
        setTalentFilter(state, action) {
            logger.log('Setting Talent: ', action.payload);
            state.talentFilter = action.payload ? { ...action.payload } : null;
        },

        //////////////////////
        //
        // GET STYLES
        //
        //////////////////////
        getTalentsStart(state, action) {
            state.success = null;
            state.error = null;
        },
        getTalentsSuccess(state, action) {
            logger.log('getSuccess : SUCCESS');

            state.loading = false;
            state.success = { type: action.type, message: 'Get Talents Success' };

            state.talents = [...action.payload];
            //state.openIssuesCount = action.payload.open_issues_count
            state.error = null;
        },
        getTalentsFailure(state, action) {
            logger.log(state, action, 'fail');
            state.error = action.payload;
        },

        //////////////////////
        //
        // SINGLE TALENT
        //
        //////////////////////
        getTalentStart(state, action) {
            state.success = null;
            state.error = null;
        },
        getTalentSuccess(state, action) {
            logger.log('getTalentSuccess : SUCCESS');

            state.loading = false;
            state.success = { type: action.type, message: 'Get Talent Success', data: action.payload };
            // if(action.payload.urls){
            //   state.selectedProject = {...state.selectedProject, urls:action.payload.urls}
            // }
            state.selectedTalent = action.payload ? { ...action.payload, scans: null } : null;
            //state.openIssuesCount = action.payload.open_issues_count
            state.error = null;
        },

        getTalentAssetsSuccess(state, action) {
            logger.log('getTalentSuccess : SUCCESS');

            state.loading = false;
            state.success = { type: action.type, message: 'Get Talents Assets Success' };
            var stateAssets = state.selectedTalent && state.selectedTalent.assets?[...state.selectedTalent.assets]:[];
            const items = action.payload.concatItems ? [...stateAssets].concat(...action.payload.items) : [...action.payload.items];
            state.searchCursor = action.payload.cursor;

            state.selectedTalent = { ...state.selectedTalent, assets: items };
            //state.openIssuesCount = action.payload.open_issues_count
            state.error = null;
        },

        getTalentFailure(state, action) {
            logger.log(state, action, 'fail');
            state.error = action.payload;
        },

        createTalentSuccess(state, action) {
            logger.log('getTalentSuccess : SUCCESS');

            state.loading = false;
            state.success = { type: action.type, message: 'Get Talents Assets Success' };

            // state.selectedTalent = { ...state.selectedTalent, assets: action.payload };
            //state.openIssuesCount = action.payload.open_issues_count
            state.error = null;
        },

        getTalentScansSuccess(state, action) {
            logger.log('getTalentScansSuccess : SUCCESS');

            state.loading = false;
            state.success = { type: action.type, message: 'Get Talent Scans Success' };

            state.selectedTalent = { ...state.selectedTalent, scans: action.payload };
            //state.openIssuesCount = action.payload.open_issues_count
            state.error = null;
        },
        updateTalentSuccess(state, action) {
            logger.log('updateTalentSuccess : SUCCESS');

            state.loading = false;
            state.success = { type: action.type, message: 'Talent Successfully Updated' };
            // state.selectedTalent = { ...action.payload };
            state.error = null;
        },
        updateTalentAssetSuccess(state, action) {
            logger.log('updateTalentSuccess : SUCCESS');

            state.loading = false;
            state.success = { type: action.type, message: 'Talent Successfully Updated' };
            // state.selectedTalent = { ...action.payload };
            state.error = null;
        },
        udpateTalentAssetFailure(state, action) {
            logger.log(state, action, 'fail');
            state.error = action.payload;
        },

        batchUpdateTalentSuccess(state, action) {
            var talents = [...state.talents];
            logger.log(action.payload);
            const idMap = new Map(action.payload.map((item) => [item.id, item]));

            // update the talent
            const tmpTalents = [...state.talents].map((talent) => {
                if (idMap.get(talent.id)) {
                    return { ...idMap.get(talent.id) };
                } else {
                    return talent;
                }
            });

            state.talents = tmpTalents;

            state.error = null;
            state.loading = false;
            state.success = { type: action.type, message: 'Your talent has been updated', data: action.payload };
        },


        distributeTalentsSuccess(state, action) {
            logger.log('updateTalentSuccess : SUCCESS');

            state.loading = false;
            state.success = { type: action.type, message: 'Talent Successfully Updated' };
            // state.selectedTalent = { ...action.payload };
            state.error = null;
        },
        distributeTalentsFailure(state, action) {
            logger.log(state, action, 'fail');
            state.error = action.payload;
        },

        clearNotifications(state, action) {
            state.error = null;
            state.success = null;
        },

        onServiceStart(state, action) {
            state.success = null;
            state.loading = true;
            state.error = null;
            // state.action = action.type;
        },
        onServiceFailure(state, action) {
            logger.log(state, action, 'fail');
            state.error = action.payload;
            state.loading = false;
        },
    },
});

// TALENT

export const createTalent = (talent) => async (dispatch) => {
    try {
        dispatch(getTalentStart());

        const data = await talentService.updateTalent(talent);
        dispatch(createTalentSuccess(null));
    } catch (err) {
        logger.log('err', err);
        dispatch(getTalentFailure(err));
    }
};

export const updateTalent = (talent) => async (dispatch) => {
    try {
        dispatch(getTalentStart());
        const data = await talentService.updateTalent(talent);

        if (talent.files && talent.files.length) {
            for (let j = 0; j < talent.files.length; j++) {
                const file = talent.files[j];

                //const fileReq = await getFileUploadPath(file, talent, session);

                //logger.log(fileReq);
                await talentService.uploadCompFile(file, talent);
            }
        }

        if (talent.assetFiles && talent.assetFiles.length) {
            for (let j = 0; j < talent.assetFiles.length; j++) {
                const file = talent.assetFiles[j];

                //const fileReq = await getFileUploadPath(file, talent, session);

                await talentService.uploadPortfolioFile(file, talent);
            }
        }

        dispatch(updateTalentSuccess(null));
    } catch (err) {
        logger.log('err', err);
        dispatch(getTalentFailure(err));
    }
};

export const fetchTalent = (id, user) => async (dispatch) => {
    try {
        logger.log(id, ' IN SLICE');
        dispatch(getTalentStart());
        const data = await getTalent(id, user);
        logger.log(data);
        dispatch(getTalentSuccess(data));
    } catch (err) {
        logger.log('err', err);
        dispatch(getTalentFailure(err));
    }
};
export const fetchTalents = (id, user) => async (dispatch) => {
    try {
        logger.log(id, ' IN SLICE');
        dispatch(getTalentsStart());
        const data = await getTalents(id, user);
        logger.log(data);
        dispatch(getTalentsSuccess(data));
    } catch (err) {
        logger.log('err', err);
        dispatch(getTalentFailure(err));
    }
};

export const fetchTalentAssets =
    (id, criteria, maxRequestCount, cursor, fetchAll = false) =>
    async (dispatch) => {
        try {
            dispatch(onServiceStart());
            var tmpCursor = cursor ?? '';
            var items = [];
            if (fetchAll) {
                do {
                    const data = await talentService.getTalentAssets(id, criteria, tmpCursor);
                    tmpCursor = data.nextCursor;
                    items.push(...data.items);

                    dispatch(getTalentAssetsSuccess({ items: [...items], complete: false }));
                } while (tmpCursor);

                dispatch(getTalentAssetsSuccess({ items: [...items], complete: true }));
            } else {
                var origCursor = cursor;
                var items = [];
                var index = 0;
                var resetCursor = true;
                var tmpCursor;
                var concatItems = true;

                do {
                    const data = await talentService.getTalentAssets(id,criteria, cursor);
                    cursor = data.nextCursor;
                    items.push(...data.items);

                    if (!maxRequestCount) dispatch(getTalentAssetsSuccess({ items: [...items], complete: false }));

                    if (maxRequestCount && maxRequestCount - 1 === index) {
                        tmpCursor = cursor;
                        cursor = null;
                    }

                    ++index;
                } while (cursor);

                if (tmpCursor) {
                    const decodedString = decodeURIComponent(tmpCursor);
                    // const obj = JSON.parse(decodedString);
                    logger.log(decodedString);
                }

                // if(origCursor && origCursor.length && maxRequestCount){
                //   concatItems = true;
                // }

                // if(tmpCursor){
                //   concatItems = true;
                // }

                if (origCursor === '' && maxRequestCount) {
                    concatItems = false;
                } else if (!maxRequestCount) {
                    concatItems = false;
                }
                

                dispatch(getTalentAssetsSuccess({ items: [...items], complete: true, cursor: tmpCursor, concatItems: concatItems }));

                // const data = await talentService.getTalentAssets(id, criteria, cursor);
                // // cursor = data.nextCursor;
                // items.push(...data.items);

                // dispatch(getTalentAssetsSuccess({ items: [...items], complete: false }));
            }
        } catch (err) {
            logger.log('err', err);
            dispatch(onServiceFailure(err));
        }

        // try {
        //     dispatch(getTalentStart());
        //     const data = await talentService.getTalentAssets(id,criteria);
        //     dispatch(getTalentAssetsSuccess(null));
        // } catch (err) {
        //     logger.log('err', err);
        //     dispatch(getTalentFailure(err));
        // }
    };

export const updateTalentAsset = (talent, asset) => async (dispatch) => {
    try {
        dispatch(onServiceStart());
        // const data = await talentService.updateTalentAsset(talent, asset);
        dispatch(updateTalentAssetSuccess(null));
    } catch (err) {
        logger.log('err', err);
        dispatch(updateTalentAssetFailure(err));
    }
};

export const batchUpdateTalent =
    (talents, user, updateState = true) =>
    async (dispatch) => {
        try {
            dispatch(getTalentStart());
            var dataItems = [];
            // loop over all the task items
            // create a separate task for each item
            for (let i = 0; i < talents.length; i++) {
                const data = await talentService.updateTalent(talents[i]);
                dataItems.push(data);
            }

            if (updateState) dispatch(batchUpdateTalentSuccess(dataItems));
        } catch (err) {
            logger.log('err', err);
            dispatch(updateTalentFailure(err));
        }
    };

export const {
    selectTalent,
    selectTalents,
    setTalentFilter,

    setDisplayPrefs,

    getTalentStart,
    getTalentFailure,
    getTalentSuccess,

    getTalentAssetsSuccess,

    getTalentScansSuccess,

    getTalentsStart,
    getTalentsFailure,
    getTalentsSuccess,

    onServiceStart,
    onServiceFailure,

    createTalentSuccess,

    updateTalentSuccess,
    updateTalentFailure,

    updateTalentAssetSuccess,
    updateTalentAssetFailure,

    batchUpdateTalentSuccess,

    distributeTalentsSuccess,
    distributeTalentsFailure,

    clearNotifications,
} = talentSlice.actions;

export default talentSlice.reducer;
