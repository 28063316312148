import { Box, Button, Container, Grid, Group, Input, Select, Text, Title, useMantineTheme } from '@mantine/core';
import { connect } from 'react-redux';
import { FormInputWrapper } from '../../../../core/components';
import { selectStyle, updateStyleSuccess } from '../../styleSlice';
import { AppConstants, DataFormats } from '../../../../utils/constants';
import moment from 'moment';
import { logger } from 'utils/logger';
import { useEffect, useState } from 'react';

const StyleTabGeneral = ({ style, success, error, departments, form, onFormSubmit }) => {
    const [isLoading, setIsLoading] = useState(false);
    const theme = useMantineTheme();
    const enabled = false;
    const momentDate = moment(style?.completed, 'YYYY-MM-DD-HH-mm-ss-SSS');
    const jsDate = momentDate.toDate();

    useEffect(() => {
        if(success){
            switch(success.type){
                case updateStyleSuccess.toString():{
                    setIsLoading(false)
                    break;
                }
            }
        }
        
        
    },[success])

    // const containerStyle = { width:'1000px', marginTop:'20%', paddingLeft:'20%'}
    return (
        <Container fluid sx={{ marginLeft: 0 }}>
            <Grid>
                <Grid.Col span={8}>
                    <Box p='xl'>
                        <Title order={5} mb='lg'>
                            General Details
                        </Title>

                        <form onSubmit={form.onSubmit((values) => {
                            setIsLoading(true)
                            onFormSubmit(values)
                            })}>
                            <FormInputWrapper label='Style Number' id='input-barcode'>
                                <Input disabled={!enabled} id='input-stylenumber' required placeholder='Enter Stylenumber' variant={AppConstants.inputVariant} {...form.getInputProps('styleNumber')} />
                            </FormInputWrapper>

                            <FormInputWrapper label='Photo Code' id='input-photoCode'>
                                <Input disabled={!enabled} id='input-code' required placeholder='Enter Photo Code' variant={AppConstants.inputVariant} {...form.getInputProps('photoCode')} />
                            </FormInputWrapper>

                            {/* <FormInputWrapper label='Sample ID' id='input-sampleId'>
                                <Input disabled={!enabled} id='input-sampleId' required placeholder='Enter style ID' variant={AppConstants.inputVariant} {...form.getInputProps('sampleId')} />
                            </FormInputWrapper> */}

                            <FormInputWrapper label='Department' id='input-department'>
                                <Select searchable
                                    data={departments?.map((val) => ({ value: val.code, label: `${val.code} - ${val.name}` }))}
                                    id='input-department'
                                    placeholder='Enter Department'
                                    variant={AppConstants.inputVariant}
                                    {...form.getInputProps('deptCode')}
                                />
                            </FormInputWrapper>

                            <FormInputWrapper
                                label='Completed'
                                id='input-completed'
                                style={{
                                    marginTop: theme.spacing.xl,
                                    marginBottom: theme.spacing.xl,
                                }}>
                                <Text size={'sm'}>{moment(jsDate).format(DataFormats.DATE_FORMAT)}</Text>
                            </FormInputWrapper>

                            {/* <FormInputWrapper label='Shoot Date' id='input-shootdate'>
                                <Group>
                                    <CustomDatePicker sx={{ width: 200 }} placeholder='Completed Date' {...form.getInputProps('completed')} />
                                </Group>
                            </FormInputWrapper> */}
                            <Group position='right'>
                                <Button type='submit' loading={isLoading}>
                                    Update Style
                                </Button>
                            </Group>
                        </form>
                    </Box>
                </Grid.Col>
            </Grid>
        </Container>
    );
};

const mapStateToProps = (state, props) => {
    //logger.log(state,props, ' mapstate')
    return {
        user: state.auth.user,
        style: state.styleReducer.selectedStyle,
        departments: state.appReducer.departments,
        success: state.styleReducer.success,
        error: state.auth.error,
    };
};

const mapDispatch = { selectStyle };

export default connect(mapStateToProps, mapDispatch)(StyleTabGeneral);
